































































































































































































































































































































import Vue from 'vue';
import { mapGetters } from 'vuex';
import pxButton from "@/dex-shared/components/px-button.vue";

export default Vue.extend({
  components: {
    pxButton
  },
  props: {
        ctaCaller: {
            type: Function,
            required: true
        },
        loadingCallerData: {
            type: Boolean,
            required: true
        },
        buttonDisabled: {
            type: Boolean,
            required: true
        },
    },
})

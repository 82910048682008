































import TextShow from "./show/TextShow.vue";
import ImageShow from "./show/ImageShow.vue";
import VideoShow from "./show/VideoShow.vue";
import AudioShow from "./show/AudioShow.vue";
import KalturaVideo from "./KalturaVideo.vue";

export default {
  components: {
    TextShow,
    ImageShow,
    VideoShow,
    AudioShow,
    KalturaVideo
  },
  computed: {
    UNIQUE_OBJ_ID() {
      const { component } = this;
      return `kaltura-${component.kalturaId}`;
    }
  },
  props: ["component", "trustedContent"]
};

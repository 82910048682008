
































































































































































































































import Vue from "vue";
import CloseIcon from "vue-material-design-icons/Close.vue";
import pxButton from "../px-button.vue";
import ResizableTextArea from "../ResizableTextArea.vue";
import HorizontalRuler from "../HorizontalRuler.vue";
import { mutation } from "../../helpers/graphql";
import track, { TRACK_TYPES } from '../../helpers/track';

export default Vue.extend({
  components: {
    pxButton,
    CloseIcon,
    ResizableTextArea,
    HorizontalRuler
  },
  props: ["id", "reportFor", "reportedId"],

  data: function() {
    return {
      reported: false,
      report: {
        kind: null,
        description: "",
        userId: this.$store.state.auth.user.id,
        examId: null,
        listId: null,
        articleId: null,
        commentId: null,
        solutionId: null,
        questionId: null,
        classroomId: null,
        examGroupId: null,
        [`${this.reportFor}Id`]: this.reportedId
      },
      ...getReportDefaultData(this.reportFor)
    };
  },

  methods: {
    async sendReport() {
      this.reported = true;
      let response = await mutation(
        `
          mutation ($userId: Int!, $kind: String!, $description: String!, $examId: Int, $listId: Int,
            $articleId: Int, $commentId: Int, $solutionId: Int, $questionId: Int, $classroomId: Int, $examGroupId: Int) {
              createReport(input: {
                report: {
                  kind: $kind
                  description: $description
                  userId: $userId
                  examId: $examId
                  listId: $listId
                  articleId: $articleId
                  commentId: $commentId
                  solutionId: $solutionId
                  questionId: $questionId
                  classroomId: $classroomId
                  examGroupId: $examGroupId
                }
              }) {
                report {
                  id
                }
              }
          }
        `,
        {
          ...this.report
        }
      );
      this.$track(TRACK_TYPES.REPORT_SENT, {
          context: this.reportFor,
          type: this.report.kind
      });
    },
    closeModal() {
      this.$store.dispatch("CLOSE_MODAL", this.id);
    }
  }
});

function getReportDefaultData(reportFor) {
  switch (reportFor) {
    case "exam":
      return { reportMessage: "", reportOptions: [] };
    case "list":
      return { reportMessage: "", reportOptions: [] };
    case "article":
      return {
        reportMessage: "Onde você encontrou o problema?",
        reportOptions: ["enunciado", "bug"]
      };
    case "comment":
      return { reportMessage: "", reportOptions: [] };
    case "solution":
      return {
        reportMessage: "Encontrou algo impróprio?",
        reportOptions: [
          "Comentário ofensivo",
          "Conteúdo impróprio",
          "Explicou errado"
        ]
      };
    case "question":
      return {
        reportMessage: "Onde você encontrou o problema?",
        reportOptions: ["enunciado", "gabarito", "bug", "não entendi a questão"]
      };
    case "classroom":
      return { reportMessage: "", reportOptions: [] };
    case "examGroup":
      return { reportMessage: "", reportOptions: [] };
    default:
      return { reportMessage: "", reportOptions: [] };
  }
}

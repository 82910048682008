































































































































































import Vue from "vue";
import { mapState, mapGetters } from "vuex";
import pxCard from "../dex-shared/components/px-card.vue";
import WrongCard from "./result/WrongCard.vue";
import CorrectCard from "./result/CorrectCard.vue";
import ListProgressBar from "./list/ListProgressBar.vue";
import track, { TRACK_TYPES } from '../dex-shared/helpers/track';

export default Vue.extend({
  components: {
    pxCard,
    WrongCard,
    CorrectCard,
    ListProgressBar
  },
  data: function() {
    return {
      active: 'Erros'
    };
  },
  computed: {
    ...mapGetters({
      replies: "listAnswers",
      listQuestions: "listQuestions",
      currentListData: "currentListData"
    })
  },
  methods: {
    isActive(subsection: string) {
      if (subsection == this.active) {
        return subsection.toLowerCase();
      }
    },
    makeActive(subsection: string) {
      this.active = subsection;
    },
  },
  beforeCreate() {
    this.$store.dispatch("PULL_LIST", this.$route.params.list);
  },
  created() {
    this.$track(TRACK_TYPES.SCREEN_VIEW, {
      screen: "Resultados Lista",
      screenId: this.currentListData.slug
    });
  },
});






































































import Vue from "vue";
import { cdn } from "../lib/cdn";
import { hubspot } from "../settings/tracking";
import { loginURL } from "@/dex-shared/settings/endpoints";

declare let POCKET: any; // TODO implement definitions
declare let _hsq: any; // TODO implement definitions

export default Vue.extend({
  created() {
    this.$store.subscribe((mutation, state) => {
      if (mutation.type == "VALIDATE") {
        this.$router.go(-1);
      }
    });

    this.renderTitleInterval = setInterval(() => {
      if (
        this.$refs.dsLogin &&
        this.$refs.dsLogin.childNodes &&
        this.$refs.dsLogin.childNodes.length
      ) {
        this.renderTitle = true;
        clearInterval(this.renderTitleInterval);
      }
    }, 50);
  },
  async mounted() {
    if (this.$store.state.auth.user) {
      this.$router.go(-1);
    }

    await cdn("https://code.jquery.com/jquery-3.3.1.min.js");
    await cdn(
      "https://cdnjs.cloudflare.com/ajax/libs/react/15.6.1/react.min.js"
    );
    await cdn(
      "https://cdnjs.cloudflare.com/ajax/libs/react/15.6.1/react-dom.min.js"
    );
    await cdn(
      "https://dnnsjdj5swfc3.cloudfront.net/web-components/dev/web-components-2.0.6.js"
    );
    await cdn(
      "https://dnnsjdj5swfc3.cloudfront.net/front-end/libs/pocket.latest.js"
    );
    await cdn("https://js.hs-scripts.com/1653949.js");

    (window as any).POCKET_ENV = {
      hubspot
    };

    POCKET.newLogin({
      phone: true,
      signup: true,
      login: true,
      first: "login",
      id: "ds-login-descomplica",
      response: async response => {
        await this.$store.dispatch("VALIDATE");
        this.onLoginConfirmationReceived(response);
        this.$router.go(-1);
        _hsq.push([
          "identify",
          {
            email: this.$store.state.auth.user.email
          }
        ]);
        _hsq.push([
          "trackEvent",
          {
            id: "dex-login"
          }
        ]);
      },
      register: 'https://descomplica.com.br'
    });
  },
  data() {
    return {
      renderTitle: false,
      renderTitleInterval: null
    };
  },
  updated() {
    let socialLoginButtons = this.$refs["dsLogin"].childNodes[0].childNodes[0]
      .childNodes[1].childNodes[0].childNodes;
    let facebookButton = document.getElementsByClassName(
      "ds-new-login-facebook-button"
    )[0];
    let googleButton = document.getElementsByClassName(
      "ds-new-login-google-button"
    )[0];
    let nativeButton = document.getElementsByClassName(
      "ds-new-login-change-form-link"
    )[0];
    let vm = this;
    facebookButton.addEventListener("click", () => {
      vm.saveLoginOrigin("facebook");
    });
    googleButton.addEventListener("click", () => {
      vm.saveLoginOrigin("google");
    });
    nativeButton.addEventListener("click", () => {
      vm.saveLoginOrigin("descomplica");
    });
  },
  methods: {
    saveLoginOrigin(path) {
      let mktMetadata = localStorage.getItem("firstSearchParams");
      let mktWithLoginMetadata;
      if (mktMetadata == "undefined") {
        mktWithLoginMetadata = { loginOrigin: path };
      } else {
        mktWithLoginMetadata = {
          ...JSON.parse(mktMetadata),
          loginOrigin: path
        };
      }
      localStorage.setItem(
        "firstSearchParams",
        JSON.stringify(mktWithLoginMetadata)
      );
    },
    clearLoginTracking() {
      let mktMetadata = localStorage.getItem("firstSearchParams");
      if (mktMetadata == "undefined") {
        localStorage.setItem("firstSearchParams", JSON.stringify({}));
        return;
      }
      let keysToPersist = Object.keys(JSON.parse(mktMetadata)).filter(
        key => key !== "loginOrigin"
      );
      if (keysToPersist.length == 0) {
        localStorage.setItem("firstSearchParams", JSON.stringify({}));
      } else {
        let objectToSave = {};
        for (let key of keysToPersist) {
          objectToSave[key] = JSON.parse(mktMetadata)[key];
        }
        localStorage.setItem("firstSearchParams", JSON.stringify(objectToSave));
      }
    },
    onLoginConfirmationReceived(response) {
      if (!response.Success || !response.signup) {
        this.clearLoginTracking();
      } else {
        let userEmail = response.email;
        let userPhone = response.phone;
        let mktWithLoginMetada = JSON.parse(
          localStorage.getItem("firstSearchParams")
        );
        let objResp = {
          email: userEmail,
          phone: userPhone,
          loginOrigin: mktWithLoginMetada["loginOrigin"] || "inconclusive"
        };
        this.sendLoginTrackingToGTM(objResp);
        this.clearLoginTracking();
      }
    },
    sendLoginTrackingToGTM(payload) {
      this.$gtm.trackEvent({
        event: this.composeEventNameByOrigin(payload.loginOrigin),
        label: payload.loginOrigin,
        category: "lead",
        action: "vest-simulado2019"
      });
    },
    composeEventNameByOrigin(loginOrigin) {
      let eventBaseName = "SignupFrom";
      let eventVariableName =
        loginOrigin.charAt(0).toUpperCase() + loginOrigin.slice(1);
      return eventBaseName.concat(eventVariableName);
    }
  },
  beforeDestroy() {
    clearInterval(this.renderTitleInterval);
  }
});

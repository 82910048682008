import moment from 'moment';

interface DateProvider {
  date?: moment.Moment
}

class DateProvider {
  constructor({ date } = { date: moment() }) {
    moment.locale('pt-br');

    if (date instanceof moment) {
      this.date = date;
    } else if (typeof date === 'string') {
      this.date = moment(date);
    } else { // Date { day: number (1..31), month: number (1..12), year: number }
      this.date = moment(this.convertToFormat(date));
    }
  }

  private convertToFormat({ day, month, year }) {
    return `${year}-${String(month).padStart(2, '0')}-${String(day).padStart(2, '0')}`;
  }

  get isToday() {
    return this.isSameDateAs(moment());
  }

  get isYesterday() {
    return this.isSameDateAs(moment().subtract(1, 'days'));
  }

  get isCurrentYear() {
    return moment().year() === this.date.year();
  }

  get isCurrentWeek() {
    return this.isCurrentYear && moment().week() === this.date.week();
  }

  get isLastWeek() {
    const lastWeek = moment().subtract(7, 'days');

    return lastWeek.year() === this.date.year() && lastWeek.week() === this.date.week();
  }

  get day() {
    return this.date.date();
  }

  get month() {
    return this.date.month() + 1; // Converts moment month values (0..11) to DB month values (1..12)
  }

  get year() {
    return this.date.year();
  }

  get formattedDateWithThreeLetters(): string {
    return this.date.format('ddd');
  }

  get dayOfTheWeek(): string {
    return this.date.format('dddd');
  }

  get timezoneOffset(): number {
    return (this.date.utcOffset() / 60)
  }

  get weekOfTheYear(): number {
    return this.date.week();
  }

  get timeFromNow(): string {
    const daysFromNow = (new DateProvider()).daysDifference(this.date);
    const weekCount = Math.floor(daysFromNow / 7);

    if (weekCount < 4) {
      return `Há ${weekCount} semana${weekCount > 1 ? 's' : ''}`;
    }

    const fromNow = this.date.fromNow();

    return fromNow.charAt(0).toUpperCase() + fromNow.slice(1).toLowerCase();
  }

  get dayAndMonth(): string {
    return `${this.date.format('DD')}/${this.date.format('MM')}`;
  }

  isBefore(date) {
    const dateToCompare = (new DateProvider({ date })).date;

    return this.date.isBefore(date);
  } 

  isAfter(date) {
    const dateToCompare = (new DateProvider({ date })).date;
    
    return this.date.isAfter(date);
  }

  isSameDateAs(date) {
    const dateToCompare = (new DateProvider({ date })).date;
    
    return this.date.isSame(dateToCompare, 'day') && this.date.isSame(dateToCompare, 'month') && this.date.isSame(dateToCompare, 'year');
  }

  subtractDateWith({ amount, comparison }) {
    return this.date.subtract(amount, comparison);
  }

  daysDifference(date) {
    const dateToCompare = (new DateProvider({ date })).date;

    return this.date.diff(dateToCompare, 'days');
  }

  weekRange(weekOfTheYear = this.weekOfTheYear): any {
    return {
      startOfWeek: moment().week(weekOfTheYear).startOf('week').format('L').substring(0, 5),
      endOfWeek: moment().week(weekOfTheYear).endOf('week').format('L').substring(0, 5)
    };
  }
}

export default DateProvider;
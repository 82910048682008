import VueCookie from "vue-cookie";
import * as jwt_decode from "jwt-decode";
import {
  authenticationURL,
  imaginieBasicURL,
  tokenAuthenticationURL
} from "@/dex-shared/settings/endpoints";
import {
  mutation,
  chimeraQuery,
  chimeraMutation,
  query
} from "@/dex-shared/helpers/graphql";

// mutations
const SET_USER = "SET_USER";
const SET_TOKEN = "SET_TOKEN";
const SIGNOUT = "SIGNOUT";
const SET_IMAGINIE_JWT = "SET_IMAGINIE_JWT";
const SET_IMAGINIE_INFO = "SET_IMAGINIE_INFO";
const SET_ADBLOCK_INFO = "SET_ADBLOCK_INFO";
const SET_USER_ESSAY_CREDITS = "SET_USER_ESSAY_CREDITS";
const SET_LAST_COURSE = "SET_LAST_COURSE";
const UPDATE_USER_EXPERIMENTS_SETTINGS = "UPDATE_USER_EXPERIMENTS_SETTINGS";

// actions
const VALIDATE = "VALIDATE";
const UPDATE_USER = "UPDATE_USER";
const IMAGINIE_AUTH = "IMAGINIE_AUTH";
const APPOSTILA_AUTH = "APPOSTILA_AUTH";
const PULL_USER_ESSAY_CREDITS = "PULL_USER_ESSAY_CREDITS";
const PUSH_USER_EXPERIMENTS_SETTINGS = "PUSH_USER_EXPERIMENTS_SETTINGS";

// url
const cookie_url = authenticationURL;
const authTokenUrl = tokenAuthenticationURL;

export default {
  state: {
    user: {
      id: null,
      name: null,
      firstName: null,
      lastName: null,
      email: null,
      courses: [],
      featureFlags: {},
      settings: {}
    },
    token: null,
    imaginieJWT: null,
    imaginieUser: null,
    isUsingAdBlock: false,
    essayCredits: 0,
    lastCourse: null
  },
  getters: {
    currentUser: (state: any) => {
      if (state.user) {
        return {
          firstName: state.user.firstName || null,
          lastName: state.user.lastName || null,
          email: state.user.email
        };
      } else {
        return {
          firstName: null,
          lastName: null,
          email: null
        };
      }
    },
    userId: (state: any) => {
      return state.user && state.user.id;
    },
    userIsAdmin: (state: any) => !!(state.user && state.user.role == "admin"),
    userIsSubscriber: (state: any) => !!(state.user && state.user.isSubscriber),
    userIsPayer: (state: any) => !!(state.user && state.user.isPayer),
    userIsUsingAdBlock: (state: any) => state.isUsingAdBlock,
    userEssayCredits: (state: any) => state.essayCredits,
    userLastCourse: (state: any) => state.lastCourse,
    userShouldSeeDexEssays: (state: any, getters: any) => {
      const user = state.user;

      return (
        !!user &&
        !!user.featureFlags &&
        !!user.courses &&
        ((user.featureFlags.beta &&
          getters.userBetaIsEnabled &&
          user.featureFlags["beta-dex-essays"] &&
          (user.featureFlags["beta-dex-essays"] === "all" ||
            user.featureFlags["beta-dex-essays"]
              .split("/")
              .some(courseSlug =>
                user.courses.map(c => c.slug).includes(courseSlug)
              ))) ||
          ((window as any).isRNWebView && user.featureFlags["learnShowEssays"]))
      );
    },
    userBetaIsEnabled: (state: any) =>
      !!(
        state.user &&
        state.user.settings &&
        state.user.settings.experiments &&
        state.user.settings.experiments.betaEnabled
      )
  },
  mutations: {
    [SET_USER](state: any, user: any) {
      state.user = user;
    },
    [SET_TOKEN](state: any, token: any) {
      state.token = token;
      localStorage.setItem("token", token);
    },
    [SIGNOUT](state: any) {
      localStorage.removeItem("token");
      VueCookie.delete("d", { domain: ".descomplica.com.br" });
      state.user = null;
      state.token = null;
    },
    [SET_IMAGINIE_JWT](state: any, imaginieJWT: any) {
      state.imaginieJWT = imaginieJWT;
    },
    [SET_IMAGINIE_INFO](state: any, userInfo: any) {
      state.imaginieUser = userInfo;
    },
    [SET_ADBLOCK_INFO](state: any, isUsingAdBlock: boolean) {
      state.isUsingAdBlock = isUsingAdBlock;
    },
    [SET_USER_ESSAY_CREDITS](state: any, updatedCreditAmount: number) {
      state.essayCredits = updatedCreditAmount;
    },
    [SET_LAST_COURSE](state: any, lastCourse: { name: string; slug: string }) {
      state.lastCourse = lastCourse;
    },
    [UPDATE_USER_EXPERIMENTS_SETTINGS](state: any, betaEnabled: boolean) {
      state.user = {
        ...state.user,
        settings: {
          experiments: {
            betaEnabled
          }
        }
      };
    }
  },
  actions: {
    async [PULL_USER_ESSAY_CREDITS]({ commit, state }: any) {
      try {
        let response = await query(
          `query pullUserEssayCredits {
            getEssayCredits {
              total
              used
            }
          }`
        );
        let availableCreditsAmount = response.data.getEssayCredits.total;
        let usedCreditsAmount = response.data.getEssayCredits.used;
        let totalCreditsAmount = availableCreditsAmount - usedCreditsAmount;
        commit(SET_USER_ESSAY_CREDITS, totalCreditsAmount);
      } catch (error) {
        console.error(error);
        commit(SET_USER_ESSAY_CREDITS, -1);
        throw new Error();
      }
    },
    async [VALIDATE]({ commit, state }: any) {
      let d = VueCookie.get("d", { domain: ".descomplica.com.br" });
      if (d) {
        try {
          let cookieResponse = await fetch(`${cookie_url}`, {
            method: "POST",
            mode: "cors",
            headers: {
              "Content-Type": "application/json",
            },
            body: JSON.stringify({
              d: d
            })
          });
          if (cookieResponse.status !== 200) {
            commit(SIGNOUT);
            return;
          }
          let { token, user, refreshedCookieD } = await cookieResponse.json();
          if (refreshedCookieD) {
            VueCookie.set("d", refreshedCookieD, {
              domain: ".descomplica.com.br",
              expires: "31D"
            });
          }

          commit(SET_TOKEN, token);
          let response = await chimeraQuery(`
            query {
              chimera {
                me {
                  email
                  name
                  firstName
                  lastName
                  isSubscriber
                  isPayer
                  featureFlags(names: [
                    "beta",
                    "beta-dex-essays",
                    "learnShowEssays",
                  ])
                  courses {
                    slug
                  }
                  settings {
                    experiments {
                      betaEnabled
                    }
                  }
                }
                course {
                  name
                  slug
                  currentWeekPlanning {
                    serial
                  }
                }
              }
            }
          `);

          let me = response.data.chimera.me;
          let chimeraMeUserData;
          if (me != null) {
            user.firstName = me.firstName;
            user.lastName = me.lastName;
            chimeraMeUserData = {
              isSubscriber: !!(me && me.isSubscriber),
              featureFlags: me.featureFlags,
              courses: me.courses,
              settings: me.settings,
              isPayer: !!(me && me.isPayer)
            };
            // TODO: This should be done at dex-auth!
            if (user.name != me.name || user.email != me.email) {
              await mutation(
                `
                  mutation updateUser($userId: Int!, $name: String!, $email: String!) {
                    updateUserById(input: {
                      id: $userId,
                      userPatch: {
                        name: $name,
                        email: $email
                      }
                    }) {
                      user {
                        name
                      }
                    }
                  }
                `,
                {
                  userId: user.id,
                  name: me.name,
                  email: me.email
                }
              );
            }
          }
          if (user) {
            commit(SET_USER, {
              ...user,
              ...chimeraMeUserData
            });
            commit(SET_LAST_COURSE, response.data.chimera.course);
          } else {
            commit(SIGNOUT);
          }
        } catch (e) {
          commit(SIGNOUT);
          return;
        }
      } else {
        commit(SIGNOUT);
      }
    },
    async [APPOSTILA_AUTH]({ commit, state }: any, token: string) {
      let dexQuery = await fetch(`${authTokenUrl}`, {
        method: "POST",
        mode: "cors",
        body: JSON.stringify({
          token: token
        })
      });
      let dexUser = await dexQuery.json();
      commit(SET_TOKEN, token);
      if (!dexUser) {
        throw new Error("Invalid Token");
      } else {
        commit(SET_USER, { ...dexUser, isSubscriber: true });
      }
    },
    async [IMAGINIE_AUTH]({ commit, state }: any) {
      let response = await fetch(`${imaginieBasicURL}/api-token-auth/`, {
        method: "POST",
        headers: {
          Accept: "application/json",
          "Content-Type": "application/json"
        },
        body: `{
                        "username": "descomplica",
                        "password": "descomplica"
                    }`
      });
      let jwt = await response.json();
      commit(SET_IMAGINIE_JWT, jwt.token);
      let jwtInfo = jwt_decode(jwt.token);
      let userResponse = await fetch(
        `${imaginieBasicURL}/v3/school_users/${jwtInfo.profile_id}`,
        {
          method: "GET",
          headers: {
            Authorization: `JWT ${jwt.token}`
          }
        }
      );
      let userInfo = await userResponse.json();
      commit(SET_IMAGINIE_INFO, userInfo);
    },
    async [UPDATE_USER]({ commit, state }: any, user: any) {
      let response = await chimeraMutation(
        `mutation updateUser($firstName: String, $lastName: String){
                chimera {
                    updateUser(user: {
                        firstName: $firstName,
                        lastName: $lastName
                    }) {
                        errors {
                            key
                            message
                        }
                    }
                }
            }`,
        {
          firstName: user.firstName,
          lastName: user.lastName
        }
      );
    },
    async [PUSH_USER_EXPERIMENTS_SETTINGS](
      _: any,
      args: { isEnabled: boolean }
    ) {
      return chimeraMutation(
        `
          mutation ToggleChimeraBeta($isEnabled: Boolean!) {
            chimera {
              upsertUserExperimentsSetting(
                isEnabled: $isEnabled
              )
            }
          }
        `,
        {
          isEnabled: args.isEnabled
        }
      );
    }
  }
};

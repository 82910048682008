<template>
  <div>
    <div class="navigation">
      <arrow-left 
        v-if="canAdvanceBackward"
        fillColor="#08db88"
        class="nav-button clickable" 
        @click.native="prevSlide" />
      <arrow-left 
        v-else
        fillColor="#ECECEC"
        class="nav-button" />
      <arrow-right 
        v-if="canAdvanceForward"
        fillColor="#08db88"
        class="nav-button clickable"
        @click.native="nextSlide" />
      <arrow-right
        v-else
        fillColor="#ECECEC"
        class="nav-button" />
    </div>
    <carousel 
      ref="carousel"
      :mouseDrag="true"
      :per-page="4" 
      :paginationEnabled="false"
      :per-page-custom="[[0, 1], [361, 2], [740, 3], [1366, 4], [1600, 5]]">
      <slide 
        v-for="list in lists" 
        :key="list.id" 
        :list="list">
        <ListCard class="inside-carousel" :list="list" :board="board"/>
      </slide>
    </carousel>
  </div>
</template>

<style lang="less">
@import '../../dex-shared/styles/imports.less';
.classroom {
  @media @desktop {
    max-width: calc(100vw - 250px);
    margin-right: -20px;
    margin-left: -10px;
    margin-bottom: 20px;
  }
  @media @mobile {
    max-width: calc(100vw - 40px);
    .navigation {
      margin-top: 0px;
    }
  }
}

.navigation {
  display: flex;
  justify-content: flex-end;
  height: 48px;
  margin-right: 10px;
  margin-top: -48px;
  .material-design-icon__svg {
    cursor: pointer;
    height: 48px !important;
    width: 48px !important;
  }
}

.board {
  .navigation {
    display: none;
  }
}

.call-to-action {
  max-width: 100%;
  overflow: hidden;
}
</style>

<script>
import { Carousel, Slide } from "vue-carousel";
import ArrowLeft from "vue-material-design-icons/ChevronLeft.vue";
import ArrowRight from "vue-material-design-icons/ChevronRight.vue";
import ListCard from "./ListCard.vue";

export default {

  components: {
    ListCard,
    Carousel,
    Slide,
    ArrowLeft,
    ArrowRight
  },
  data() {
    return {
      canAdvanceBackward: false,
      canAdvanceForward: false
    }
  },
  props: ["lists", "board"],
  methods: {
    nextSlide() {
      this.$refs.carousel.goToPage(this.$refs.carousel.getNextPage());
      this.checkPage();
    },
    prevSlide() {
      this.$refs.carousel.goToPage(this.$refs.carousel.getPreviousPage());
      this.checkPage();
    },
    checkPage() {
      this.canAdvanceBackward = this.$refs.carousel.canAdvanceBackward;
      this.canAdvanceForward = this.$refs.carousel.canAdvanceForward;
    }
  },
  mounted() {
    this.checkPage();
  },
  updated() {
    this.checkPage();
  }

};
</script>

import { render, staticRenderFns } from "./ImageShow.vue?vue&type=template&id=4618051c&scoped=true&"
import script from "./ImageShow.vue?vue&type=script&lang=ts&"
export * from "./ImageShow.vue?vue&type=script&lang=ts&"
import style0 from "./ImageShow.vue?vue&type=style&index=0&id=4618051c&lang=less&scoped=true&"


/* normalize component */
import normalizer from "!../../../../../node_modules/vue-loader/lib/runtime/componentNormalizer.js"
var component = normalizer(
  script,
  render,
  staticRenderFns,
  false,
  null,
  "4618051c",
  null
  
)

export default component.exports













import Vue from "vue";
export default Vue.extend({
  props: ["videoId", "uniqueId"],

  data: function() {
    return {
      PARTNER_ID: 1612851,
      UICONF_ID: 21336162,
      oldID: ""
    };
  },

  computed: {
    ENTRY_ID: function() {
      return this.videoId;
    },
    url: function(): string {
      return `https://cdnapisec.kaltura.com/p/${this.PARTNER_ID}/sp/${
        this.PARTNER_ID
      }00/embedIframeJs/uiconf_id/${this.UICONF_ID}/partner_id/${
        this.PARTNER_ID
      }?entry_id=${this.ENTRY_ID}&playerId=${
        this.uniqueId
      }&cache_st=1362074486&autoembed=true&width=400&height=333&`;
    }
  },
  mounted() {
    this.addVideo(this.videoId);
  },
  updated() {
    this.removeVideo(this.oldID);
    this.addVideo(this.videoId);
  },
  methods: {
    removeVideo: function(videoId: any) {
      let oldVideo = document.getElementById(this.uniqueId + "_ifp");
      if (oldVideo) {
        oldVideo.remove();
      }
    },
    addVideo: function(videoId: any) {
      let newVideo = document.createElement("script");
      newVideo.setAttribute("src", this.url);
      // parentDiv is null after removing video
      let parentDiv = document.getElementById(this.uniqueId);
      if (parentDiv) {
        parentDiv.appendChild(newVideo);
      }
      this.oldID = this.videoId;
    }
  }
});

type HTTPMethod = "GET" | "POST" | "PUT" | "DELETE";
interface JSONParams {
  [key: string]: any;
}

export default class Rest {
  public tableName: string;
  public static apiURL: string;

  constructor(tableName: string) {
    this.tableName = tableName;
  }

  public getTableName(): string {
    return this.tableName;
  }

  public setTableName(tableName: string): void {
    this.tableName = tableName;
  }

  public static getApiURL(): string {
    return this.apiURL;
  }

  public getTableURL(): string {
    return Rest.apiURL + "/" + this.tableName;
  }

  public static setApiURL(apiURL: string): void {
    this.apiURL = apiURL;
  }

  public async index(params: JSONParams = {}): Promise<any[]> {
    return await this.classAction("GET", null, params);
  }

  public async show(id: string, params: JSONParams = {}): Promise<any> {
    return await this.entityAction(id, "GET", null, params);
  }

  public async validate(params: JSONParams = {}): Promise<any> {
    return await this.classAction("POST", "validate", params, true);
  }

  public async create(params: JSONParams = {}): Promise<any> {
    return await this.classAction("POST", null, params);
  }

  public async update(id: string, params: JSONParams = {}): Promise<any> {
    return await this.entityAction(id, "PUT", null, params);
  }

  public async destroy(id: string, params: JSONParams = {}): Promise<any> {
    return await this.entityAction(id, "DELETE", null, params);
  }

  public async entityAction(
    id: string,
    method: HTTPMethod,
    path: string | null,
    params: JSONParams = {}
  ): Promise<any> {
    let urlParams = Object.entries(params)
      .map(pair => pair.map(encodeURI).join("="))
      .join(";");
    let token = localStorage.getItem("token");
    if (method == "GET") {
      let response = await fetch(
        Rest.apiURL +
          `/${this.tableName}${id ? `/${id}` : ""}/${
            path ? path : ""
          }?${urlParams}`,
        {
          headers: {
            Authentication: `Bearer ${token}`,

            "Content-Type": "application/json"
          },
          method
        }
      );
      return await response.json();
    } else {
      let response = await fetch(
        Rest.apiURL +
          `/${this.tableName}${id ? `/${id}` : ""}/${path ? path : ""}`,
        {
          headers: {
            Authentication: `Bearer ${token}`,
            "Content-Type": "application/json"
          },
          method,
          body: JSON.stringify(params)
        }
      );
      return await response.json();
    }
  }

  public async classAction(
    method: HTTPMethod,
    path: string | null,
    params: JSONParams = {},
    hideLoader: boolean = false
  ): Promise<any> {
    let urlParams = Object.entries(params)
      .map(pair => pair.map(encodeURI).join("="))
      .join(";");
    if (method == "GET") {
      let response = await fetch(
        Rest.apiURL +
          `/${this.tableName}` +
          `/${path ? path : ""}?${urlParams}`,
        {
          headers: {
            Authentication: `Bearer ${localStorage.getItem("token")}`,
            "Content-Type": "application/json"
          },
          method
        }
      );
      return await response.json();
    } else {
      let response = await fetch(
        Rest.apiURL + `/${this.tableName}` + `/${path ? path : ""}`,
        {
          headers: {
            Authentication: `Bearer ${localStorage.getItem("token")}`,
            "Content-Type": "application/json"
          },
          method,
          body: JSON.stringify(params)
        }
      );
      return await response.json();
    }
  }
}

const STORAGE_KEY = 'SolutionsStorage';
const DEFAULT_PROPS = { userSentSolution: false };

interface SolutionsStorage {
  storageKey: string,
  props: any
}

class SolutionsStorage {
  constructor(storageKey: string, defaultProps: any) {
    this.storageKey = storageKey;
    this.props = JSON.parse(localStorage.getItem(this.storageKey) || 'null') || defaultProps; 
  }

  get userSentSolution() {
    return this.props.userSentSolution;
  }

  set(prop: string, value: boolean) {
    this.props[prop] = value;
    localStorage.setItem(this.storageKey, JSON.stringify(this.props));
  }
}

const solutionsStorage = new SolutionsStorage(STORAGE_KEY, DEFAULT_PROPS);

export default solutionsStorage;
import { ApolloClient } from "apollo-client";
import { ApolloLink } from "apollo-link";
import { BatchHttpLink } from "apollo-link-batch-http";
import { RetryLink } from "apollo-link-retry";
import { setContext } from "apollo-link-context";
import { InMemoryCache } from "apollo-cache-inmemory";
import {
  graphqlBasicURL,
  chimeraBasicURL,
} from "@/dex-shared/settings/endpoints";
import gql from "graphql-tag";
import QueueLink from "apollo-link-queue";
import VueCookie from "vue-cookie";
import fetch from "unfetch";

const httpLink = new BatchHttpLink({
  uri: graphqlBasicURL,
  fetch: fetch as any,
});

// const httpLink = new createUploadLink({
//   uri: graphqlBasicURL
// });

const offlineLink = new QueueLink();

window.addEventListener("offline", () => offlineLink.close());
window.addEventListener("online", () => offlineLink.open());

const authLink = setContext((_, { headers }) => {
  const token = localStorage.getItem("token");

  if (token) {
    return {
      headers: {
        ...headers,
        authorization: token ? `Bearer ${token.replace(/"/g, "")}` : "",
      },
    };
  } else {
    return;
  }
});

const cacheLink = setContext((_, { headers }) => {
  const disableCacheHeader =
    process.env.VUE_APP_DISABLE_CACHE === "true"
      ? { "cache-control": "no-cache" }
      : {};

  return {
    headers: {
      ...headers,
      ...disableCacheHeader,
    },
  };
});

const link = ApolloLink.from([
  new RetryLink(),
  offlineLink,
  authLink,
  cacheLink,
  httpLink,
]);

// Create the apollo client
export const apolloClient = new ApolloClient({
  link: link,
  cache: new InMemoryCache(),
  connectToDevTools: true,
  // disable cache in order to guarantee reactivity in board
  defaultOptions: {
    query: {
      fetchPolicy: "no-cache",
    },
  },
});

export async function query(
  query: string,
  variables?: { [key: string]: any }
): Promise<any> {
  return await apolloClient.query<any>({
    query: gql(query),
    variables: variables,
  });
}

export async function mutation(
  query: string,
  variables?: { [key: string]: any }
): Promise<any> {
  return await apolloClient.mutate<any>({
    mutation: gql(query),
    variables: variables,
  });
}

var chimeraHttpLink = new BatchHttpLink({
  uri: chimeraBasicURL,
  fetch: fetch as any,
});

var chimeraAuthLink = setContext((_, { headers }) => {
  const dCookie = VueCookie.get("d", { domain: ".descomplica.com.br" });

  if (dCookie) {
    return {
      headers: {
        ...headers,
        authorization: dCookie ? `Bearer ${dCookie.replace(/"/g, "")}` : "",
      },
    };
  } else {
    return;
  }
});

const chimeraLink = ApolloLink.from([chimeraAuthLink, chimeraHttpLink]);

export const chimeraClient = new ApolloClient({
  link: chimeraLink,
  cache: new InMemoryCache(),
  connectToDevTools: true,
  defaultOptions: {
    query: {
      fetchPolicy: "no-cache",
    },
  },
});

export async function chimeraQuery(
  query: string,
  variables?: { [key: string]: any }
): Promise<any> {
  return await chimeraClient.query<any>({
    query: gql(query),
    variables: variables,
  });
}

export async function chimeraMutation(
  query: string,
  variables?: { [key: string]: any }
): Promise<any> {
  return await chimeraClient.mutate<any>({
    mutation: gql(query),
    variables: variables,
  });
}

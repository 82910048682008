




















import Content from "../../dex-shared/components/content/Content.vue";
import InteractiveData from "./interactive_data/InteractiveData.vue";
import SendReport from "../../dex-shared/components/report/SendReport.vue";

export default {
  props: ["question", "onAnswer"],

  components: {
    Content,
    InteractiveData,
    SendReport, 
  },

  computed: {
    index: function():any {
      return parseInt(this.$route.params.itemIndex) - 1
    },
  },
};
















































































import Vue from "vue";

export default Vue.extend({
  props: ['correctRepliesCount', 'wrongRepliesCount', 'listQuestionsTotal'],

  computed: {
    redRadius() {
      return this.wrongRepliesCount > 0
        ? '0 10px 10px 0'
        : '0 0 0 0';
    }
  },
});
































































































import Vue from "vue";

export default Vue.extend({
  // tabs: array of strings, initialIndex: number
  props: ["tabs", "initialIndex"],

  data() {
    return {
      active: this.initialIndex || 0
    };
  },

  methods: {
    isActive(i: number) {
      if (i == this.active) return "st-active";
    },
    makeActive(i: number) {
      this.active = i;
      this.$emit("makeTabActive", i);
    }
  }
});

import stringSimilarity from 'string-similarity';
import knowledges from './knowledges';

const similarity = (a: string, b: string) => stringSimilarity.compareTwoStrings(a, b);
const searchRecommendations = (searchTerm: string) => 
    knowledges
        .filter(k => similarity(k.searchTerm, searchTerm) > 0.2)
        .sort((a, b) => similarity(searchTerm, b.searchTerm) - similarity(searchTerm, a.searchTerm))
        .slice(0, 10);

export default searchRecommendations;
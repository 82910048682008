























































import Vue from "vue";
import { mapState } from "vuex";

export default Vue.extend({
  props: {
    beta: {
      type: Boolean,
      default: false
    },
    textColor: {
      type: String,
      default: "#000"
    }
  },
  computed: {
    ...mapState({
      classroom: (state: any) => state.classrooms.currentClassroom
    }),
    shapeColor() {
      if (
        this.$route.name === "course" ||
        (this.classroom.course && this.classroom.course.name === "Concursos")
      ) {
        // Concursos
        return "#269FF9";
      } else {
        // Enem
        return "#00E88F";
      }
    }
  }
});

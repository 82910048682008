import { query } from '@/dex-shared/helpers/graphql';
import DateProvider from "@/models/DateProvider";

// mutations
const SET_LIST_HISTORY = 'SET_LIST_HISTORY';
const SET_BOARDS_REPLIES = 'SET_BOARDS_REPLIES';
const SET_BOARDS_GRAPH_DATA = 'SET_BOARDS_GRAPH_DATA';
const SET_TOTAL_DAYS_DOING_EXERCISES = 'SET_TOTAL_DAYS_DOING_EXERCISES';

// actions
const PULL_LIST_HISTORY = 'PULL_LIST_HISTORY';
const PULL_BOARDS_REPLIES = 'PULL_BOARDS_REPLIES';
const PULL_TOTAL_DAYS_DOING_EXERCISES = 'PULL_TOTAL_DAYS_DOING_EXERCISES';

export default {
  state: {
    listsHistory: [],
    boardsReplies: [],
    boardsGraphData: [],
    totalDaysDoingExercises: 0,
  },
  mutations: {
    [SET_LIST_HISTORY](state: any, { listHistory, lists }: any) {
      state.listsHistory = [
        ...state.listsHistory,
        ...listHistory.map(historyData => ({
          ...historyData,
          completedLists: historyData.completedListsIds.map(cli => lists[cli]),
          uncompletedLists: historyData.uncompletedListsIds.map(cli => lists[cli]),
        })),
      ];
    },
    [SET_BOARDS_REPLIES](state: any, boardsReplies: any) {
      state.boardsReplies = boardsReplies.map(b => {
        const correct = b.boardReplies.nodes.filter(br => br.answer == br.questionByQuestionId.answer).length;
        const wrong = b.boardReplies.nodes.filter(br => br.answer != br.questionByQuestionId.answer).length;

        return {
          ...b,
          boardReplies: {
            total: correct + wrong,
            correct,
            wrong,
          },
          totalLists: [...new Set(
            b.boardReplies.nodes.reduce((acc, br) => acc.concat(
              br.questionByQuestionId.listItemsByQuestionId.nodes.map(n => n.listId)
            ), [])
          )].length,
          allReplies: b.boardReplies.nodes.map(br => ({
            createdAt: br.createdAt,
            correct: br.answer == br.questionByQuestionId.answer,
          })),
        };
      });
    },
    [SET_BOARDS_GRAPH_DATA](state: any, boardsReplies: any) {
      state.boardsGraphData = boardsReplies;
    },
    [SET_TOTAL_DAYS_DOING_EXERCISES](state: any, totalDaysDoingExercises: number) {
      state.totalDaysDoingExercises = totalDaysDoingExercises;
    }
  },
  actions: {
    async [PULL_TOTAL_DAYS_DOING_EXERCISES]({ commit, getters }: any) {
      const userResponse = await query(
        `
          query DaysDoingExercises($userId: Int!) {
            allUsers(condition: {
              id: $userId
            }) {
              nodes {
                totalDaysDoingExercises
              }
            }
          }
        `,
        { userId: getters.userId }
      );
      if (!userResponse.data.allUsers.nodes[0]) return;

      commit(SET_TOTAL_DAYS_DOING_EXERCISES, userResponse.data.allUsers.nodes[0].totalDaysDoingExercises);
    },
    async [PULL_LIST_HISTORY]({ commit, getters }: any, { limit = 0, offset = 0 }: any) {
      let timezoneOffset = new DateProvider().timezoneOffset;
      const listHistoryResponse = await query(
        `
          query GetListHistory($userId: Int!, $limit: Int, $offset: Int, $timezoneOffset: Int) {
            getUserListHistory(userId: $userId, timezoneOffset: $timezoneOffset, limit: $limit, offset: $offset) {
              day
              month
              year
              qtdCompletedLists
              completedListsIds
              qtdUncompletedLists
              uncompletedListsIds
              streakCount
            }
          }
        `,
        { userId: getters.userId, limit, offset, timezoneOffset }
      );
      const listIds = [...new Set(
        listHistoryResponse.data.getUserListHistory
          .reduce((acc, { completedListsIds, uncompletedListsIds }) => acc.concat([
            ...completedListsIds,
            ...uncompletedListsIds
          ]), [])
      )
      ];
      let listsResponse;
      if (listIds.length) {
        listsResponse = await query(
          `
            query getAllListsOnDexWeb{
              allLists(filter: {
                id: {
                  in: [${listIds.join(',')}]
                }
              }) {
                nodes {
                  id
                  name
                  slug
                  questionsTotal
                  answersByUser {
                    correct
                    wrong
                  }
                  materialsByListId(filter: {
                    boardId: {isNull: false}
                  }) {
                    nodes {
                      boardByBoardId{
                        id
                        title
                        slug
                        boardsClassroomsByBoardId {
                          nodes {
                            classroomByClassroomId {
                              id
                              name
                              slug
                            }
                          }
                        }
                      }
                    }
                  }
                }
              }
            }
          `
        );
      }
      const lists = !listsResponse ? [] : listsResponse.data.allLists.nodes.reduce((acc, list) => {
        acc[list.id] = {
          id: list.id,
          name: list.name,
          slug: list.slug,
          questionsTotal: list.questionsTotal,
          answersByUser: list.answersByUser || { correct: 0, wrong: 0 },
          boardSlug: list.materialsByListId.nodes[0] && list.materialsByListId.nodes[0].boardByBoardId.slug
        };

        return acc;
      }, {});
      commit(SET_LIST_HISTORY, { lists, listHistory: listHistoryResponse.data.getUserListHistory });
    },
    async [PULL_BOARDS_REPLIES]({ commit, getters }: any, classroomSlug: string) {
      const classroomsResponse = await query(
        `
          query ClassroomBySlug($classroomSlug: String!) {
            allClassrooms(condition: {
                slug: $classroomSlug
            }) {
              nodes {
                slug
                boardsClassroomsByClassroomId {
                  nodes {
                    boardByBoardId {
                      id
                      title
                      slug
                    }
                  }
                }
              }
            }
          }
        `,
        { classroomSlug }
      );
      const currentClassroom = classroomsResponse.data.allClassrooms.nodes.find(c => c.slug === classroomSlug);
      const boards = currentClassroom.boardsClassroomsByClassroomId.nodes.map(n => n.boardByBoardId);
      const boardIds = boards.map(b => b.id);

      const boardsRepliesResponse = await query(
        `
          query getBoardsRepliesResponse {
            ${boardIds.map(boardId => `
              repliesOfBoard_${boardId}: repliesByBoard(uid: ${getters.userId}, bid: ${boardId}) {
                totalCount
                nodes {
                  answer
                  createdAt
                  questionByQuestionId {
                    answer
                    listItemsByQuestionId(filter: {
                      listByListId: {
                        and: {
                          listSessionsByListId: {
                            some: {
                              userId: {
                                equalTo: ${getters.userId}
                              }
                            }
                          },
                          materialsByListId: {
                            some: {
                              boardId: {
                                equalTo: ${boardId}
                              }
                            }
                          }
                        }
                      }
                    }) {
                      nodes {
                        listId
                      }
                    }
                  }
                }
              }
            `)}
          }
        `
      );

      const boardsReplies = Object.keys(boardsRepliesResponse.data)
        .reduce((acc, repliesOfBoard) => acc.concat([{
          ...boards.find(b => b.id === +repliesOfBoard.split("repliesOfBoard_")[1]),
          boardReplies: {
            ...boardsRepliesResponse.data[repliesOfBoard]
          }
        }
        ]), []);

      commit(SET_BOARDS_GRAPH_DATA, boardsReplies);
      commit(SET_BOARDS_REPLIES, boardsReplies);
    },
  }
}
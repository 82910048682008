



















import Vue from "vue";
import Content from "../../../dex-shared/components/content/Content.vue";
import InteractiveDataShow from "./InteractiveDataShow.vue";
import InteractiveDataResults from "./InteractiveDataResults.vue";
import { mapGetters, mapActions } from "vuex";

export default Vue.extend({

  components: {
    Content,
    InteractiveDataShow,
    InteractiveDataResults
  },
  props: ["interactiveData", "question", "onAnswer", "abTestVariant"],
  computed: {
    ...mapGetters({
      sessionQuestionReply: "sessionQuestionReply",
    })
  },

  methods: {
    ...mapActions({
      pullQuestionSolutions: "PULL_QUESTION_SOLUTIONS"
    }),
    answerQuestion(answer:any) {
      this.$emit('onAnswer', answer)
    }
  }
});

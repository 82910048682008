import Vue from 'vue';
import VueDonutChart from 'vue-css-donut-chart';
import 'vue-material-design-icons/styles.css';
import 'vue2-animate/dist/vue2-animate.min.css';
import App from './App.vue';
import router from './router';
import store from './store';
import './registerServiceWorker';
import Rest from './dex-shared/lib/Rest';
import './dex-shared/styles/imports.less';
import {authenticationURL} from '@/dex-shared/settings/endpoints';
import VueAnalytics, { onAnalyticsReady } from "vue-analytics";
import Vue2TouchEvents from "vue2-touch-events";
import VueGtm from 'vue-gtm';
import * as Sentry from "@sentry/vue";
import { Integrations } from '@sentry/tracing';
import ABTest from '@/dex-shared/helpers/abTests/index';
import VueMultianalytics from 'vue-multianalytics';
import track from './dex-shared/vue-plugins/track';
import { cdn } from "@/dex-shared/lib/cdn";
import { loadPOCKET } from "@/dex-shared/lib/pocket";


const testInstance = new ABTest();

testInstance.initialize();

const isProd = process.env.NODE_ENV === "production";
const isStaging = isProd && process.env.VUE_APP_CUSTOM_ENV_MODE === "staging";
const isDebug = !isProd || isStaging;

if (isProd) {
  Sentry.init({
    Vue,
    dsn: process.env.VUE_APP_SENTRY_DNS,
    sampleRate: process.env.NODE_ENV === 'production' ? 0.005 : 1,
    tracesSampleRate: process.env.NODE_ENV === 'production' ? 0.1 : 1,
    environment: process.env.NODE_ENV,
    integrations: [new Integrations.BrowserTracing()],
    release: `dex-web@${process.env.VUE_APP_COMMIT_SHA}`,
  });
}

const handleAuthenticationType = async queryParams => {
  let { appostila_token: appostilaToken } = queryParams;
  if (appostilaToken) {
    return await store.dispatch("APPOSTILA_AUTH", appostilaToken);
  } else {
    return await store.dispatch("VALIDATE");
  }
}

const isAnalyticsReady = () => (new Promise((resolve, reject) => {
  onAnalyticsReady()
    .then(() => loadPOCKET())
    .then(() => resolve(true));

  const options: RequestInit = { mode: 'no-cors' };
  if (isDebug) {
    fetch('https://www.google-analytics.com/analytics_debug.js', options)
      .catch(() => resolve(false));
  } else {
    fetch('https://www.google-analytics.com/analytics.js', options)
      .catch(() => resolve(false));
  }
}));

const loadHsScript = () => {
  var script = document.createElement("script");

  script.type = "text/javascript";
  script.async = true;
  script.defer = true;
  script.id = "hs-script-loader";
  script.src = "//js.hs-scripts.com/1653949.js";

  document.head.appendChild(script);
}  

async function main() {

  // Install the vue plugin
  Vue.use(Vue2TouchEvents);
  Vue.use(track, { dispatch: store.dispatch });

  try {
    Vue.use(VueMultianalytics, {
      modules: {
        mixpanel: {
          token: '760dfcc19d45ca96a003a0643baf7f3a',
          debug: isDebug,
        }
      },
      routing: {
        vueRouter: router
      }
    });
  } catch(e) {
    console.error(e);
  }

  try {
    Vue.use(VueAnalytics, {
      // id: ['UA-77155958-4', 'UA-6492218-1'],
      id: 'UA-77155958-4',
      fields: {
        userId: store.getters.userId
      },
      router,
      autoTracking: {
        exception: true
      },
      debug: {
        enabled: isDebug,
        sendHitTask: isProd && !isStaging
      }
    });
  } catch(e) {
    console.error(e);
  }

  try {
    loadHsScript();
    await isAnalyticsReady();
    Vue.use(VueGtm, {
      id: 'GTM-N3PV5RH',
      debug: isDebug,
      vueRouter: router
    });
  } catch(e) {
    console.error('Script 1653949.js and analytics cannot load, error:', e);
  }
  // await store.commit("SET_ADBLOCK_INFO", !analyticsReady);

  Rest.apiURL = authenticationURL;
  Vue.config.productionTip = false;

  Vue.use(VueDonutChart);

  const vueApp = new Vue({
    router,
    store,
    render: h => h(App)
  });

  await handleAuthenticationType(vueApp.$route.query)

  vueApp.$mount('#app');
}

main();



































import Vue from "vue";
import CheckIcon from "vue-material-design-icons/Check.vue";
import ResultCard from "./ResultCard.vue";

export default Vue.extend({
  components: {
    ResultCard,
    CheckIcon
  },
  props: ["question"]
});

import TrackingEvents from '@/dex-shared/models/Tracking/TrackingEvents';
import { TRACK_TYPES, injectPayloadMetadata } from '@/dex-shared/helpers/track';

export default store => {
    const TRACK = "TRACK";
  store.subscribeAction((action, rootState) => {
    const { type, payload } = action;

    if (type == TRACK) {
      switch (payload.actionName) {
        case TRACK_TYPES.REPORT_SENT:
          return new TrackingEvents(rootState).track(
            injectPayloadMetadata(payload, { eventName: TRACK_TYPES.REPORT_SENT })
          );
        default:
          return;
      }
    }
  });
};
import * as _ from "lodash";
import tests from "./tests";
import TestBuilder from "./testBuilder";

declare interface ABTest {
  localStorage: any;
  savedTests: Object;
}

class ABTest {
  constructor() {
    this.localStorage = localStorage;
    this.savedTests = tests;
  }

  private testsWithLocalStorageFormat() {
    let testConsistor = new TestBuilder({ savedTests: this.savedTests });
    return testConsistor.testsWithVariantSelected();
  }

  private setComposedTestInterfaceOnLocalStorage(testInterface) {
    this.localStorage.setItem("ABTests", JSON.stringify(testInterface));
  }

  public getExperimentByName(name) {
    let ABTests = JSON.parse(this.localStorage.getItem("ABTests"));
    if (_.has(ABTests, name)) {
      let localTestVariant = _.get(ABTests, name);
      return {
        variant: localTestVariant,
        element: this.savedTests[name].data[localTestVariant].element
      };
    } else {
      throw new Error(`Experiment ${name} not found.`);
    }
  }

  public initialize() {
    let formattedTests = this.testsWithLocalStorageFormat();
    this.setComposedTestInterfaceOnLocalStorage(formattedTests);
    return formattedTests;
  }
}

export default ABTest;

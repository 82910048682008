




























import Vue from "vue";
import CloseIcon from "vue-material-design-icons/Close.vue";
import ResultCard from "./ResultCard.vue";

export default Vue.extend({
  components: {
    ResultCard,
    CloseIcon
  },
  props: ["question"],
});




































import Vue from "vue";
import lottie from "lottie-web";
import InteractiveData from "@/components/question/interactive_data/InteractiveData.vue";
import ABTest from "@/dex-shared/helpers/abTests/index";

export default Vue.extend({
  components: {
    InteractiveData
  },
  props: ["investigation", "onAnswer"],
  data() {
    return {
      animationInstance: null,
      userAnsweredFeedback: false
    };
  },
  computed: {
    animacaoDaInvestigation() {
      let abTest = new ABTest();
      return abTest.getExperimentByName("animacaoDaInvestigation");
    }
  },
  mounted() {
    this.buildAnimation();
  },
  methods: {
    buildAnimation() {
      let instance = lottie.loadAnimation({
        container: this.$refs.sluthAnimatedImage,
        renderer: "svg",
        loop: true,
        autoplay: true,
        animationData: this.animacaoDaInvestigation.element
      });
      this.animationInstance = instance;
    },
    removeAnimation() {
      this.animationInstance.destroy();
    },
    onUserAnswer(index) {
      this.userAnsweredFeedback = true;

      this.onAnswer(index);
    }
  }
});

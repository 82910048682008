import Vue from 'vue';
import Vuex from 'vuex';
import auth from '@/dex-shared/store/user';
import modals from '@/dex-shared/store/modals';
import classrooms from './modules/classrooms';
import irt from './modules/irt';
import lists from './modules/lists';
import boards from '@/store/modules/boards';
import investigations from '@/dex-shared/store/investigations';
import streaks from '@/store/modules/streaks';
import history from '@/store/modules/history';
import votes from '@/store/modules/votes';
import solutions from '@/store/modules/solutions';
import levels from '@/store/modules/levels';
import plugins from './plugins';
import events from '@/dex-shared/store/events';

Vue.use(Vuex);

const debug = process.env.NODE_ENV === 'development';

export default new Vuex.Store({
    modules: {
        auth,
        modals,
        classrooms,
        irt,
        lists,
        boards,
        investigations,
        streaks,
        history,
        votes,
        solutions,
        levels,
        events
    },
    strict: debug,
    plugins
});
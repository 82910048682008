













import Vue, { PropType } from "vue";
import VideoModel from "@/dex-shared/models/VideoModel";
export default Vue.extend({
  props: {
    video: {
      type: Object,
      required: true,
      validator: function (value:VideoModel) {
        return value.providerType === "KALTURA";
      }
    },
    uniqueId: {
      type: String,
      required: true
    }
  },

  data: function() {
    return {
      PARTNER_ID: 1612851,
      UICONF_ID: 21336162,
      oldID: ""
    };
  },

  computed: {
    ENTRY_ID: function() {
      return this.video.providerId;
    },
    url: function(): string {
      return `https://cdnapisec.kaltura.com/p/${this.PARTNER_ID}/sp/${
        this.PARTNER_ID
      }00/embedIframeJs/uiconf_id/${this.UICONF_ID}/partner_id/${
        this.PARTNER_ID
      }?entry_id=${this.video.providerId}&playerId=${
        this.uniqueId
      }&cache_st=1362074486&autoembed=true&width=400&height=333&`;
    }
  },
  mounted() {
    this.addVideo(this.video.providerId);
  },
  updated() {
    this.removeVideo(this.oldID);
    this.addVideo(this.video.providerId);
  },
  beforeDestroy() {
    this.removeVideo(this.video.providerId);
  },
  methods: {
    removeVideo: function(videoId: any) {
      let oldVideo = document.getElementById(this.uniqueId + "_ifp");
      if (oldVideo) {
        oldVideo.remove();
      }
    },
    addVideo: function(videoId: any) {
      let newVideo = document.createElement("script");
      newVideo.setAttribute("src", this.url);
      // parentDiv is null after removing video
      let parentDiv = document.getElementById(this.uniqueId);
      if (parentDiv) {
        parentDiv.appendChild(newVideo);
      }
      this.oldID = this.video.providerId;
    }
  }
});

import { query } from '@/dex-shared/helpers/graphql';
import { slugifyString } from '@/dex-shared/lib/StringHelper';

// mutations
let SET_ALL_CLASSROOMS = 'SET_ALL_CLASSROOMS';
let SET_CURRENT_BOARD_ID = 'SET_CURRENT_BOARD_ID';
let SET_CURRENT_BOARD_SLUG = 'SET_CURRENT_BOARD_SLUG';
let SET_CURRENT_BOARD = 'SET_CURRENT_BOARD';
let SET_CURRENT_BOARD_BY_SLUG = 'SET_CURRENT_BOARD_BY_SLUG';
let SET_CURRENT_CLASSROOM = 'SET_CURRENT_CLASSROOM';
let SET_POPULAR_LISTS = 'SET_POPULAR_LISTS';
let SET_CURRENT_CLASSROOM_WITH_LISTS = "SET_CURRENT_CLASSROOM_WITH_LISTS";

// actions
let PULL_BOARD = 'PULL_BOARD';
let PULL_CLASSROOM = 'PULL_CLASSROOM';
let PULL_POPULAR_LISTS = 'PULL_POPULAR_LISTS';

export default {
    state: {
        currentClassroom: {},
        currentBoard: {},
        popularLists: [],
        currentClassroomWithLists: {},
    },
    mutations: {
        [SET_CURRENT_CLASSROOM_WITH_LISTS](state: any, classroom: any) {
            state.currentClassroomWithLists = classroom;
        },
        [SET_CURRENT_CLASSROOM](state: any, classroom: any) {
            state.currentClassroom = classroom;
        },
        [SET_POPULAR_LISTS](state: any, lists: any[]) {
            state.popularLists = lists.map((list: any) => {
                if (list.materialsByListId.nodes.length > 0) {
                    list.board = list.materialsByListId.nodes[0].boardByBoardId;
                    if (list.board.boardsClassroomsByBoardId && list.board.boardsClassroomsByBoardId.nodes.length > 0)
                        list.classroom = list.board.boardsClassroomsByBoardId.nodes[0].classroomByClassroomId;
                }
                return list;
            });
        },
        [SET_CURRENT_BOARD_ID](state: any, boardId: number) {
            if (state.currentBoard.id) {
                state.currentBoard = { id: +boardId }
            } else {
                state.currentBoard.id = +boardId;
            }
        },
        [SET_CURRENT_BOARD_SLUG](state: any, boardSlug: string) {
            if (state.currentBoard.slug) {
                state.currentBoard = { slug: boardSlug }
            } else {
                state.currentBoard.slug = boardSlug;
            }
        },
        [SET_CURRENT_BOARD](state: any, board: any) {
            if (board && board.boardsClassroomsByBoardId && board.boardsClassroomsByBoardId.nodes.length > 0) {
                board.classroom = board.boardsClassroomsByBoardId.nodes[0].classroomByClassroomId;
            }
            state.currentBoard = board;
        },
        [SET_CURRENT_BOARD_BY_SLUG](state: any, boardSlug: any) {
            let currentBoard = {};
            let boards: any[] = [];
            if (state.currentClassroom.boardsClassroomsByClassroomId)
                boards = state.
                    currentClassroom.
                    boardsClassroomsByClassroomId.
                    nodes.map((b: any) => b.boardByBoardId);
            if (boards) {
                for (let board of boards)
                    if (board.slug === boardSlug)
                        currentBoard = board;
            }
            state.currentBoard = currentBoard;
        }
    },
    getters: {
        boards: (state: any) => {
            if (state.currentClassroom.boardsClassroomsByClassroomId)
                return state.
                    currentClassroom.
                    boardsClassroomsByClassroomId.
                    nodes.map((b: any) => b.boardByBoardId);
            else
                return [];
        },
        currentBoard: (state: any, getters: any) => {
            if (state.currentBoard && state.currentBoard.id && state.currentBoard.slug) {
                return state.currentBoard;
            } else if (state.currentBoard) {
                for (let board of getters.boards)
                    if (board.id === state.currentBoard.id || board.slug === state.currentBoard.slug)
                        return board;
                return null;
            } else {
                return null;
            }
        },
        currentClassroom: (state: any) => {
            return state.currentClassroom;
        },
        popularLists: (state: any) => state.popularLists,
    },
    actions: {
        async [PULL_BOARD]({ commit, getters, state }: any, boardInfo: any) {
            let boardId, boardSlug, response, board;
            if (isNaN(boardInfo)) {
                boardSlug = boardInfo;
                commit(SET_CURRENT_BOARD_SLUG, boardSlug);
                if (getters.currentBoard) {
                    boardId = getters.currentBoard.id;
                    commit(SET_CURRENT_BOARD_ID, boardId);
                }
                response = await query(
                    `query BoardBySlug($boardSlug: String!) {
                        allBoards(condition: {
                            slug: $boardSlug
                        }) {
                            nodes {
                                id
                                title
                                slug
                                description
                                boardsClassroomsByBoardId {
                                    nodes {
                                        classroomByClassroomId {
                                            id
                                            name
                                            slug
                                        }
                                    }
                                }
                            }
                        }
                    }`, {
                    boardSlug: boardSlug
                });
                board = response.data.allBoards.nodes[0];
            } else {
                boardId = boardInfo;
                commit(SET_CURRENT_BOARD_ID, boardId);
                if (getters.currentBoard) {
                    boardSlug = getters.currentBoard.slug;
                    commit(SET_CURRENT_BOARD_SLUG, boardSlug);
                } else {
                    response = await query(
                        `query BoardById($boardId: Int!) {
                        boardById(id: $boardId) {
                            id
                            title
                            slug
                            description
                            boardsClassroomsByBoardId {
                                nodes {
                                    classroomByClassroomId {
                                        id
                                        name
                                        slug
                                    }
                                }
                            }
                        }
                    }`, {
                        boardId: +boardId
                    })
                    board = response.data.boardById;
                }
            }
            if ((response && board) || (boardId && boardSlug)) {
                commit(SET_CURRENT_BOARD, board);
            } else {
                return false;
            }
        },
        async [PULL_CLASSROOM]({ commit }: any, classroomSlug: string) {
            if (classroomSlug == undefined) {
                classroomSlug = 'enem'
            } else {
                classroomSlug = slugifyString(classroomSlug);
            }
            let response = await query(`
            query GetClassroom($classroomSlug: String!){
                allClassrooms(condition: {
                    slug: $classroomSlug
                }){
                    nodes{
                        id
                        name
                        description
                        slug
                        boardsClassroomsByClassroomId{
                            nodes{
                                boardByBoardId{
                                    id
                                    title
                                    slug
                                    description
                                }
                            }
                        }
                    }
                }
            }`, {
                classroomSlug: classroomSlug
            });
            if (response.data.allClassrooms.nodes.length > 0) {
                commit(SET_CURRENT_CLASSROOM, response.data.allClassrooms.nodes[0]);
            } else {
                commit(SET_CURRENT_CLASSROOM, { error: true });
                return false;
            }
        },
        async ["PULL_CLASSROOM_WITH_LISTS"]({ commit, rootState }: any, classroomSlug: string) {
            let userId = rootState.auth.user ? rootState.auth.user.id : null
            if (classroomSlug == undefined) {
                classroomSlug = 'enem'
            } else {
                classroomSlug = slugifyString(classroomSlug);
            }
            let response = await query(`
            query GetClassroom($classroomSlug: String!, $userId: Int){
                allClassrooms(condition: {
                    slug: $classroomSlug
                }){
                    nodes{
                        id
                        name
                        description
                        slug
                        boardsClassroomsByClassroomId {
                            nodes {
                                boardByBoardId {
                                    id
                                    title
                                    slug
                                    description
                                }
                            }
                        }
                        popularLists(first: 32, userid: $userId) {
                            nodes {
                                id
                                name
                                slug
                                assertionRate
                                totalQuestions
                                answersByUser {
                                    correct
                                    wrong
                                }
                                materialsByListId(filter: { boardId: { isNull: false } }) {
                                    nodes {
                                        boardByBoardId {
                                            id
                                            title
                                            slug
                                        }
                                    }
                                }
                            }
                        }
                        stoppedLists(first: 32, userid: $userId) {
                            nodes {
                                id
                                name
                                slug
                                assertionRate
                                totalQuestions
                                answersByUser {
                                    correct
                                    wrong
                                }
                                materialsByListId(filter: { boardId: { isNull: false } }) {
                                    nodes {
                                        boardByBoardId {
                                            id
                                            title
                                            slug
                                        }
                                    }
                                }
                            }
                        }
                    }
                }
            }`, {
                classroomSlug: classroomSlug,
                userId
            });

            if (response.data.allClassrooms.nodes.length > 0) {
                commit("SET_CURRENT_CLASSROOM_WITH_LISTS", response.data.allClassrooms.nodes[0]);
            } else {
                commit(SET_CURRENT_CLASSROOM, { error: true })
                return false;
            }

        },
        async [PULL_POPULAR_LISTS]({ commit, state, rootState }: any) {
            let userId = rootState.auth.user ? rootState.auth.user.id : null
            let classroomId = state.currentClassroom.id;
            let response = await query(`query popularListsByClassroom($userId: Int, $classroomId: Int!) {
                classroomById(id: $classroomId){
                    id
                    popularLists(userid: $userId, first: 32){
                        nodes{
                            id 
                            name
                            slug
                            description
                            assertionRate
                            totalQuestions
                            answersByUser {
                                correct
                                wrong
                            }
                            listQuestionsByListId: listItemsByListId (
                                condition: {
                                    articleId: null
                                }
                                orderBy: POSITION_ASC
                            ) {
                                totalCount
                            }
                            materialsByListId (filter: {
                                boardId: {
                                    isNull: false
                                }
                            }){
                                nodes {
                                    boardByBoardId {
                                        id
                                        title
                                        slug
                                    }
                                }
                            }
                        }
                    }
                }
            }`, {
                userId: userId,
                classroomId: classroomId
            })
            commit(SET_POPULAR_LISTS, response.data.classroomById.popularLists.nodes);
        },
    }

};
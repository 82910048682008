import { TrackerEngine } from './interfaces';

/* 
    It's responsible to provide abstract tracking 
    interface.
*/

export default class Tracker {
    engine: TrackerEngine
    constructor(engine) {
        this.engine = engine;
    }

    public userIdentityWith(eventData) {
        if (this.engine) {
            this.engine.userIdentity(eventData);
        }
    }

    public trackWith(eventName, eventData) {
        if (this.engine) {
            this.engine.track(eventName, eventData);
        }
    }
}
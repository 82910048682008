


































































































import Vue from 'vue'
import TWEEN from '@tweenjs/tween.js';
export default Vue.extend({
    data() {
        return {
            width: (window.innerWidth * 0.575),
            progressPercentage: 90,
        }
    },
    watch: {
        activeStreak(nextStreak) {
            this.tween(90, this.progressBarPercentage(nextStreak));
        }
    },
    created() {
        this.tween(90, this.progressBarPercentage(this.activeStreak));
    },
    methods: {
        tween(start, end) {
            // TODO: Tween knows too much about percentage transition, it is not reusable.
            let frameHandler

            const animate = function(currentTime) {
                TWEEN.update(currentTime)
                frameHandler = requestAnimationFrame(animate);
            }

            const myTween = new TWEEN.Tween({ progressPercentage: start }).to({ progressPercentage: end }, 850)
                .onUpdate((object) =>  {
                    this.progressPercentage = object.progressPercentage;
                })
                .onComplete(() => {
                    cancelAnimationFrame(frameHandler);
                })
                .delay(100)
                .easing(TWEEN.Easing.Quadratic.Out)
                .start()
                frameHandler = requestAnimationFrame(animate);
        },
        progressBarPercentage(streak) {
            // TODO: knows the message and the fact that exists a streak, it has first degree dependency.
            let completedLists = streak.completedLists, 
            achievedMilestones = streak.achievedMilestones;
            if (!completedLists || completedLists <= 0) {
                return 95;
            } else if (completedLists == 1) {
                return 90;
            }
            if (streak.streakBreakpoints.includes(completedLists)) {
                return (100 - ( ((achievedMilestones - 1) * 20) - 5 ));
            } else {
                let proportionalDifference = ( ((streak.completedLists - streak.lastAchievedMilestone) * 20 ) / ((streak.nextMilestone) - streak.lastAchievedMilestone) )
                let result = (100 - (proportionalDifference + ( (achievedMilestones - 1) * 20 )));
                return result;
            }
        }
    },
    props: ["activeStreak"]
})

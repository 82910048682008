export const TRACK_TYPES = {
    TRACK: "TRACK",
    LIST_STARTED: "List Started",
    LINK_NAVIGATED: "Link Navigated",
    LIST_CONSUMED: "List Consumed",
    LIST_CONTINUED: "List Continued",
    SCREEN_VIEW: "Screen Viewed",
    SOLUTION_INTERACTED: "Solution Interacted",
    REPORT_SENT: "Report Sent",
    NAVIGABILITY_ELEMENT_USED: "Navigability Element Used",
    EXAM_CONSUMED: "Exam Consumed",
    EXAM_EXIT_INTENTION: "Exam Exit Intention",
    EXERCISE_ANSWERED: "Exercise Answered",
    RANKING_INTERACTED: "Ranking Interacted",
    EXAM_STARTED: "Exam Started",
    ESSAY_STARTED: "Essay Started",
    ESSAY_CONTINUED: "Essay Continued",
    ESSAY_CONSUMED: "Essay Consumed",
    ESSAY_SEARCHED: "Essay Searched",
    CORRECTION_CONSUMED: "Correction Consumed",
    CORRECTION_INTERACTED: "Correction Interacted",
    INTEGRATION_EVENT: "Integration Event",
    INTEGRATION_VIEWED: "Integration Viewed",
    INTEGRATION_NAVIGATE: "Integration Navigate"
};

export const injectPayloadMetadata = (payload, increment) => {
    const mergedInformations = {
      ...payload,
      ...increment
    };
    return mergedInformations;
  };

export default (dispatch) => (actionName, payload) => {
    dispatch(TRACK_TYPES.TRACK, { actionName, payload });
};
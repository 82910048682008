import Model from "./Model";

export default class VideoModel extends Model {
  id: number;
  name: string;
  duration: number;
  description: string;
  providerId: string;
  providerType: string;
  static __typename: string = "Video";

  constructor(video: any, getters: any) {
    super(getters);
    this.id = video.id;
    this.name = video.name;
    this.duration = video.duration;
    this.description = video.description;
    this.providerId = video.providerId;
    this.providerType = video.providerType;
  }

  contents() {
    return {
      id: this.id,
      name: this.name,
      duration: this.duration,
      description: this.description,
      providerId: this.providerId,
      providerType: this.providerType,
      kalturaId: this.providerType === 'KALTURA' ? this.providerId : null,
      behavior: this.providerType === 'KALTURA' ? 'kalturaVideo' : 'video'
    }
  }

  raw() {
    return {
      id: this.id,
      name: this.name,
      duration: this.duration,
      description: this.description,
      providerId: this.providerId,
      providerType: this.providerType,
      __typename: VideoModel.__typename
    };
  }
}



































import Vue from "vue";
import pxButton from "@/dex-shared/components/px-button.vue";
export default Vue.extend({
  components: {
    pxButton
  }
});

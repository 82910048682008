


























































import Vue from 'vue';
import solutionsStorage from '../../helpers/solutionsStorage';

export default Vue.extend({
  props: ['hasSolutions'],

  methods: {
    getMessageAndImage() {
      return {
        message: 'Mostre aos seus colegas como chegou a essa conclusão',
        imgSrc: '../../assets/img/solution/send-solution.svg',
      }
    }
  }
});

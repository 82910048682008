import { TRACK_TYPES, injectPayloadMetadata } from '@/dex-shared/helpers/track';
import TrackingEvents from '@/dex-shared/models/Tracking/TrackingEvents';

export default store => {
  const TRACK = "TRACK";

  store.subscribeAction((action, state) => {
    const { type, payload } = action;

    if(type == TRACK) {
      switch(payload.actionName) {
        case TRACK_TYPES.SCREEN_VIEW:
          return new TrackingEvents(state).track(
            injectPayloadMetadata(payload, { eventName: TRACK_TYPES.SCREEN_VIEW })
          );
        default:
            return;
      }
    }
  });
};
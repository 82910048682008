import { query, mutation } from "@/dex-shared/helpers/graphql";

const PUSH_VOTE = 'PUSH_VOTE';

export default {
  state: {},
  getters: {},
  mutations: {},

  actions: {
    async [PUSH_VOTE]({ commit }: any, vote: any) {
      const voteResponse = await query(
        `
          query PullVotes($userId: Int!, $solutionId: Int!) {
            allVotes(filter: {
              userId: {
                equalTo: $userId
              },
              solutionId: {
                equalTo: $solutionId
              }
            }) {
              nodes {
                id
                solutionId
                userId
                positive
              }
            }
          }
        `,
        {
          userId: vote.userId,
          solutionId: vote.solutionId
        }
      );

      const votes = voteResponse.data.allVotes.nodes;
      
      if (!votes.length) {
        await mutation(
          `
            mutation CreateVote($userId: Int!, $solutionId: Int!, $positive: Boolean!) {
              createVote(
                input: {
                  vote: {
                    userId: $userId
                    solutionId: $solutionId
                    positive: $positive
                  }
                }
              ) {
                vote {
                  id
                }
              }
            }
          `,
          {
            userId: vote.userId,
            solutionId: vote.solutionId,
            positive: vote.positive
          }
        );
      } else if (votes[0].positive !== vote.positive) {
        await mutation(
          `
            mutation UpdateVote($id: Int!, $positive: Boolean!) {
              updateVoteById(
                input: {
                  id: $id
                  votePatch: {
                    positive: $positive
                  }
                }
              ) {
                vote {
                  id
                }
              }
            }
          `,
          {
            id: votes[0].id,
            positive: vote.positive
          }
        );
      } else {
        await mutation(
          `
            mutation DeleteVote($id: Int!) {
              deleteVoteById(
                input: {
                  id: $id
                }
              ) {
                vote {
                  id
                }
              }
            }
          `,
          {
            id: votes[0].id,
          }
        );
      }
    },
  }
};

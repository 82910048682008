import { render, staticRenderFns } from "./ListQuestion.vue?vue&type=template&id=4cabbd43&scoped=true&"
import script from "./ListQuestion.vue?vue&type=script&lang=ts&"
export * from "./ListQuestion.vue?vue&type=script&lang=ts&"
import style0 from "./ListQuestion.vue?vue&type=style&index=0&id=4cabbd43&lang=less&scoped=true&"


/* normalize component */
import normalizer from "!../../../node_modules/vue-loader/lib/runtime/componentNormalizer.js"
var component = normalizer(
  script,
  render,
  staticRenderFns,
  false,
  null,
  "4cabbd43",
  null
  
)

export default component.exports
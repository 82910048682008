








































































import moment from 'moment';
import Vue from 'vue';
import { Carousel, Slide } from 'vue-carousel';
export default Vue.extend({
    components: {
        Carousel,
        Slide
    },
    data() {
        return {
            moment,
            activePage: 0,
        }
    },
    created() {
        this.activePage = this.streaks.length - 3;
    },
    props: ['activeDate', 'changeDate', 'streaks'],
    methods: {
        isActive(date) {
            return date.isSame(this.activeDate, 'day')
                && date.isSame(this.activeDate, 'month')
                && date.isSame(this.activeDate, 'year');
        },
        dateByName(date) {
            if (moment(date).isSame(moment(), 'day') && moment(date).isSame(moment(), 'month') && moment(date).isSame(moment(), 'year')) {
                return "HOJE";
            } else if ( date.isSame(moment().subtract(1, 'days'), 'day') && date.isSame(moment().subtract(1, 'days'), 'month') && date.isSame(moment().subtract(1, 'days'), 'year') ) {
                return "ONTEM";
            } else {
                return moment(date).format('ddd').toUpperCase();
            }
        },
        moveSliderToLeft() {
            if (this.activePage > 0) {
                this.activePage -= 1;
            }
        },
        moveSliderToRight() {
            if (this.activePage < this.streaks.length - 3) {
                this.activePage += 1;
            }
        },
        isMovingToRight(date) {
            return moment(this.activeDate).isBefore(moment(date));
        },
        isMovingToLeft(date) {
            return moment(this.activeDate).isAfter(moment(date));
        },
        onDateClick(date) {
            if (this.isMovingToLeft(date)) {
                this.moveSliderToLeft();
            } else {
                this.moveSliderToRight();
            }
            this.changeDate(date);
        }
    }
})

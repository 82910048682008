var render = function () {var _vm=this;var _h=_vm.$createElement;var _c=_vm._self._c||_h;return _c('div',{ref:"bar",staticClass:"container",style:({ width: _vm.width, margin: _vm.margin })},[_c('div',{staticClass:"background",style:({
      width: '100%',
      background: _vm.backgroundColor,
      height: _vm.barHeight + 'px',
      borderRadius: _vm.barHeight + 'px'
    })}),_c('div',{staticClass:"progress",style:({
      width: _vm.progressWidth,
      background: _vm.progressColor,
      transitionDuration: _vm.transitionDuration,
      transitionDelay: _vm.transitionDelay,
      transitionTimingFunction: _vm.transitionTimingFunction,
      height: _vm.barHeight + 'px',
      borderRadius: _vm.barHeight + 'px'
    })})])}
var staticRenderFns = []

export { render, staticRenderFns }

























































































































































































































































import Vue from 'vue';
import Streak from "@/models/Streak";
import DateProvider from "@/models/DateProvider";
import DatePicker from "@/components/streak/DatePicker.vue";
import StreakProgress from "@/components/streak/StreakProgress.vue";
import pxButton from '@/dex-shared/components/px-button.vue';
import { Carousel, Slide } from 'vue-carousel';
import ArrowLeft from "vue-material-design-icons/ChevronLeft.vue";
import ArrowRight from "vue-material-design-icons/ChevronRight.vue";
import { TweenMax } from 'gsap';
import { cdn } from "@/dex-shared/lib/cdn";
import TWEEN from '@tweenjs/tween.js';
import track, { TRACK_TYPES } from '../../dex-shared/helpers/track';

export default Vue.extend({
    components: {
        DatePicker,
        StreakProgress,
        Carousel,
        Slide,
        ArrowLeft,
        ArrowRight,
        pxButton
    },
    props: {
        ctaCaller: {
            type: Function,
            required: true
        },
        loadingCallerData: {
            type: Boolean,
            required: true
        }
    },
    data() {
        return {
            activeDate: new DateProvider(),
            activePage: 0,
            activeStreak: null
        }
    },
    computed: {
        streaks() {
            return [ ...this.$store.getters['streakCollection'] ].reverse();
        }
    },
    async created() {
        await this.loadStreaks();
        this.setInitialConfigurations();
    },
    methods: {
        setInitialConfigurations() {
            this.activePage = this.streaks.length - 3;
            this.activeStreak = this.streaks[this.streaks.length - 1];
        },
        loadStreaks() {
            return this.$store.dispatch('PULL_STREAK');
        },
        changeDate(dateProvider) {
            this.activeDate = dateProvider;
        },
        isActive(dateProvider) {
            return dateProvider.isSameDateAs(this.activeDate.date);
        },
        dateByName(dateProvider) {
            if (dateProvider.isSameDateAs(new DateProvider().date)) {
                return "HOJE";
            } else if ( dateProvider.isSameDateAs( new DateProvider().subtractDateWith({amount: 1, comparison: 'days'}))) {
                return "ONTEM";
            } else {
                return dateProvider.formattedDateWithThreeLetters.toUpperCase();
            }
        },
        moveSliderToLeft() {
            if (this.activePage > 0) {
                this.activePage -= 1;
            }
        },
        moveSliderToRight() {
            if (this.activePage < this.streaks.length - 3) {
                this.activePage += 1;
            }
        },
        isMovingToRight(dateProvider) {
            return this.activeDate.isBefore(dateProvider.date);
        },
        isMovingToLeft(dateProvider) {
            return this.activeDate.isAfter(dateProvider.date);
        },
        changeStreakTo(streak) {
            this.activeStreak = streak;
        },
        onDateClick(dateProvider, streak) {
            this.$track(TRACK_TYPES.NAVIGABILITY_ELEMENT_USED, {
                type: "Streak",
                label: this.dateByName(dateProvider)
            });
            if (this.isMovingToLeft(dateProvider)) {
                this.moveSliderToLeft();
            } else {
                this.moveSliderToRight();
            }
            this.changeStreakTo(streak);
            this.changeDate(dateProvider);
        },
        isOnFire(streak) {
            let streakIndex = this.streaks.indexOf(streak);
            let lastStreak = this.streaks[streakIndex + 1];
            let nextStreak = this.streaks[streakIndex - 1];
            if (!streak.hasNoListDone) {
                if (streak.dateProvider.isToday) {
                    return true;
                } else if (lastStreak && !lastStreak.hasNoListDone) {
                    return true;
                } else if (nextStreak && !nextStreak.hasNoListDone) {
                    return true;
                }
            } else {
                return false;
            }
        },
        shouldRemainingListsAppear({ streak }) {
            return !streak.completed && streak.dateProvider.isToday && !streak.hasNoListDone;
        }
    }
})




































































































































































































































































import Vue from 'vue';
import { mapGetters, mapState, mapActions } from 'vuex';
import Breadcrumb from '../components/Breadcrumb.vue';
import QuestionMap from '../components/question/QuestionMap.vue';
import ListQuestion from '../components/question/ListQuestion.vue';
import ListSolutions from '../components/question/ListSolutions.vue';
import ListArticle from '../components/article/ListArticle.vue';
import Investigation from '@/dex-shared/components/investigation/Investigation.vue';
import StreakProgressMobile from "@/components/streak/StreakProgressMobile.vue";
import Results from '../components/Results.vue';
import pxButton from '@/dex-shared/components/px-button.vue';
import Loading from '@/dex-shared/components/Loading.vue';
import VideoShow from '@/dex-shared/components/new_content/show/VideoShow.vue';
import DescomplicaLogo from '@/dex-shared/components/icons/DescomplicaLogo.vue';
import DescomplicaShortLogo from '@/dex-shared/components/icons/DescomplicaShortLogo.vue';
import SendSolutionBanner from '../components/solution/SendSolutionBanner.vue';
import SendSolutionThanksBanner from '../components/solution/SendSolutionThanksBanner.vue';
import SendSolution from '../components/solution/SendSolution.vue';
import keyPressTutorial from '../helpers/keyPressTutorial';
import ListCarousel from '../components/list/ListCarousel.vue';
import ArrowTopRight from 'vue-material-design-icons/ArrowTopRight.vue';
import ArrowBottomLeft from 'vue-material-design-icons/ArrowBottomLeft.vue';
import StreakProgress from "@/components/streak/StreakProgress.vue";
import TWEEN from '@tweenjs/tween.js';
import { elementIsVisible } from '@/dex-shared/lib/position';
import track, { TRACK_TYPES } from '@/dex-shared/helpers/track';
import VideoModel from '../dex-shared/models/VideoModel';

export default Vue.extend({
	components: {
    VideoShow,
		Breadcrumb,
		QuestionMap,
		ListQuestion,
		ListSolutions,
		ListArticle,
		Results,
		pxButton,
		Loading,
		ListCarousel,
		ArrowTopRight,
		ArrowBottomLeft,
    Investigation,
    DescomplicaLogo,
		DescomplicaShortLogo,
		SendSolutionBanner,
		SendSolutionThanksBanner,
		SendSolution,
    StreakProgressMobile,
    StreakProgress
	},

	data: function() {
		return {
			loaded: false,
      lastKey: null,
			routePath: "",
			keyPressTutorial: keyPressTutorial,
			seenTutorialOnCurrentRoute: keyPressTutorial.completed,
			userAlreadyFinishedThisList: false,
			showInvestigations: false,
			doubtAboutInvestigations: false,
      streakHeight: -300,
      showStreak: true,
      streakAlreadyDisplayed: false,
      isIFrame: false,
      showSolutionButton: true,
      solutionButtonVisible: false,
      enterAnimationTimeout: null,
      exitAnimationTimeout: null,
		};
	},
	computed: {
		...mapGetters({
			currentListData: 'currentListData',
			listItems: 'listItems',
			listQuestions: 'listQuestions',
			listArticles: 'listArticles',
			listVideos: 'listVideos',
			sessionQuestionReply: 'sessionQuestionReply',
			sessionQuestionSolution: 'sessionQuestionSolution',
			classroom: 'currentClassroom',
			investigationsAsQuestions: 'INVESTIGATIONS_AS_QUESTIONS',
			listAnswers: 'listAnswers',
      userId: 'userId',
      popularLists: 'popularLists',
      todayStreak: 'todayStreak',
      hasRecommendedListToCurrentList: 'hasRecommendedListToCurrentList',
      nextRecommendedListToCurrentList: 'nextRecommendedListToCurrentList',
      hasRecommendedListToCurrentUser: 'hasRecommendedListToCurrentUser',
      nextRecommendedListToCurrentUser: 'nextRecommendedListToCurrentUser',
		}),
		...mapState({
			user: (state:any) => state.auth.user,
			sessionReplies: (state:any) => state.lists.sessionReplies,
			answeredOnCurrentSession: (state:any) => state.lists.answeredOnCurrentSession,
			classroomSlug: (state:any) => state.classrooms.currentClassroom.slug,
			board: (state: any) => state.boards.board,
			list: (state: any) => state.lists.current,
      modals: (state: any) => state.modals.modals,
    }),
    listWasStarted(): any {
       return (this.listAnswers.correct.length + this.listAnswers.wrong.length) > 0;
    },
		currentItem(): any {
      return this.listItems[this.index];
		},
		breadcrumbItems(): any {
			return [
				{
					name: this.$store.getters.currentListData.name,
					link: this.routePath + this.$store.getters.currentListData.slug
				}
			];
		},
		index: function() {
      const { questionIndex, articleIndex, videoIndex } = this.$route.params;

      let index, content;

      if (questionIndex) {
        content = this.listQuestions;
        index = parseInt(questionIndex, 10);
      } else if (articleIndex) {
        content = this.listArticles;
        index = parseInt(articleIndex, 10);
      } else if (videoIndex) {
        content = this.listVideos;
        index = parseInt(videoIndex, 10);
      } else return;

      return this.listItems.indexOf(
        content[index - 1]
      );
		},
    showResult: function() {
      const resultRoutes = ['list_result', 'deprecated_list_result', 'list_classroom_result', 'list_board_result'];
      if (resultRoutes.includes(this.$route.name)) {
        return true;
      } else {
				return false;
			}
		},
		homeLink: function():string {
			if (this.classroomSlug) {
				return `/${this.classroomSlug}`
			} else {
				return '/'
			}
		},
    hasSolutions() {
      return this.currentItem && this.sessionQuestionSolution(this.currentItem.id);
    },
		hasUserSolution() {
			const { id } = this.currentItem;
			const solutions = this.sessionQuestionSolution(id);

			return solutions && solutions.userHasSentSolution;
		},
		shouldShowSendSolution() {
			const { id } = this.currentItem;
			const currentReply = this.sessionQuestionReply(id);

			return currentReply && currentReply.answer == currentReply.correctAnswer && !this.hasUserSolution;
		},
	},
	watch: {
		$route(to, from) {
      this.hideSolutionButton();

			if (`${to.params.board}/${to.params.list}` != `${from.params.board}/${from.params.list}`) {
				this.pullInformation(to);
			}

			this.seenTutorialOnCurrentRoute = this.keyPressTutorial.completed;
		},
		async listAnswers(answers) {
      if (
        this.userFinishedList({  amountOfQuestions: this.listQuestions.length, amountOfQuestionsAnswered: (answers.correct.length + answers.wrong.length) })  && !this.userAlreadyFinishedThisList && this.loaded && !this.streakAlreadyDisplayed
      ) {
        const LIST_CONSUMED_PROPERTIES = {
          list: this.list,
          board: this.board,
          assertivenessRate: (answers.correct.length / this.listQuestions.length)
        }

        this.$track(TRACK_TYPES.LIST_CONSUMED, LIST_CONSUMED_PROPERTIES);
        this.$store.dispatch('ADD_COMPLETED_LIST');
        await this.$store.dispatch('PULL_STREAK');
        Vue.nextTick(() => {
          this.triggerStreak();
          this.streakAlreadyDisplayed = true;
        });
      } else {
        this.closeStreak();
      }
      if (
      this.suitableForInvestigations({
        amountOfQuestions: this.listQuestions.length,
        amountOfQuestionsAnswered: (answers.correct.length + answers.wrong.length),
        userFinishedList: this.userAlreadyFinishedThisList
      })
      ) {
        this.pullInvestigations();
      } else {

      }
    },
    modals() {
      if (this.modals.length) {
        this.removeEventListener();
      } else {
        this.addEventListener();
      }
    },
	},
	async created() {
    if ( window.location !== window.parent.location ){
      this.isIFrame = true;
    }
    if(this.$route.params.classroom){
      this.routePath = `/${this.$route.params.classroom}/${this.$route.params.board}/`;
    }
    if (this.user && this.user.id) {
      await this.loadCurrentStreak();
    }

    this.addEventListener();
    window.addEventListener('scroll', this.scrollListener);

    await this.pullInformation(this.$route);

	},

	destroyed() {
		this.removeEventListener();
    window.removeEventListener('scroll', this.scrollListener);
  },
	methods: {
    isMobile() {
      if(/Android|webOS|iPhone|iPad|iPod|BlackBerry|IEMobile|Opera Mini/i.test(navigator.userAgent)) {
        return true
      } else {
        return false
      }
    },
		goHome() {
      this.$track(TRACK_TYPES.LINK_NAVIGATED, {
        list: this.list,
        context: "OUT",
        label: "Home"
      });
      if(this.isIFrame) {
        const routeData = this.$router.resolve({path: this.homeLink})
        window.open(routeData.href, '_blank');
      } else {
        this.$router.push(this.homeLink);
      }
    },
		fixDeprecatedRoute() {
			if(this.$route.name.includes("deprecated")) {
				if (!this.list.classroom){
          this.routePath = `/lista/`;
					return true;
				}
				this.routePath = `/${this.list.classroom.slug}/${this.list.board.slug}/`
				let route = this.routePath + this.$route.params.list;

				if(this.$route.name.includes("result")) {
					route += '/resultado';
				} else if (this.$route.params.questionIndex) {
					route += `/questao/${this.$route.params.questionIndex}`;
				} else if (this.$route.params.articleIndex) {
					route += `/explicacao/${this.$route.params.articleIndex}`;
				} else if (this.$route.params.itemIndex) {
					route += `/${this.$route.params.itemIndex}`;
				}

				this.$router.replace(route);
				return true;
			}
		},

		...mapActions({
			pushReply: 'PUSH_REPLY',
			pullPopularLists: 'PULL_POPULAR_LISTS',
			updateQuestionAnsweredOnSession: "UPDATE_QUESTION_ANSWERED_ON_CURRENT_SESSION",
			pushInvestigationResponse: "PUSH_INVESTIGATION_RESPONSE",
			pushInvestigationDenial: "PUSH_INVESTIGATION_DENIAL"
		}),

		async answerQuestion(answer:any) {
			await this.pushReply({
				listData: this.$store.getters.currentListData,
				questionId: this.currentItem.id,
				answer,
        isEnemQuestion: this.$route.params.classroom === 'enem'
      });

      this.$nextTick(() => {
        if (this.solutionElementsNotVisible()) {
          this.goToSolutionButtonVisible();
        }
      });
		},

		cancelInvestigations() {
			this.pushInvestigationDenial({ userId: this.user.id, investigationId: this.investigationsAsQuestions[0].id });
			this.dismissInvestigations();
		},
		switchDoubtAboutInvestigations() {
			this.doubtAboutInvestigations = !this.doubtAboutInvestigations;
		},
		async pushInvestigationAnswer(answer: any) {
			await this.pushInvestigationResponse({
          hypothesisId: this.investigationsAsQuestions[0].interactiveData.alternatives[answer][0].id,
          investigationId: this.investigationsAsQuestions[0].id,
          userId: this.user.id
      })
      setTimeout(() => this.dismissInvestigations() , 1250)
    },
    removeEventListener() {
      window.removeEventListener('keydown', this.keyListener, false);
    },
    addEventListener() {
      window.addEventListener('keydown', this.keyListener, false);
    },
    triggerInvestigations() {
      this.showInvestigations = true;
    },
    dismissInvestigations() {
      this.showInvestigations = false;
    },
    async pullInvestigations() {
      if (this.user) {
          if (this.investigationsAsQuestions) {
            this.triggerInvestigations()
          }
      }
    },
    suitableForInvestigations({ amountOfQuestions, amountOfQuestionsAnswered, userFinishedList } :any) {
      return this.userFinishedList({amountOfQuestions, amountOfQuestionsAnswered})
        && !userFinishedList
        && (this.investigationsAsQuestions.length > 0)
        && amountOfQuestions <= 10
    },
    userFinishedList({amountOfQuestions, amountOfQuestionsAnswered}:any) {
      return amountOfQuestionsAnswered === amountOfQuestions;
    },
    getRouteForFirstQuestionWithoutReplies(completeListItems:any) {
      const firstQuestionWithoutReplies = completeListItems.find(
        (listItem) => {
          let question = listItem.questionByQuestionId;
          if (question) {
            let replyCount = question.repliesByQuestionId.nodes.length;
            return replyCount === 0;
          } return false;
        }
      );
      if (firstQuestionWithoutReplies) {
        const questionIndex = this.listQuestions.findIndex(
          (listQuestion) => listQuestion.id == firstQuestionWithoutReplies.questionByQuestionId.id
        );
        return `/questao/${questionIndex + 1}`;
      } else {
        return '/questao/1';
      }
    },
    getRouteForLoggedUser(completeListItems) {
      const userHasStartedList = completeListItems.some(
        (listItem) => {
          let question = listItem.questionByQuestionId;
          if (question) {
            let replyCount = question.repliesByQuestionId.nodes.length;
            return replyCount > 0;
          } return false;
        }
      );
      if (userHasStartedList) {
        return this.getRouteForFirstQuestionWithoutReplies(completeListItems);
      } else {
        return this.getRouteForFirstItem(completeListItems);
      }
    },
    getRouteForFirstItem(completeListItems:any) {
      const firstItem = completeListItems[0];
      if (firstItem.questionByQuestionId) {
        return '/questao/1';
      } else if (firstItem.articleByArticleId) {
        return '/explicacao/1';
      } else if (firstItem.videoByVideoId) {
        return '/video/1';
      }
    },
    fixRoute(route:any) {
      let newRoute = this.routePath + this.currentListData.slug
      let replaceRoute = false;

      const invalidRoute = (route.params.itemIndex > this.listItems.length) ||
          (route.params.articleIndex > this.listArticles.length) ||
          (route.params.questionIndex > this.listQuestions.length) ||
          (route.params.itemIndex == 0) || (route.params.questionIndex == 0) ||
          (route.params.articleIndex == 0);

      const unspecifiedRoute = (!route.params.itemIndex && !this.showResult &&
           !route.params.questionIndex && !route.params.articleIndex && !route.params.videoIndex);

      if (invalidRoute || unspecifiedRoute) {
        replaceRoute = true;
        const completeListItems = this.$store.state.lists.current.listItemsByListId.nodes;
        if (this.user) {
          newRoute += this.getRouteForLoggedUser(completeListItems);
        } else {
          newRoute += this.getRouteForFirstItem(completeListItems);
        }
      } else if (route.params.itemIndex) {
        replaceRoute = true;
        let item = this.listItems[route.params.itemIndex-1]
        if (item.type === "Question") {
          newRoute += '/questao/' + (this.listQuestions.indexOf(item) + 1)
        } else if (item.type === "Article") {
          newRoute += '/explicacao/' + (this.listArticles.indexOf(item) + 1)
        } else if (item.type === "Video") {
          newRoute += '/video/' + (this.listQuestions.indexOf(item) + 1)
        }
      } else if(!isNaN(route.params.list)) {
        if (this.showResult) {
          newRoute += '/resultado';
          replaceRoute = true;
        }
      }
      if (replaceRoute) {
        this.$router.replace(newRoute)
      }
    },
    loadCurrentStreak() {
      return this.$store.dispatch('PULL_STREAK');
    },
    triggerStreak() {
      this.showStreak = true;
      setTimeout(() => {
        this.closeStreak();
      }, 4000);
    },
    closeStreak() {
      this.showStreak = false;
    },
    async pullInformation(route:any) {
      this.loaded = false;

      if (route.params.board) {
        await this.$store.commit("SET_CURRENT_BOARD_BY_SLUG", route.params.board);
        await this.$store.dispatch("PULL_BOARD_WITH_BOARD_SECTIONS_BY_BOARD_WITH_LISTS", { boardId: route.params.board, batchForEachSection: 12 });
      }

      if (route.params.list) {
        let response = await this.$store.dispatch("PULL_LIST", route.params.list);
        if(response === false)
          this.$router.push('/404');
        // Deprecated route
        const validRoute = this.fixDeprecatedRoute();
        if(validRoute === false){
          return;
        }
        if ((this.listAnswers.correct.length + this.listAnswers.wrong.length) == 0) {
          this.$track(TRACK_TYPES.LIST_STARTED, this.currentListData);
        } else {
          this.$track(TRACK_TYPES.LIST_CONTINUED, {
            ...this.currentListData,
            answeredQuestions: (this.listAnswers.correct.length + this.listAnswers.wrong.length)
          });
        }

        if (
            this.userFinishedList({
              amountOfQuestions:  this.listQuestions.length,
              amountOfQuestionsAnswered: (this.listAnswers.correct.length + this.listAnswers.wrong.length)
            })
        ) {
          this.userAlreadyFinishedThisList = true;
          this.dismissInvestigations();
        } else {
          this.userAlreadyFinishedThisList = false;
          if (this.user && this.user.id) {
            this.$store.dispatch('PULL_INVESTIGATIONS', {  userId: this.user.id });
          }
        }
      }
      document.title = `Exercícios - ${this.currentListData.name}`;
      let description = document.getElementsByName('description')[0] as HTMLMetaElement;

      if (this.currentListData.description) {
        description.content = this.currentListData.description;
      } else {
        if (this.listItems[0].type !== "Video") {
          description.content = this.listItems[0].content[0].content;
        }
      }

      this.fixRoute(route);
      this.loaded = true;
    },
    nextItem() {
      this.$track(TRACK_TYPES.NAVIGABILITY_ELEMENT_USED, {
        type: "Next And Stop Buttons",
        listId: this.$store.getters["currentListData"].id,
      });
      let newRoute = this.routePath+this.$route.params.list+'/';

      if (!isNaN(this.index)) {
        if (this.index + 1 === this.listItems.length) {
          let firstItem = this.listItems[0]
          if (firstItem.type === "Question") {
            newRoute += 'questao/' + 1
          } else if (firstItem.type === "Article") {
            newRoute += 'explicacao/' + 1
          } else if (firstItem.type === "Video") {
            newRoute += 'video/' + 1
          } else {
            newRoute += 1
          }
        } else {
          let nextItem = this.listItems[this.index+1]
          if (nextItem.type === "Question") {
            newRoute += 'questao/' + (this.listQuestions.indexOf(nextItem)+1)
          } else if (nextItem.type === "Article") {
            newRoute += 'explicacao/' + (this.listArticles.indexOf(nextItem)+1)
          } else if (nextItem.type === "Video") {
            newRoute += 'video/' + (this.listVideos.indexOf(nextItem)+1)
          } else {
            newRoute += this.index+2;
          }
        }
      }
      if (newRoute[0] !== '/') newRoute = `/lista/${newRoute}`;
      this.$router.push(newRoute)
    },
    previousItem() {
      this.$track(TRACK_TYPES.NAVIGABILITY_ELEMENT_USED, {
        type: "Next And Stop Buttons",
        listId: this.$store.getters["currentListData"].id,
      });
      let newRoute = this.routePath+this.$route.params.list+'/';
      if (!isNaN(this.index)) {
        if (this.index == 0) {
          let lastItem = this.listItems[this.listItems.length - 1];
          if (lastItem.type === "Question") {
            newRoute += 'questao/' + this.listQuestions.length
          } else if (lastItem.type === "Article") {
            newRoute += 'explicacao/' + this.listQuestions.length
          } else if (lastItem.type === "Video") {
            newRoute += 'video/' + this.listVideos.length
          } else {
            newRoute += this.listItems.length;
          }
        } else {
          let previousItem = this.listItems[this.index-1];
          if (previousItem.type === "Question") {
            newRoute += 'questao/' + (this.listQuestions.indexOf(previousItem)+1)
          } else if (previousItem.type === "Article") {
            newRoute += 'explicacao/' + (this.listArticles.indexOf(previousItem)+1)
          } else if (previousItem.type === "Video") {
            newRoute += 'video/' + (this.listVideos.indexOf(previousItem)+1)
          } else {
            newRoute += this.index;
          }
        }
      }
      if (newRoute[0] !== '/') newRoute = `/lista/${newRoute}`;
      this.$router.push(newRoute);
    },
    keyListener(event: any) {
      event = event || window.event;
      var key = event.keyCode;
      if (event.metaKey || event.ctrlKey) {
        key = null;
      }
      // character keys
      if (key >= 65 && key <= 90) {
        let alternative = key - 65;
        if (
          this.currentItem.interactiveData.alternatives[alternative]
        ) {
          this.$track(TRACK_TYPES.NAVIGABILITY_ELEMENT_USED, {
            type: "Keyboard",
            listId: this.$store.getters["currentListData"].id,
          });
          this.keyPressTutorial.set('answer', true);
          this.seenTutorialOnCurrentRoute = true;
          this.answerQuestion(alternative);
        }
      }
      // number keys
      if (key >= 48 && key <= 57) {
        this.$track(TRACK_TYPES.NAVIGABILITY_ELEMENT_USED, {
          type: "Keyboard",
          listId: this.$store.getters["currentListData"].id,
        });
        if (this.lastKey) {
          this.keyPressTutorial.set('numberNavigation', true)
          this.seenTutorialOnCurrentRoute = true;
          this.$router.push(`${this.routePath}${this.$route.params.list}/questao/${this.lastKey}${event.key}`);
        } else {
          this.keyPressTutorial.set('numberNavigation', true)
          this.seenTutorialOnCurrentRoute = true;
          this.$router.push(`${this.routePath}${this.$route.params.list}/questao/${event.key}`);
        }
        this.lastKey = event.key;
        setTimeout(this.clearLastKey, 500, event.key);
      }
      // arrow keys
      if (key === 37) {
        this.previousItem()
        this.$track(TRACK_TYPES.NAVIGABILITY_ELEMENT_USED, {
          type: "Keyboard",
          listId: this.$store.getters["currentListData"].id,
        });
      }
      else if (key === 39) {
        this.$track(TRACK_TYPES.NAVIGABILITY_ELEMENT_USED, {
          type: "Keyboard",
          listId: this.$store.getters["currentListData"].id,
        });
        this.nextItem()
      }
      else if (key === 38) {
        this.$track(TRACK_TYPES.NAVIGABILITY_ELEMENT_USED, {
          type: "Keyboard",
          listId: this.$store.getters["currentListData"].id,
        });
        window.scrollTo(window.scrollX, window.scrollY - 41);
      } else if (key === 40) {
        this.$track(TRACK_TYPES.NAVIGABILITY_ELEMENT_USED, {
          type: "Keyboard",
          listId: this.$store.getters["currentListData"].id,
        });
        window.scrollTo(window.scrollX, window.scrollY + 41);
      }
      if (key === 37 || key === 39) {
        this.$track(TRACK_TYPES.NAVIGABILITY_ELEMENT_USED, {
          type: "Keyboard",
          listId: this.$store.getters["currentListData"].id,
        });
        this.keyPressTutorial.set('arrowNavigation', true)
        this.seenTutorialOnCurrentRoute = true;
      }
    },
    clearLastKey(key: any) {
      if (this.lastKey == key) {
        this.lastKey = null;
      }
    },
    async toggleShowResult() {
      if (this.showResult) {
        this.$router.push(this.routePath+this.$route.params.list);
      } else {
        this.$router.push(this.routePath+this.$route.params.list+'/resultado');
      }

      this.fixRoute(this.$route);
    },
    async goToNextList() {

      this.streakAlreadyDisplayed = false;

      if (this.hasRecommendedListToCurrentList) {
        const list = this.nextRecommendedListToCurrentList;
        const { board, classroom } = list;

        if (board && classroom) {
          this.$track(TRACK_TYPES.LINK_NAVIGATED, {
            list: this.list,
            label: "Next List",
            context: list.name
          });
          this.routePath = `/${classroom.slug}/${board.slug}/`;
          this.$router.push(`/${classroom.slug}/${board.slug}/${list.slug}`);
          return;
        }
      }

      if (!this.popularLists || this.popularLists.length == 0) {
        this.loaded = false;
        await this.pullPopularLists();
      }
      if (this.popularLists && this.popularLists.length > 0) {
        const list = this.popularLists[Math.floor(Math.random() * this.popularLists.length)];
        this.$track(TRACK_TYPES.LINK_NAVIGATED, {
            list: this.list,
            label: "Next List",
            context: list.slug
        });
        this.goToList(list.id);
      }
    },
    goToList(listId:number) {
      if(this.isIFrame){
        const routeData = this.$router.resolve({path: this.routePath+listId});
        window.open(routeData.href, '_blank');
      } else {
        this.$router.push(this.routePath+listId)
      }
    },
    tween(start, end, key, duration) {
      let frameHandler;
      const animate = function(currentTime) {
          TWEEN.update(currentTime)
          frameHandler = requestAnimationFrame(animate);
      }
      const myTween = new TWEEN.Tween({ [key]: start }).to({ [key]: end }, duration)
          .onUpdate(object => {
              this[key] = object[key];
          })
          .onComplete(() => {
              cancelAnimationFrame(frameHandler);
          })
          .delay(100)
          .easing(TWEEN.Easing.Quadratic.Out)
          .start()
          frameHandler = requestAnimationFrame(animate);
    },
    getSolutionElements() {
      return {
        solutions: document.getElementsByClassName('solution-body'),
        sendSolution: this.$refs.sendSolution && this.$refs.sendSolution.$el,
      }
    },
    goToSolution() {
      this.$track(TRACK_TYPES.SCREEN_VIEW, {
        screen: "Solução",
        screenId: this.list.name
      });

      const { questionIndex } = this.$route.params;
      const { solutions, sendSolution } = this.getSolutionElements();

      if (solutions.length > 0) {
        solutions[0].scrollIntoView({ block: 'start',  behavior: 'smooth' });
      } else if (sendSolution) {
        sendSolution.scrollIntoView({ block: 'start',  behavior: 'smooth' });
      }

      this.hideSolutionButton();
    },
    scrollListener() {
      if (!this.solutionElementsNotVisible()) {
        this.hideSolutionButton();
      }
    },
    solutionElementsNotVisible() {
      const { solutions, sendSolution } = this.getSolutionElements();

      if (!solutions.length && !sendSolution) return false;

      return (
        (!Array.from(solutions).reduce((acc, e) => acc || elementIsVisible(e), false)) &&
        (!elementIsVisible(sendSolution))
      );
    },
    goToSolutionButtonVisible() {
      this.showSolutionButton = true;
      this.enterAnimationTimeout = setTimeout(() => {
        this.solutionButtonVisible = true;
      }, 300);
    },
    hideSolutionButton() {
      this.solutionButtonVisible = false;
      this.exitAnimationTimeout = setTimeout(() => {
        this.showSolutionButton = false;
      }, 300);
    }
  },

  beforeDestroy() {
    clearTimeout(this.enterAnimationTimeout);
    clearTimeout(this.exitAnimationTimeout);
  },
});

export default {
    state: {
        levels: {
            "EASY": { minimum: 0.75, maximum: 1},
            "MEDIUM": { minimum: 0.5, maximum: 0.75},
            "HARD": { minimum: 0, maximum: 0.5 }
        },
        selectedLevels: [ "EASY", "MEDIUM", "HARD" ]
    },
    getters: {
        displayNameByLevelName: (state: any) => (levelName: any) => {
            switch(levelName) {
                case "EASY":
                    return "fácil";
                case "MEDIUM":
                    return "médio";
                case "HARD":
                    return "difícil";
                default:
                    return "todas";
            }
        },
        displayNameByAssertionRate: (state: any) => (assertionRate: any) => {
            for (let level in state.levels) {
                if (assertionRate >= state.levels[level].minimum && assertionRate < state.levels[level].maximum ) {
                    switch(level) {
                        case "EASY":
                            return "fácil";
                        case "MEDIUM":
                            return "médio";
                        case "HARD":
                            return "difícil";
                        default:
                            return "todas";
                    }
                }
            }
        },
        levelNames: (state: any) => {
            return Object.keys(state.levels);
        }
    },
    mutations: {
        "SET_SELECTED_LEVELS"(state: any, selectedLevels: any) {
            state.selectedLevels = selectedLevels;
        }
    },
    actions: {
        handleOperatedLevel({ rootState, commit }: any, level: any) {
            if (rootState.levels.selectedLevels.includes(level)) {
                let filteredLevels = rootState.levels.selectedLevels.filter(selectedLevel => selectedLevel != level);
                commit("SET_SELECTED_LEVELS", filteredLevels);
            } else {
                commit("SET_SELECTED_LEVELS", [...rootState.levels.selectedLevels, level]);
            }
        }
    },
}
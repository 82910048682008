










import Vue, { PropType } from "vue";
import VideoModel from "@/dex-shared/models/VideoModel";
export default Vue.extend({
  props: {
    video: {
      type: Object as PropType<VideoModel>,
      required: true,
      validator: function (value:VideoModel) {
        return value.providerType === "YOUTUBE";
      }
    }
  },
});

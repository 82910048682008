export const trackHubspotEvent = (eventId, email, properties) => {
  if (window._hsq) {
    window._hsq.push([
      "identify",
      {
        email,
        ...properties
      }
    ]);
    window._hsq.push([
      "trackEvent",
      {
        id: eventId
      }
    ]);
  }
};

import Tracker from "./Tracker";
import { MixpanelEngine } from "./interfaces";
import { TRACK_TYPES } from "@/dex-shared/helpers/track";
import * as _ from "lodash";

/* 
    It's responsible to define mixpanel methods 
    interface with application store's relevant information.
*/

class MixpanelTracker extends Tracker {
  store: any;
  constructor(store) {
    const engine = {
      userIdentity: (data) => {
        window.mixpanel.identify(data.userId);
      },
      track: (eventName, data) => window.mixpanel.track(eventName, data),
    } as MixpanelEngine;
    super(engine);
    this.engine = engine;
    this.store = store;
  }
  private safe(desiredKeyName, payload, key) {
    return _.get(payload, key) !== undefined
      ? { [desiredKeyName]: _.get(payload, key) }
      : null;
  }

  private informationRulesOf(eventInfo) {
    let { safe } = this;
    const userData = this.userInformation();
    let event = { eventName: eventInfo.eventName, ...userData };
    switch (eventInfo.eventName) {
      case TRACK_TYPES.LIST_STARTED:
        return _.chain(event)
          .assign(safe("discipline", eventInfo.payload, "board.slug"))
          .assign(safe("listSlug", eventInfo.payload, "slug"))
          .value();
      case TRACK_TYPES.LIST_CONSUMED:
        return _.chain(event)
          .assign(safe("discipline", eventInfo.payload, "board.slug"))
          .assign(safe("listSlug", eventInfo.payload, "list.slug"))
          .assign(
            safe("listCorrectRate", eventInfo.payload, "assertivenessRate")
          )
          .value();
      case TRACK_TYPES.EXERCISE_ANSWERED:
        return _.chain(event)
          .assign(safe("listSlug", eventInfo.payload, "listSlug"))
          .assign(safe("questionId", eventInfo.payload, "questionId"))
          .assign(safe("examGroupSlug", eventInfo.payload, "examGroup"))
          .assign(
            safe("exerciseAnswerWasCorrect", eventInfo.payload, "correct")
          )
          .assign(safe("examSlug", eventInfo.payload, "exam"))
          .assign(safe("discipline", eventInfo.payload, "discipline"))
          .value();
      case TRACK_TYPES.LIST_CONTINUED:
        return _.chain(event)
          .assign(safe("discipline", eventInfo.payload, "board.slug"))
          .assign(safe("listSlug", eventInfo.payload, "slug"))
          .assign(
            safe(
              "questionsAnsweredBefore",
              eventInfo.payload,
              "answeredQuestions"
            )
          )
          .value();
      case TRACK_TYPES.SOLUTION_INTERACTED:
        return _.chain(event)
          .assign(safe("solutionActivity", eventInfo.payload, "activity"))
          .assign(safe("voteStatus", eventInfo.payload, "label"))
          .assign(safe("questionId", eventInfo.payload, "questionId"))
          .value();
      case TRACK_TYPES.LINK_NAVIGATED:
        return (
          _.chain(event)
            // .assign(safe('board', eventInfo.payload, 'board.slug'))
            .assign(safe("discipline", eventInfo.payload, "board.slug"))
            .assign(safe("boardSection", eventInfo.payload, "section"))
            // .assign(safe('exam', eventInfo.payload, 'examSlug'))
            .assign(safe("examSlug", eventInfo.payload, "examSlug"))
            // .assign(safe('examGroup', eventInfo.payload, 'examGroup'))
            .assign(safe("examGroupSlug", eventInfo.payload, "examGroup"))
            .assign(safe("context", eventInfo.payload, "context"))
            // .assign(safe('list', eventInfo.payload, 'list.slug'))
            .assign(safe("listSlug", eventInfo.payload, "list.slug"))
            // .assign(safe('classroom', eventInfo.payload, 'classroom.slug'))
            .assign(safe("classroomSlug", eventInfo.payload, "classroom.slug"))
            .assign(safe("label", eventInfo.payload, "label"))
            .value()
        );
      case TRACK_TYPES.SCREEN_VIEW:
        return _.chain(event)
          .assign(safe("screen", eventInfo.payload, "screen"))
          .assign(safe("screenId", eventInfo.payload, "screenId"))
          .assign(safe("subsections", eventInfo.payload, "sections"))
          .value();
      case TRACK_TYPES.EXAM_STARTED:
        return _.chain(event)
          .assign(safe("examSlug", eventInfo.payload, "exam"))
          .assign(safe("examVariant", eventInfo.payload, "variant"))
          .assign(
            safe(
              "isPostgradAssessment",
              eventInfo.payload,
              "isPostgradAssessment"
            )
          )
          .assign(safe("relatedAssessmentCourse", eventInfo.payload, "relatedAssessmentCourse"))
          .assign(safe("relatedAssessmentCategory", eventInfo.payload, "relatedAssessmentCategory"))
          .value();
      case TRACK_TYPES.EXAM_CONSUMED:
        return _.chain(event)
          .assign(safe("examSlug", eventInfo.payload, "exam"))
          .assign(safe("examVariant", eventInfo.payload, "variant"))
          .assign(safe("examFinishMethod", eventInfo.payload, "finishMethod"))
          .assign(
            safe(
              "isPostgradAssessment",
              eventInfo.payload,
              "isPostgradAssessment"
            )
          )
          .assign(safe("relatedAssessmentCourse", eventInfo.payload, "relatedAssessmentCourse"))
          .assign(safe("relatedAssessmentCategory", eventInfo.payload, "relatedAssessmentCategory"))
          .value();
      case TRACK_TYPES.EXAM_EXIT_INTENTION:
        return _.chain(event)
          .assign(safe("examSlug", eventInfo.payload, "exam"))
          .value();
      case TRACK_TYPES.RANKING_INTERACTED:
        return _.chain(event)
          .assign(safe("interactionType", eventInfo.payload, "action"))
          .assign(safe("examSlug", eventInfo.payload, "examSlug"))
          .assign(safe("examGroupSlug", eventInfo.payload, "examGroupSlug"))
          .value();
      case TRACK_TYPES.REPORT_SENT:
        return _.chain(event)
          .assign(safe("reportOrigin", eventInfo.payload, "context"))
          .assign(safe("reportIssue", eventInfo.payload, "type"))
          .value();
      case TRACK_TYPES.NAVIGABILITY_ELEMENT_USED:
        return _.chain(event)
          .assign(safe("type", eventInfo.payload, "type"))
          .assign(safe("listId", eventInfo.payload, "listId"))
          .assign(safe("examId", eventInfo.payload, "examId"))
          .assign(safe("correctionId", eventInfo.payload, "correctionId"))
          .assign(safe("label", eventInfo.payload, "label"))
          .assign(safe("examFinished", eventInfo.payload, "examFinished"))
          .value();
      case TRACK_TYPES.ESSAY_STARTED:
        return _.chain(event)
          .assign(safe("discipline", eventInfo.payload, "board"))
          .assign(safe("essaySubject", eventInfo.payload, "essaySubject"))
          .assign(safe("wasSuggested", eventInfo.payload, "suggested"))
          .assign(safe("sentMethod", eventInfo.payload, "sentMethod"));
      case TRACK_TYPES.ESSAY_CONTINUED:
        return _.chain(event)
          .assign(safe("discipline", eventInfo.payload, "board"))
          .assign(safe("essaySubject", eventInfo.payload, "essaySubject"))
          .assign(safe("wasSuggested", eventInfo.payload, "suggested"))
          .assign(safe("sentMethod", eventInfo.payload, "sentMethod"));
      case TRACK_TYPES.ESSAY_CONSUMED:
        return _.chain(event)
          .assign(safe("discipline", eventInfo.payload, "board"))
          .assign(safe("essaySubject", eventInfo.payload, "essaySubject"))
          .assign(safe("wasSuggested", eventInfo.payload, "suggested"))
          .assign(safe("sentMethod", eventInfo.payload, "sentMethod"))
          .assign(safe("essayId", eventInfo.payload, "essayId"));
      case TRACK_TYPES.ESSAY_SEARCHED:
        return _.chain(event)
          .assign(safe("discipline", eventInfo.payload, "board"))
          .assign(
            safe("searchedOnBoard", eventInfo.payload, "examinationBoard")
          )
          .assign(safe("searchTerm", eventInfo.payload, "searchTerm"));
      case TRACK_TYPES.CORRECTION_CONSUMED:
        return _.chain(event)
          .assign(safe("discipline", eventInfo.payload, "board"))
          .assign(safe("essaySubject", eventInfo.payload, "essaySubject"))
          .assign(safe("wasSuggested", eventInfo.payload, "suggested"))
          .assign(safe("sentMethod", eventInfo.payload, "sentMethod"))
          .assign(safe("correctionScore", eventInfo.payload, "essayScore"));
      case TRACK_TYPES.CORRECTION_INTERACTED:
        return _.chain(event)
          .assign(safe("discipline", eventInfo.payload, "board"))
          .assign(safe("wasSuggested", eventInfo.payload, "suggested"))
          .assign(safe("interactionType", eventInfo.payload, "activity"))
          .assign(safe("sentMethod", eventInfo.payload, "sentMethod"))
          .assign(safe("correctionScore", eventInfo.payload, "essayScore"));
      default:
        return {};
    }
  }

  private user() {
    const { user } = this.store.auth;
    return user && user.email && user.descomplicaId ? user : null;
  }

  private userInformation() {
    const user = this.user();
    if (!user) {
      return {};
    } else {
      return {
        userId: user.descomplicaId ? user.descomplicaId : null,
        userEmail: user.email ? user.email : null,
        userName: user.name ? user.name : null,
        is_subscriber:
          user.isSubscriber === undefined ? null : user.isSubscriber,
      };
    }
  }

  identifyUser() {
    this.userIdentityWith({ userId: this.user().descomplicaId });
  }

  formatMPResponseWith(payload) {
    let response = {};
    let keys = Object.keys(payload).filter((key) => key !== "eventName");
    for (let key of keys) {
      response[key] = payload[key];
    }
    return response;
  }

  track(payload) {
    if (this.user()) {
      this.identifyUser();
    }
    const treatedPayload = this.informationRulesOf(payload) as any;
    this.trackWith(
      treatedPayload.eventName,
      this.formatMPResponseWith(treatedPayload)
    );
  }
}

export default MixpanelTracker;

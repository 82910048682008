



































import Vue from "vue";

export default Vue.extend({
  props: ["placeholder", "value", "resizable", "rows", "initialValue"],

  data() {
    return {
      shouldResize: this.resizable == undefined ? true : this.resizable
    };
  },

  mounted() {
    if (this.value) {
      this.resizeTextArea();
    }
  },

  methods: {
    resizeTextArea() {
      const { textArea } = this.$refs;

      textArea.style.height = 0;
      textArea.style.height = textArea.scrollHeight + "px";
    },
    handleInput(e) {
      this.$emit("input", e.target.value);

      if (this.shouldResize) {
        this.resizeTextArea();
      }
    }
  }
});

export default class TestBuilder {
  savedTests: Object;
  constructor({ localTests, savedTests }: any) {
    this.savedTests = savedTests;
  }

  calculateVariant({ variants, ratios }: any) {
    let weightSum = 0,
      randomNumber = Math.random();
    for (let index in variants) {
      weightSum += ratios[index];
      weightSum = +weightSum.toFixed(2);
      if (randomNumber <= weightSum) {
        return variants[index];
      }
    }
  }

  buildTests() {
    let savedTestsNames = Object.keys(this.savedTests);
    let localTests = {};
    for (let name of savedTestsNames) {
      let test = this.savedTests[name];
      let variants = Object.keys(test.data);
      let ratios = [];
      for (let variant of variants) {
        ratios.push(test.data[variant].ratio);
      }
      let determinedVariant = this.calculateVariant({ variants, ratios });
      localTests[name] = determinedVariant;
    }
    return localTests;
  }

  testsWithVariantSelected() {
    let testsWithDeterminedVariant = this.buildTests();
    return testsWithDeterminedVariant;
  }
}

import { query, mutation } from "@/dex-shared/helpers/graphql";

const PUSH_SOLUTION = 'PUSH_SOLUTION';
const REMOVE_SOLUTION = 'REMOVE_SOLUTION';

export default {
  state: {},
  getters: {},
  mutations: {},
  actions: {
    async [PUSH_SOLUTION]({ commit }: any, { solutionId, solution, questionId, userId }: any) {
      const content = [{ behavior: 'text', content: solution }];

      if (!solutionId) {
        const response = await mutation(
          `
            mutation sendCreateSolutionInDexWeb($userId: Int!, $questionId: Int!, $content: JSON!) {
              createSolution(input: {
                solution: {
                userId: $userId
                questionId: $questionId
                content: $content
                }
              }) {
                solution {
                  id
                }
              }
            }
          `,
          {
            userId,
            questionId,
            content,
          }
        );
      } else {
        const votesResponse = await query(
          `
            query VotesForSolution($solutionId: Int!) {
              allVotes(condition: {
                solutionId: $solutionId
              }) {
                nodes {
                  id
                }
              }
            }
          `,
          {
            solutionId: +solutionId,
          }
        );

        const votes = votesResponse.data.allVotes.nodes;

        if (!!votes.length) {
          await mutation(
            `
              mutation deleteManyVotes {
                ${votes.map(
                  (vote: any, index: number) => `
                    vote${index}: deleteVoteById(input: {
                      id: ${vote.id}
                    }) {
                      vote {
                        id
                      }
                    }
                  `
                )}
              }
            `
          );
        }

        await mutation(
          `
            mutation UpdateSolution($id: Int!, $content: JSON!) {
              updateSolutionById(
                input: {
                  id: $id
                  solutionPatch: {
                    content: $content
                  }
                }
              ) {
                solution {
                  id
                }
              }
            }
          `,
          {
            id: +solutionId,
            content,
          }
        );
      }
    },

    async REMOVE_SOLUTION({ commit }: any, solutionId: number) {
      let response = await mutation(
        `
          mutation deleteSolution($solutionId: Int!) {
            deleteSolutionById(input: {
              id: $solutionId
            }) {
              solution {
                id
              }
            }
          }
        `,
        {
          solutionId: +solutionId
        }
      );
    }
  }
};

















































































































































































import Vue from 'vue';
import CloseIcon from "vue-material-design-icons/Close.vue";
import pxButton from '@/dex-shared/components/px-button.vue';
import {query, mutation} from '@/dex-shared/helpers/graphql.ts';
import { mapGetters } from 'vuex';
import classrooms from '@/store/modules/classrooms';
import pxModal from '@/dex-shared/components/px-modal.vue';
export default Vue.extend({
    components: {
        CloseIcon,
        pxButton,
        pxModal
    },
    data: function() {
        return {
            showDialog: false,
            showQuestions: true,
            booleanQuestions: [],
            textQuestions: [],
            buttonState: [['button-secondary', 'button-secondary']]
        }
    },
    computed: {
        ...mapGetters({
            classroom: "currentClassroom",
        })
    },
    methods: {
        async getFeedbackQuestionsIds() {
            await this.$store.dispatch("PULL_CLASSROOM", this.$route.params.classroom);
            const response = await query(`query getFeedbackQuestionsIds($classroomId:  Int!) {
                allFeedbackQuestions(condition: {
                    classroomId: $classroomId,
                    disabled: false
                }) {
                    nodes {
                        id
                        statement
                        responseType
                    }
                }
            }`, {
                classroomId: this.classroom.id
            });

            // JSON parse/stringify unfreezes object/allows new properties to be added
            let questions = JSON.parse(JSON.stringify(response.data.allFeedbackQuestions.nodes));
            this.textQuestions = questions.filter(
                function(question:any) {
                    return question.responseType == "text"
                }
            );
            this.booleanQuestions = questions.filter(
                function(question:any) {
                    return question.responseType == "boolean"
                }
            );
            this.buttonState = Array(this.booleanQuestions.length).fill(['button-secondary', 'button-secondary']);
        },
        toggleDialog() {
            this.showDialog = !this.showDialog;
            this.showQuestions = true;
        },
        setAnswer(index:any, answer:any) {
            this.booleanQuestions[index].answer = answer;
            if (answer) {
                this.buttonState.splice(index, 1, ['button-primary', 'button-disabled']);
            } else {
                this.buttonState.splice(index, 1, ['button-disabled', 'button-primary']);
            }
        },
        async sendAnswers() {
            this.showQuestions = false;
            let response = await mutation(`mutation sendAnswersInDexWeb($userId: Int!) {
                ${this.textQuestions.map(
                    (question:any, index:any) => `textResponse${index}: createFeedbackResponse(input: {
                        feedbackResponse: {
                            text: "${question.answer}"
                            feedbackQuestionId: ${question.id}
                            userId: $userId
                        }
                    }) {
                        feedbackResponse {
                            id
                        }
                    }
                    `).join('')}
                ${this.booleanQuestions.map(
                    (question:any, index:any) => `boolResponse${index}: createFeedbackResponse(input: {
                        feedbackResponse: {
                            text: "${question.answer}"
                            feedbackQuestionId: ${question.id}
                            userId: $userId
                        }
                    }) {
                        feedbackResponse {
                            id
                        }
                    }
                    `).join('')}
            }`, {
                userId: this.$store.state.auth.user.id
            });
        }
    },
    watch: {
        classroom(newValue, oldValue)  {
            if (newValue.id != oldValue.id) {
                this.getFeedbackQuestionsIds();
            }
        }
    }

})

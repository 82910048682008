export let hubspot = "1653949";

function searchParams() {
  let searchParams = {};
  let queryParams = location.search
    .substring(1)
    .split("&")
    .filter(parameter => parameter.length > 1);
  queryParams.map(key => {
    const [k, value] = key.split("=");
    searchParams[k] = value;
  });
  return searchParams;
}

let parsedParams = searchParams();
localStorage.firstSearchParams =
  parsedParams && Object.keys(parsedParams).length > 0
    ? JSON.stringify(parsedParams)
    : localStorage.firstSearchParams;

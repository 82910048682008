import exercise from '../../../dex-shared/store/plugins/tracking/exercise';
import screenViewed from '@/dex-shared/store/plugins/tracking/screenViewed';
import solution from './solution';
import report from './report';
import exerciseAnswered from '@/dex-shared/store/plugins/tracking/exerciseAnswered';
import navigabilityElementUsed from '@/dex-shared/store/plugins/tracking/navigabilityElementUsed';
import linkNavigated from '@/dex-shared/store/plugins/tracking/linkNavigated';

export default [
    exercise, 
    screenViewed, 
    solution, 
    report, 
    exerciseAnswered, 
    navigabilityElementUsed, 
    linkNavigated
];

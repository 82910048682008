













import Vue, { PropType } from "vue";
import YoutubeVideoShow from "./YoutubeVideoShow.vue";
import KalturaVideoShow from "./KalturaVideoShow.vue";
import VideoModel from "@/dex-shared/models/VideoModel";
export default Vue.extend({
  components: {
    YoutubeVideoShow,
    KalturaVideoShow
  },
  computed: {
    UNIQUE_OBJ_ID(): string {
      const { providerType, providerId } = this.video;
      return `${providerType}-${providerId}`;
    }
  },
  props: {
    video: {
      type: Object as PropType<VideoModel>,
      required: true,
      validator: function (value:VideoModel) {
        return ["KALTURA", "YOUTUBE"].includes(value.providerType)
      }
    }
  }
});

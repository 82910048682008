

















































import Vue from "vue";
import KeyboardBackspaceIcon from "vue-material-design-icons/KeyboardBackspace.vue";

export default Vue.extend({
  components: {
    KeyboardBackspaceIcon,
  },

  data() {
    return {
      fromRoute: null,
    };
  },

  methods: {
    handleBack(fallbackRoute) {
      const userCameFromDirectLink = this.fromRoute && !this.fromRoute.name;
      if (userCameFromDirectLink) {
        this.$router.push(fallbackRoute);
      } else {
        this.$router.back();
      }
    }
  },

  beforeRouteEnter(to, from, next) {
    next(vm => {
      (vm as any).fromRoute = from;
    })
  },
});

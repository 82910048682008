const OPEN_MODAL = "OPEN_MODAL";
const CLOSE_MODAL = "CLOSE_MODAL";

export default {
  state: {
    modals: []
  },
  getters: {},
  mutations: {
    [OPEN_MODAL](state: any, modal: any) {
      state.modals = [...state.modals, modal];
    },
    [CLOSE_MODAL](state: any, modalId: number) {
      state.modals = state.modals.filter(m => m.id !== modalId);
    }
  },
  actions: {
    [OPEN_MODAL]({ commit, state }: any, componentAndProps: any) {
      const { component, props = {} } = componentAndProps;

      commit(OPEN_MODAL, { id: Date.now(), component, props });
    },
    [CLOSE_MODAL]({ commit, state }: any, modalId: number) {
      commit(CLOSE_MODAL, modalId);
    }
  }
};

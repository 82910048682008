























































































































import _ from 'lodash';
import { mapState, mapGetters } from "vuex";
import Content from "../../dex-shared/components/content/Content.vue";
import InteractiveData from "./interactive_data/InteractiveData.vue";
import pxCard from "../../dex-shared/components/px-card.vue";
import DotsVerticalIcon from "vue-material-design-icons/DotsVertical.vue";
import CommentAlertIcon from "vue-material-design-icons/CommentAlert.vue";
import StarCircleIcon from "vue-material-design-icons/StarCircle.vue";
import PencilIcon from "vue-material-design-icons/Pencil.vue";
import DeleteIcon from "vue-material-design-icons/Delete.vue";
import ThumbUpIcon from "vue-material-design-icons/ThumbUp.vue";
import ThumbDownIcon from "vue-material-design-icons/ThumbDown.vue";
import ArrowRight from 'vue-material-design-icons/ArrowRight.vue';
import ArrowLeft from 'vue-material-design-icons/ArrowLeft.vue';
import SpeechBubbleMenu from "../../dex-shared/components/SpeechBubbleMenu.vue";
import ReportModal from "../../dex-shared/components/report/ReportModal.vue";
import SendSolution from "../solution/SendSolution.vue";
import track, { TRACK_TYPES } from '../../dex-shared/helpers/track';
import Loading from "@/dex-shared/components/Loading.vue";

export default {
  props: ["solutions", "questionId"],

  components: {
    Content,
    InteractiveData,
    pxCard,
    DotsVerticalIcon,
    CommentAlertIcon,
    StarCircleIcon,
    PencilIcon,
    DeleteIcon,
    ThumbUpIcon,
    ThumbDownIcon,
    ArrowRight,
    ArrowLeft,
    SpeechBubbleMenu,
    SendSolution,
    Loading
  },

  data() {
    return {
      dotsMenuOpen: this.solutions.data.reduce((acc, s, i) => ({ ...acc, [s.id]: false }), {}),
      solutionsBeingEdited: this.solutions.data.reduce((acc, s, i) => ({ ...acc, [s.id]: false }), {}),
      ReportModal,
      timers: {},
      isLoadingSolutions: false,
      solutionsPerPage: 15,
      solutionsPaginationOffset: 0
    };
  },

  computed: {
    ...mapState({
      user: (state: any) => state.auth.user,
      listItems: (state:any) => state.lists.current.listItemsByListId.nodes
    }),
    ...mapGetters({
      listReplies: "listReplies",
      userId: "userId",
      userIsAdmin: "userIsAdmin",
      voteHasChanged: "voteHasChanged",
      votedByUser: "votedByUser"
    }),
    isEnemQuestion() {
      return this.$route.params.classroom === 'enem';
    },
    orderedSolutions() {
			const solutions = this.solutions.data.map(s => ({
        ...s,
        balance: s.cachedVotesUp - s.cachedVotesDown,
        validation: s.validation ? 1 : 0,
        fromAdmin: s.user.role === 'admin' ? 1 : 0,
        validatedByAdmin: s.validatedBy == null ? 0 : s.validationJudgment ? 1 : 0,
      }));

			return _.orderBy(
        solutions,
        ['fromAdmin', 'validatedByAdmin', 'balance', 'updatedAt'],
        ['desc', 'desc', 'desc', 'desc']
      )
    },
    solutionsPageNumber() {
      return this.solutions.shownPage;
    }
  },

  watch: {
    solutionsPageNumber() {
      this.solutionsPaginationOffset = this.solutionsPageNumber === 1
        ? 0 
        : ((this.solutionsPageNumber * this.solutionsPerPage) - this.solutionsPerPage);
    }
  },

  methods: {
    async voteForSolution(solution, positive) {
      const solutionId = solution.id;

      if (solution.user.id === this.userId) {
        return alert('Você não pode votar na sua própria solução.');
      }

      if (this.userIsAdmin && positive === false) {
        return alert('Avaliação negativa apenas para alunos.');
      }

      this.$track(TRACK_TYPES.SOLUTION_INTERACTED, {
        activity: "Vote",
        label: positive ? "positive" : "negative",
        questionId: this.questionId
      });

      this.$store.commit("SET_LOCAL_VOTE", { solutionId, positive });
      this.sendVoteRequest(solutionId, positive);
    },
    sendVoteRequest(solutionId, positive) {
      if (this.timers[solutionId]) {
        clearTimeout(this.timers[solutionId]);
      }

      this.timers[solutionId] = setTimeout(async () => {
        if (!this.voteHasChanged(this.questionId, solutionId)) return;

        await this.$store.dispatch('PUSH_VOTE', {
          userId: this.userId,
          solutionId,
          positive,
        });

        await this.$store.dispatch("PULL_LIST", this.$route.params.list);
        this.$store.commit("UPDATE_SESSION_SOLUTIONS", this.questionId);
      }, 1000);
    },
    isBestVoted(solutionIndex) {
      const solution = this.orderedSolutions[solutionIndex];
      const balance = solution.cachedVotesUp - solution.cachedVotesDown;
      const hasTiedBalances = this.orderedSolutions.filter(s => s.cachedVotesUp - s.cachedVotesDown === balance).length > 1;

      return balance > 0 && solutionIndex === 0 && !hasTiedBalances;
    },
    toggleMenu(solutionId) {
      this.dotsMenuOpen = {
        ...this.dotsMenuOpen,
        [solutionId]: !this.dotsMenuOpen[solutionId],
      };
    },
    openReportModal(solutionId) {
      this.$track(TRACK_TYPES.SCREEN_VIEW, {
        screen: 'ReportModal'
      });
      this.$store.dispatch('OPEN_MODAL', {
        component: this.ReportModal,
        props: {
          reportFor: 'solution',
          reportedId: solutionId,
        }
      })
    },
    editSolution(solutionId) {
      if (confirm('Você tem certeza que quer editar esta solução? Após editar todos os votos são perdidos.')) {
        this.solutionsBeingEdited = {
          ...this.solutionsBeingEdited,
          [solutionId]: true,
        }
      }
    },
    cancelEdit(solutionId) {
      this.solutionsBeingEdited = {
        ...this.solutionsBeingEdited,
        [solutionId]: false,
      };

      this.toggleMenu(solutionId);
    },
    async deleteSolution(solutionId) {
      if (confirm('Você tem certeza que quer excluir esta solução? Após excluir não é possível recuperá-la.')) {
        await this.$store.dispatch('REMOVE_SOLUTION', solutionId);
        await this.$store.dispatch('PULL_LIST', this.$route.params.list);
        await this.$store.commit('UPDATE_SESSION_SOLUTIONS', this.questionId);
      }
    },
    async changeSolutionsPage(action: 'prev' | 'next') {
      if (this.isLoadingSolutions) return;

      switch(action) {
        case 'prev':
          const isFirstPage = this.solutionsPageNumber === 1;
          if (isFirstPage) return;

          this.solutionsPaginationOffset !== 0 && (this.solutionsPaginationOffset -= this.solutionsPerPage);
          break;

        case 'next':
          const isLastPage = this.solutions.hasNextPage === false;
          if (isLastPage) return;

          this.solutionsPaginationOffset += this.solutionsPerPage;
          break;
        
        default: 
          return;
      }

      this.reloadSolutions();
    },
    async reloadSolutions() {
      this.isLoadingSolutions = true;
      await this.$store.dispatch(
        'REFRESH_SOLUTIONS',
        {
          questionId: this.questionId,
          first: this.solutionsPerPage,
          offset: this.solutionsPaginationOffset,
        }
      );
      this.isLoadingSolutions = false;

      this.$nextTick(() => {
        this.$refs.firstSolution[0].scrollIntoView({ block: 'start', behavior: 'smooth' });
      });
    }
  }
};

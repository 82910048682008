









































import Vue from "vue";
import { elementIsVisible } from "../lib/position";

export default Vue.extend({
  props: {
    width: {
      type: String,
      default: "100%"
    },
    animated: {
      type: Boolean,
      default: false
    },
    amount: {
      type: Number,
      required: true
    },
    total: {
      type: Number,
      required: true
    },
    backgroundColor: {
      type: String,
      default: "#F2F2F2"
    },
    progressColor: {
      type: String,
      default: "#00E88F"
    },
    margin: {
      type: String,
      default: "0"
    },
    transitionDuration: {
      type: String,
      default: "0.4s"
    },
    transitionDelay: {
      type: String,
      default: "0s"
    },
    transitionTimingFunction: {
      type: String,
      default: "ease-out"
    },
    barHeight: {
      type: Number,
      default: 10
    }
  },

  data() {
    return { ready: false, readyInterval: null, readyTimeout: null };
  },

  computed: {
    progressWidth() {
      const defaultWidth = (100 * this.amount) / this.total + "%";

      if (this.animated) {
        return this.ready ? defaultWidth : "0%";
      }

      return defaultWidth;
    }
  },

  mounted() {
    this.readyInterval = setInterval(() => {
      if (this.isInsideViewport()) {
        this.ready = true;
        clearInterval(this.readyInterval);
      }
    }, 300);

    this.readyTimeout = setTimeout(() => {
      this.ready = true;
      clearInterval(this.readyInterval);
    }, 6000);
  },

  beforeDestroy() {
    clearInterval(this.readyInterval);
    clearTimeout(this.readyTimeout);
  },

  methods: {
    isInsideViewport() {
      return elementIsVisible(this.$refs.bar);
    }
  }
});

























































































import Vue from 'vue';
import TWEEN from '@tweenjs/tween.js';

export default Vue.extend({
    props: ['activeStreak'],
    data() {
        return {
            progressBarValue: 0
        }
    },
    computed: {
        nextMilestonePercentage() {
            return ((this.activeStreak.achievedMilestones) * 20);
        },
        achievedMilestones() {
            let achieved = [];
            for (let breakpoint of this.activeStreak.streakBreakpoints) {
                if (achieved.length < (this.activeStreak.achievedMilestones) ) {
                    achieved.push(breakpoint);
                }
            }
            return achieved;
        }
    },
    watch: {
        activeStreak: function(nextStreak) {
            this.tween(0, this.progressBarPercentage(nextStreak));
        }
    },
    created() {
        this.tween(0, this.progressBarPercentage(this.activeStreak));
    },
    methods: {
        tween(start, end) {
            // TODO: Tween knows too much about percentage transition, it is not reusable.
            let frameHandler

            const animate = function(currentTime) {
                TWEEN.update(currentTime)
                frameHandler = requestAnimationFrame(animate);
            }

            const myTween = new TWEEN.Tween({ progressBarValue: start }).to({ progressBarValue: end }, 850)
                .onUpdate((object) =>  {
                    this.progressBarValue = object.progressBarValue;
                })
                .onComplete(() => {
                    cancelAnimationFrame(frameHandler);
                })
                .delay(100)
                .easing(TWEEN.Easing.Quadratic.Out)
                .start()
                frameHandler = requestAnimationFrame(animate);
        },
        progressBarPercentage(streak) {
            // TODO: knows the message and the fact that exists a streak, it has first degree dependency.
            let completedLists = streak.completedLists, 
            achievedMilestones = streak.achievedMilestones;

            if (!completedLists || completedLists <= 0) {
                return 0;
            }

            if (streak.streakBreakpoints.includes(completedLists)) {
                return ( (achievedMilestones - 1) * 20 );
            } else {
                let proportionalDifference = ( ((streak.completedLists - streak.lastAchievedMilestone) * 20 ) / ((streak.nextMilestone) - streak.lastAchievedMilestone) )
                return proportionalDifference + ( (achievedMilestones - 1) * 20 );
            }
        },
    }
})









































































import Vue from 'vue'
import CloseIcon from "vue-material-design-icons/Close.vue";
export default Vue.extend({
    components: {
        CloseIcon
    },
    data() {
      return {
        closed: false
      }
    },
    methods: {
        onCloseClicked() {
          this.closed = true;
        }
    }
})

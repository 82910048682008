const TRACK = "TRACK";

export default {
  state: {},
  getters: {},
  mutations: {},
  actions: {
    [TRACK]({ commit, state }: any, {mutationName, payload}: any) {
      
    },
  }
};

const storageKey = 'keyPressTutorial';

interface KeyPressTutorial {
  tutorial: any
}

class KeyPressTutorial {
  constructor() {
    this.tutorial = JSON.parse(localStorage.getItem(storageKey) || 'null') || { answer: false, arrowNavigation: false, numberNavigation: false }; 
  }

  get completed() {
    const { answer, arrowNavigation, numberNavigation } = this.tutorial;
    return answer && arrowNavigation && numberNavigation;
  }

  get answer() {
    return this.tutorial.answer;
  }

  get arrowNavigation() {
    return this.tutorial.arrowNavigation;
  }

  get numberNavigation() {
    return this.tutorial.numberNavigation;
  }

  set(prop: string, value: boolean) {
    this.tutorial[prop] = value;
    localStorage.setItem(storageKey, JSON.stringify(this.tutorial));
  }
}

const keyPressTutorial = new KeyPressTutorial();

export default keyPressTutorial;
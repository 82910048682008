

































































import Vue from 'vue';
import { mapGetters } from 'vuex';

export default Vue.extend({
  props: ['questionId'],

  computed: {
    ...mapGetters({
      sessionQuestionSolution: 'sessionQuestionSolution',
      userId: 'userId',
    }),
  },

  methods: {
    async goToUserSolution() {
      let solutions = this.sessionQuestionSolution(this.questionId);

      if (solutions.shownPage > 1) {
        await this.$store.dispatch(
          'REFRESH_SOLUTIONS',
          {
            questionId: this.questionId,
            first: 15,
            offset: 0,
          }
        );
        
        solutions = this.sessionQuestionSolution(this.questionId);
      };

      const userSolutionId = solutions && solutions.data.find(s => s.user.id === this.userId).id;

      const cardId = `solution-${userSolutionId}`;

      document.getElementById(cardId).scrollIntoView({ block: 'start',  behavior: 'smooth' });
    }
  }
});

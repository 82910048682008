























import Vue from "vue";

export default Vue.extend({
  props: ['size', 'thickness', 'correctCount', 'wrongCount', 'totalCount'],

  computed: {
    slotInUse() {
      return !!this.$slots.default
    },
  },

  methods: {
    totalText() {
      const thousands = (this.totalCount || 0) / 1000;
      return !!Math.floor(thousands) ? `${thousands.toFixed(2)}k` : this.totalCount;
    },
  }
});















import Vue from "vue";
import { mapGetters } from "vuex";
import pxButton from "@/dex-shared/components/px-button.vue";
import ResizableTextArea from "@/dex-shared/components/ResizableTextArea.vue";
import { mutation } from '@/dex-shared/helpers/graphql';
import solutionsStorage from '../../helpers/solutionsStorage';
import track, { TRACK_TYPES } from '../../dex-shared/helpers/track';

export default Vue.extend({
  props: ['solution', 'solutionId', 'questionId'],

  components: {
    pxButton,
    ResizableTextArea,
  },

  computed: {
    ...mapGetters({
      userId: "userId"
    })
  },

  data() {
    return {
      solutionContent: this.solution || '',
      sendingSolution: false,
    };
  },

  methods: {
    async sendSolution() {
      if (this.sendingSolution) return;

      if (!this.isSolutionValid(this.solutionContent)) {
        return alert('Essa solução não é válida.');
      }

      this.sendingSolution = true;

      await this.$store.dispatch('PUSH_SOLUTION', {
        solutionId: this.solutionId,
        solution: this.solutionContent,
        userId: this.userId,
        questionId: this.questionId,
      });

      this.$track(TRACK_TYPES.SOLUTION_INTERACTED, {
        activity: "Create",
        question: this.questionId
      });

      solutionsStorage.set('userSentSolution', true);

      await this.$store.dispatch('PULL_LIST', this.$route.params.list);
      await this.$store.commit('UPDATE_SESSION_SOLUTIONS', this.questionId);

      this.$emit('solutionPushed', this.solutionId);
      this.solutionContent = '';

      this.sendingSolution = false;
    },
    isSolutionValid(string) {
	    let stringWithoutSpaces = string.replace(/\s/g, '');
      let stringWithoutSpecialCharacters = stringWithoutSpaces.replace(/[!@#$%^&*(),.?":{}|<>]/g, '');
      return stringWithoutSpecialCharacters.length > 0 ? true : false;
    }
  },
  watch: {
    questionId() {
      this.solutionContent = '';
    }
  },
});

import { query, mutation } from '@/dex-shared/helpers/graphql';
import searchRecommendations from '../../helpers/searchRecommendations';
import { trackHubspotEvent } from '../../helpers/hubspot';
import track, { TRACK_TYPES } from '@/dex-shared/helpers/track';
import VideoModel from '@/dex-shared/models/VideoModel';

// mutations
let SET_CURRENT_LIST = 'SET_CURRENT_LIST';
let SET_CURRENT_LIST_STAT = 'SET_CURRENT_LIST_STAT';
let UPDATE_SESSION_REPLIES = 'UPDATE_SESSION_REPLIES';
let UPDATE_SESSION_SOLUTIONS = 'UPDATE_SESSION_SOLUTIONS';
let UPDATE_SESSION_SOLUTIONS_PAGE = 'UPDATE_SESSION_SOLUTIONS_PAGE';
const SET_SEARCH_RECOMMENDATIONS = 'SET_SEARCH_RECOMMENDATIONS';
const CLEAN_SEARCH_RECOMMENDATIONS = 'CLEAN_SEARCH_RECOMMENDATIONS';
const SET_LIST_SEARCH = 'SET_LIST_SEARCH';
const CLEAN_LIST_SEARCH = 'CLEAN_LIST_SEARCH';
const SET_LOCAL_VOTE = 'SET_LOCAL_VOTE';
const SET_LIST_RECOMMENDATIONS = 'SET_LIST_RECOMMENDATIONS';
const SET_LIST_RECOMMENDATIONS_SCORES = 'SET_LIST_RECOMMENDATIONS_SCORES';

// actions
const PULL_LIST = 'PULL_LIST';
const PUSH_REPLY = 'PUSH_REPLY';
const REFRESH_SOLUTIONS = 'REFRESH_SOLUTIONS';
const PULL_SEARCH_RECOMMENDATIONS = 'PULL_SEARCH_RECOMMENDATIONS';
const PULL_SEARCH_RESULTS = 'PULL_SEARCH_RESULTS';
const PULL_LIST_RECOMMENDATIONS = 'PULL_LIST_RECOMMENDATIONS';

const solutionMapper = solution => {
  const { cachedVotes } = solution;

  return {
    ...solution,
    user: solution.userByUserId,
    cachedVotesUp: (cachedVotes && cachedVotes.up) || 0,
    cachedVotesDown: (cachedVotes && cachedVotes.down) || 0,
  };
};

export default {
  state: {
    current: [],
    sessionReplies: [],
    sessionSolutions: [],
    stat: [],
    searchRecommendations: [],
    listSearch: [],
    listRecommendationsToList: [],
    listRecommendationsToUser: [],
    listRecommendationsToUserScores: null
  },
  getters: {
    percentageCorrectOfQuestion: (state: any) => (questionId: any) => {
      let questionStat = state.stat.filter((item: any) => item.questionId == questionId)
      if (questionStat.length > 0) {
        return 100 * questionStat[0].correct / questionStat[0].total
      } return null
    },
    currentListData: (state: any) => state.current,
    listItems: (state: any) => {
      let list = state.current;
      let listItems = list.listItemsByListId ? list.listItemsByListId.nodes : [];
      return listItems.map((listItem: any) => {
        if (listItem.questionByQuestionId) {
          let question = listItem.questionByQuestionId;
          return {
            id: question.id,
            content: question.content,
            interactiveData: question.interactiveData,
            type: "Question"
          }
        } else if (listItem.articleByArticleId) {
          let article = listItem.articleByArticleId;
          return {
            id: article.id,
            title: article.title,
            content: article.content,
            type: "Article"
          }
        } else if (listItem.videoByVideoId) {
          let video = listItem.videoByVideoId;
          return {
            type: "Video",
            content: new VideoModel({
              id: video.id,
              providerId: video.providerId,
              providerType: video.providerType,
            }, {})
          }
        }
      });
    },
    listArticles: (state: any, getters: any) => {
      return getters.listItems.filter(
        (item: any) => item.type == "Article"
      )
    },
    listQuestions: (state: any, getters: any) => {
      return getters.listItems.filter(
        (item: any) => item.type == "Question"
      )
    },
    listVideos: (state: any, getters: any) => {
      return getters.listItems.filter(
        (item: any) => item.type == "Video"
      )
    },
    sessionQuestionReply: (state: any) => (questionId: string) => {
      let index = state.sessionReplies.map((reply: any) => reply.questionId).indexOf(questionId);
      if (index > -1) {
        return state.sessionReplies[index];
      } else {
        return false;
      }
    },
    sessionQuestionSolution: (state: any) => (questionId: string) => {
      let index = state.sessionSolutions.map((solution: any) => solution.questionId).indexOf(questionId);
      if (index > -1) {
        return state.sessionSolutions[index].solutions;
      } else {
        return false;
      }
    },
    listReplies: (state: any) => {
      let list = state.current;
      let listItems = list.listItemsByListId ? list.listItemsByListId.nodes : [];
      return listItems.map((listItem: any) => {
        if (listItem.articleByArticleId || listItem.videoByVideoId) {
          return false;
        }
        else if (listItem.questionByQuestionId) {
          let question = listItem.questionByQuestionId;
          let index = state.sessionReplies.map((sessionReplyMapped: any) => sessionReplyMapped.questionId).indexOf(question.id);
          if (index > -1) {
            let reply = state.sessionReplies[index];
            return {
              answer: reply.answer,
              correct_answer: reply.correct_answer,
              correct: reply.correct
            }
          } else {
            let replies = question.repliesByQuestionId;
            if (replies && replies.nodes.length > 0) {
              let reply = replies.nodes[0];
              let correct_answer = parseInt(reply.questionByQuestionId.answer);
              return {
                answer: parseInt(reply.answer),
                correct_answer: correct_answer,
                correct: parseInt(reply.answer) === correct_answer
              }
            } else {
              return false;
            }
          }
        }
      });
    },
    listAnswers: (state: any, getters: any, rootState: any) => {
      let answers: any = {
        "correct": [],
        "wrong": []
      }
      if (!state.current.listItemsByListId || !rootState.auth.user) {
        return answers
      }
      state.current.listItemsByListId.nodes.forEach((listItem: any) => {
        if (listItem.questionByQuestionId) {
          let question = listItem.questionByQuestionId;
          let index = state.sessionReplies.map((sessionReplyMapped: any) => sessionReplyMapped.questionId).indexOf(question.id);
          if (index > -1) { // sessionReply is not null
            let reply = state.sessionReplies[index];
            if (reply.correct) {
              answers.correct.push(listItem);
            }
            else {
              answers.wrong.push(listItem);
            }
          } else {
            // check if first or lastReply
            let lastReply = listItem.questionByQuestionId.repliesByQuestionId.nodes[0];
            if (lastReply) {
              if (parseInt(lastReply.answer) === parseInt(lastReply.questionByQuestionId.answer)) {
                answers.correct.push(listItem);
              }
              else {
                answers.wrong.push(listItem);
              }
            }
          }
        }
      });
      return answers;
    },
    listSearchResult: (state: any) => {
      return state.listSearch.reduce((acc, list, listIndex) => {
        const boards = acc.boards;
        if (listIndex === 0) acc.bestResult = list;
        if (!boards[list.boardTitle]) boards[list.boardTitle] = [];

        boards[list.boardTitle] = [...boards[list.boardTitle], list];

        return acc;
      }, { boards: {} });
    },
    listRecommendationsToCurrentUser: (state: any) => state.listRecommendationsToUser,
    hasRecommendedListToCurrentList: (state: any) => !!state.listRecommendationsToList[0],
    nextRecommendedListToCurrentList: (state: any) => state.listRecommendationsToList[0],
    hasRecommendedListToCurrentUser: (state: any) => !!state.listRecommendationsToUser[0],
    nextRecommendedListToCurrentUser: (state: any) => state.listRecommendationsToUser[0],
    votedByUser: (state: any) => (solution: any) => solution.currentUserVote && solution.currentUserVote.positive,
    voteHasChanged: (state: any, getters: any) => (questionId: number, solutionId: number) => {
      const originalQuestion = state.current.listQuestionsByListId.nodes.find(q => q.questionByQuestionId.id == questionId);
      const originalQuestionSolution = originalQuestion.questionByQuestionId.repliesByQuestionId.nodes[0].questionByQuestionId.solutionsByQuestionId.nodes.find(s => s.id == solutionId);
      const sessionSolutions = state.sessionSolutions.map(q => q.solutions.data).reduce((acc, s) => acc.concat(s), []);
      const sessionSolution = sessionSolutions.find(s => s.id == solutionId);

      return getters.votedByUser(originalQuestionSolution) !== getters.votedByUser(sessionSolution);
    },
  },
  mutations: {
    [SET_CURRENT_LIST](state: any, list: any) {
      if (list.materialsByListId && list.materialsByListId.nodes.length > 0) {
        list.board = list.materialsByListId.nodes[0].boardByBoardId;
        if (list.board.boardsClassroomsByBoardId && list.board.boardsClassroomsByBoardId.nodes.length > 0)
          list.classroom = list.board.boardsClassroomsByBoardId.nodes[0].classroomByClassroomId;
      }
      state.current = list;
    },
    [SET_CURRENT_LIST_STAT](state: any, stat: any) {
      state.stat = stat;
    },
    [UPDATE_SESSION_REPLIES](state: any, replyNode: any) {
      let reply = replyNode.data.createReply.reply;

      let sessionReply: any = {};
      sessionReply.answer = parseInt(reply.answer);
      sessionReply.correctAnswer = parseInt(reply.questionByQuestionId.answer);
      sessionReply.correct = parseInt(sessionReply.answer) === parseInt(sessionReply.correctAnswer);
      sessionReply.questionId = reply.questionId;
      let index = state.sessionReplies.map((sessionReplyMapped: any) => sessionReplyMapped.questionId).indexOf(sessionReply.questionId);
      if (index > -1) {
        state.sessionReplies.splice(index, 1);
      }
      state.sessionReplies.push(sessionReply);
    },
    [UPDATE_SESSION_SOLUTIONS](state: any, questionId: number) {
      const listQuestion = state.current.listQuestionsByListId.nodes.find(q => q.questionByQuestionId.id == questionId);
      const solutions = listQuestion.questionByQuestionId.repliesByQuestionId.nodes[0].questionByQuestionId.solutionsByQuestionId.nodes;
      
      const currentQuestionData = state.sessionSolutions.find(s => s.questionId === questionId);
      currentQuestionData.solutions.data = solutions.map(solutionMapper);
    },
    [UPDATE_SESSION_SOLUTIONS_PAGE](state: any, data: any) {
      const questionIndex = state.sessionSolutions.findIndex(s => s.questionId === data.questionId);

      const userHasSentSolution = questionIndex === -1
        ? data.userHasSentSolution
        : state.sessionSolutions[questionIndex].solutions.userHasSentSolution;

      const solutionData = {
        questionId: data.questionId,
        solutions: {
          userHasSentSolution,
          shownPage: data.shownPage,
          hasNextPage: data.hasNextPage,
          data: data.newSolutions.map(solutionMapper)
        }
      }
      
      if (questionIndex === -1) state.sessionSolutions.push(solutionData);
      else state.sessionSolutions.splice(questionIndex, 1, solutionData);
    },
    [SET_LIST_SEARCH](state: any, listSearch: any) {
      state.listSearch = listSearch;
    },
    [CLEAN_LIST_SEARCH](state: any) {
      state.listSearch = [];
    },
    [SET_SEARCH_RECOMMENDATIONS](state: any, searchRecommendations: any) {
      state.searchRecommendations = searchRecommendations;
    },
    [CLEAN_SEARCH_RECOMMENDATIONS](state: any) {
      state.searchRecommendations = [];
    },
    [SET_LOCAL_VOTE](state: any, vote: any) {
      let { solutionId, positive } = vote;
      const solutions = state.sessionSolutions.map(q => q.solutions.data).reduce((acc, s) => acc.concat(s), []);
      const solution = solutions.find(s => s.id == solutionId);
      const voteExists = !!solution.currentUserVote;

      if (voteExists) {
        const deleting = solution.currentUserVote.positive === positive;

        if (deleting) {
          solution.currentUserVote = null;

          if (positive) {
            solution.cachedVotesUp--
          } else {
            solution.cachedVotesDown--
          }
        } else {
          solution.currentUserVote = { ...solution.currentUserVote, positive };

          if (positive) {
            solution.cachedVotesUp++
            solution.cachedVotesDown--
          } else {
            solution.cachedVotesUp--
            solution.cachedVotesDown++
          }
        }
      } else {
        solution.currentUserVote = {
          positive
        }

        if (positive) {
          solution.cachedVotesUp++
        } else {
          solution.cachedVotesDown++
        }
      }
    },
    [SET_LIST_RECOMMENDATIONS](state: any, { listRecommendations, listId, boardId }: any) {
      if (listId && boardId) {
        state.listRecommendationsToList = listRecommendations;
      } else {
        state.listRecommendationsToUser = listRecommendations;
      }
    },
    [SET_LIST_RECOMMENDATIONS_SCORES](state: any, listRecommendations: any) {
      state.listRecommendationsToUserScores = listRecommendations.reduce((acc, recommendation) => {
        acc[recommendation.id] = recommendation.score;
        return acc;
      }, {});
    },
  },
  actions: {
    async [PULL_LIST]({ commit, rootState, dispatch, getters }: any, listId: any) {
      //TODO [maybe] this should be done via getter
      if (isNaN(listId)) {
        let listBySlugResponse = await query(`query getListBySlugResponse($slug: String!){
          allLists(condition: {
            slug: $slug
          }){
            nodes {
              id
            }
          }
        }`, {
          slug: listId
        });
        let listBySlug = listBySlugResponse.data.allLists.nodes;
        if (listBySlug && listBySlug[0]) {
          listId = listBySlug[0].id;
        } else {
          return false
        }
      }

      let userId = rootState.auth.user ? rootState.auth.user.id : null;
      let getReplies: string = "";
      if (userId) {
        getReplies = `
          repliesByQuestionId(
            orderBy: ID_DESC,
            filter: {
              and: [
                { userId: { equalTo: ${+userId}} },
                { listId: { equalTo: ${+listId}} }
              ]
            }) {
            nodes {
              positiveScore
              negativeScore
              answer
              questionByQuestionId {
                answer
              }
            }
          }
        `;
      }

      let response = await query(`query getListByIdResponse($listId: Int!) {
          listById(id: $listId) {
            id
            name
            slug
            description
            listQuestionsByListId: listItemsByListId(
              condition: {
                articleId: null
              }
              orderBy: POSITION_ASC
            ) {
              totalCount
              nodes{
                position
                questionByQuestionId{
                  id
                  content
                  interactiveData
                  ${getReplies}
                }
              }
            }
            listItemsByListId (
              orderBy: POSITION_ASC
            ) {
              totalCount
              nodes{
                position
                questionByQuestionId {
                  id
                  content
                  interactiveData
                  ${getReplies}
                }
                articleByArticleId {
                  id
                  title
                  content
                }
                videoByVideoId {
                  id
                  providerId
                  providerType
                }
              }
            }
            materialsByListId(filter: {
              boardId: {isNull: false}
            }) {
              nodes {
                boardByBoardId{
                  id
                  title
                  slug
                  boardsClassroomsByBoardId {
                    nodes {
                      classroomByClassroomId {
                        id
                        name
                        slug
                      }
                    }
                  }
                }
              }
            }

          }
          correctAnswersToQuestionInList(lid: $listId) {
            nodes {
              questionId
              correct
              total
            }
          }
        }`, {
        userId: userId,
        listId: +listId
      });

      if (!response.data.listById) {
        return false
      } else {
        commit(SET_CURRENT_LIST, response.data.listById);
        commit(SET_CURRENT_LIST_STAT, response.data.correctAnswersToQuestionInList.nodes);

        const board = response.data.listById.materialsByListId && response.data.listById.materialsByListId.nodes[0];
        const boardId = getters.currentBoard ? getters.currentBoard.id : board && board.id;

        dispatch(PULL_LIST_RECOMMENDATIONS, { listId, boardId });
        dispatch(PULL_LIST_RECOMMENDATIONS);
      }
    },
    async [PUSH_REPLY]({ commit, rootState, dispatch }: any, { listData, questionId, answer, isEnemQuestion }: any) {
      let userId = rootState.auth.user.id;
      let response = await mutation(`mutation CreateReply($listId: Int!, $questionId: Int!, $userId: Int!){
        createReply(
          input: {
            reply: {
              answer: "${answer}",
              questionId: $questionId,
              userId: $userId,
              listId: $listId
            }
          }
        ){
          reply{
            answer
            positiveScore
            negativeScore
            questionId
            questionByQuestionId {
              answer
            }
          }
        }
      }`, {
        listId: +listData.id,
        questionId,
        userId,
        answer: answer.toString()
      });

      await dispatch(REFRESH_SOLUTIONS, {
        questionId,
        first: isEnemQuestion ? null : 15,
        offset: 0
      });
      commit(UPDATE_SESSION_REPLIES, response);

      const userAnswer = response.data.createReply.reply.answer;
      const questionAnswer = response.data.createReply.reply.questionByQuestionId.answer;
      const answeredCorrectly = userAnswer === questionAnswer;

      track(dispatch)(TRACK_TYPES.EXERCISE_ANSWERED, {
        listName: listData.name,
        listSlug: listData.slug,
        title: listData.name,
        questionId,
        board: rootState.boards.board.title,
        discipline: rootState.boards.board.title,
        correct: answeredCorrectly,
      });

      trackHubspotEvent(
        'Fazer Exercicios',
        rootState.auth.user.email,
        { last_exercise_answering_moment: new Date().getTime() }
      );

      return answeredCorrectly;
    },
    async [REFRESH_SOLUTIONS](
      { commit, rootState }: any,
      params: { questionId: number, offset: number, first?: number }
    ) {
      const userId = rootState.auth.user?.id || null;
      let newSolutions;
      let userHasSentSolution = false;

      let response = await query(`
        query getSolutionsByQuestionId($questionId: Int!, $userId: Int!, $first: Int, $offset: Int!) {
          questionById(id: $questionId) {
            solutionsByQuestionId(first: $first, offset: $offset) {
              pageInfo {
                hasNextPage
              }
              nodes {
                id
                content
                cachedVotes {
                  up
                  down
                }
                updatedAt
                userByUserId {
                  id
                  name
                  role
                }
                validatedBy {
                  id
                  name
                  email
                }
                validationJudgment
                currentUserVote(currentUserId: $userId) {
                  positive
                }
              }
            }
          }
        }
      `, {
        questionId: params.questionId,
        userId,
        first: params.first || null,
        offset: params.offset
      });

      response = response.data.questionById.solutionsByQuestionId;

      if (params.first !== null && params.offset === 0) { // postgrad solutions first page
        let userSolution = await query(`
          query getUserSolution($questionId: Int!, $userId: Int!) {
            questionById(id: $questionId) {
              solutionsByQuestionId(filter: {
                userId: {
                  equalTo: $userId
                }
              }) {
                nodes {
                  id
                  content
                  cachedVotes {
                    up
                    down
                  }
                  updatedAt
                  userByUserId {
                    id
                    name
                    role
                  }
                  validatedBy {
                    id
                    name
                    email
                  }
                  validationJudgment
                }
              }
            }
          }`, {
          questionId: params.questionId,
          userId,
        });

        userSolution = userSolution.data.questionById.solutionsByQuestionId.nodes;

        if (userSolution.length > 0) userHasSentSolution = true;

        newSolutions = [
          ...response.nodes,
          ...userSolution
        ];
      }
      else if (params.first !== null && params.offset !== 0) { // postgrad solutions other pages
        newSolutions = response.nodes.filter((r: any) => r.userByUserId.id !== userId);
      }
      else { // ENEM
        newSolutions = response.nodes;

        const userSolution = newSolutions.find((r: any) => r.userByUserId.id === userId);
        userHasSentSolution = Boolean(userSolution);
      }

      commit(
        'UPDATE_SESSION_SOLUTIONS_PAGE',
        {
          questionId: params.questionId,
          userHasSentSolution,
          hasNextPage: response.pageInfo.hasNextPage,
          shownPage: params.offset ? ((params.offset / 15) + 1) : 1,
          newSolutions
        }
      );
    },
    async [PULL_SEARCH_RECOMMENDATIONS]({ commit }: any, searchTerm: string) {
      const response = searchRecommendations(searchTerm);
      commit(SET_SEARCH_RECOMMENDATIONS, response);
    },
    async [PULL_SEARCH_RESULTS]({ commit, rootState, getters }: any, searchTerm: string) {
      let classroomId = "";
      let currentClassroom = rootState.classrooms.currentClassroom;
      if (currentClassroom.id) {
        classroomId = "classroomId: " + currentClassroom.id;
      }
      const searchListsResponse = await query(
        `
          query getSearchListsResponse($searchTerm: String!) {
            getSimilarLists(searchText: $searchTerm, hasClassroomId: ${currentClassroom.id}, limit: 12) {
              id
              score
              name
              listInfo {
                id
                name
                slug
                description
                totalQuestions
                answersByUser {
                  correct
                  wrong
                }
                materialsByListId(filter: {
                  boardId: { isNull: false }
                }) {
                  nodes {
                    boardByBoardId {
                      id
                      title
                      slug
                      boardsClassroomsByBoardId {
                        nodes {
                          classroomByClassroomId {
                            id
                            name
                            slug
                          }
                        }
                      }
                    }
                  }

                }
              }
            }
          }
        `,
        { searchTerm }
      );
      const { getSimilarLists } = searchListsResponse.data;

      if (getSimilarLists.length === 0) {
        commit(SET_LIST_SEARCH, getSimilarLists);
        return;
      }

      const lists = getSimilarLists.map(sl => sl.listInfo);

      commit(SET_LIST_SEARCH, lists.map(list => {
        if (
          !list ||
          !list.materialsByListId ||
          !list.materialsByListId.nodes ||
          !list.materialsByListId.nodes.length
        ) {
          return null;
        }

        list.board = list.materialsByListId.nodes[0].boardByBoardId;

        if (list.board.boardsClassroomsByBoardId && list.board.boardsClassroomsByBoardId.nodes.length > 0) {
          list.classroom = list.board.boardsClassroomsByBoardId.nodes[0].classroomByClassroomId;
        }

        return {
          ...list,
          boardTitle: list.board.title,
          answersByUser: list.answersByUser || { correct: 0, wrong: 0 },
        };
      }).filter(l => l));
    },
    async [PULL_LIST_RECOMMENDATIONS]({ commit, rootState, dispatch }: any, { listId, boardId, withoutListInfo }: any = {}) {
      const withListInfo = !withoutListInfo;
      const listData = `
        id
        score
        ${!withListInfo
          ? ''
          : `
              listInfo {
                id
                name
                slug
                description
                materialsByListId(filter: {
                  boardId: {isNull: false}
                }) {
                  nodes {
                    boardByBoardId {
                      id
                      title
                      slug
                      boardsClassroomsByBoardId {
                        nodes {
                          classroomByClassroomId {
                            id
                            name
                            slug
                          }
                        }
                      }
                    }
                  }
                }
              }
            `
        }
      `;

      let response = await (
        (listId && boardId)
          ? query(
            `
              query PullListRecommendationsByListId($listId: Int!, $boardId: Int, $withoutListInfo: Boolean!) {
                listRecommendations(listId: $listId, boardId: $boardId, withoutListInfo: $withoutListInfo) {
                  ${listData}
                }
              }
            `,
            { listId, boardId, withoutListInfo: !!withoutListInfo }
          )
          : query(
            `
              query PullListRecommendationsByUserId($withoutListInfo: Boolean!) {
                listRecommendations(withoutListInfo: $withoutListInfo) {
                  ${listData}
                }
              }
            `,
            { withoutListInfo: !!withoutListInfo }
          )
      );

      if (withListInfo) {
        const listRecommendations = response.data.listRecommendations.filter(l => l.listInfo).map(l => {
          const boards = l.listInfo.materialsByListId.nodes.map(b => b.boardByBoardId);
          const board = boards[0];
          const classroom = board && board.boardsClassroomsByBoardId.nodes[0] && board.boardsClassroomsByBoardId.nodes[0].classroomByClassroomId;

          return {
            ...l.listInfo,
            score: l.score,
            board,
            classroom,
          };
        });

        commit(SET_LIST_RECOMMENDATIONS, { listRecommendations, listId, boardId });

        if (!rootState.lists.listRecommendationsToUserScores) {
          dispatch(PULL_LIST_RECOMMENDATIONS, { withoutListInfo: true });
        }
      } else {
        commit(SET_LIST_RECOMMENDATIONS_SCORES, response.data.listRecommendations);
      }
    },
  }
};























































































































import Vue from "vue";
import pxCard from "../../dex-shared/components/px-card.vue";
import ProgressBar from "../../dex-shared/components/ProgressBar.vue";
import {mapGetters, mapState} from "vuex";
import track, { TRACK_TYPES } from '../../dex-shared/helpers/track';

export default Vue.extend({
  components: {
    pxCard,
    ProgressBar
  },
  computed: {
    ...mapGetters({
      displayNameByAssertionRate: "displayNameByAssertionRate"
    }),
    ...mapState({
      classroom: (state: any) => state.classrooms.currentClassroom,
      stateClassrooms: (state: any) => state.classrooms
    })
  },
  props: ["list", "board", "isBestResult", "section"],
  data(){
    return {
      routePath: ""
    }
  },
  created() {
    if (this.classroom && this.board) {
      this.routePath = `/${this.classroom.slug}/${this.board.slug}/`
    } else if (this.list.materialsByListId){
      this.routePath = `/${this.classroom.slug}/${this.list.materialsByListId.nodes[0].boardByBoardId.slug}/`;
    }
    else {
      this.routePath = `/enem/matematica/`;
    }
  },
  methods: {
    listSection() {
      if (this.section) {
        return this.section;
      }
      if (this.board) {
        const boardPopularListsIds = this.board.popularListsWithNoSection ? this.board.popularListsWithNoSection.nodes.map(list => list.id) : [];
        const stoppedPopularListsIds = this.board.startedListsWithNoSection ? this.board.startedListsWithNoSection.nodes.map(list => list.id) : [];
        const finishedPopularListsIds = this.board.finishedListsWithNoSection? this.board.finishedListsWithNoSection.nodes.map(list => list.id) : [];
        if (boardPopularListsIds.includes(this.list.id)) {
          return "Popular Lists"
        } else if (stoppedPopularListsIds.includes(this.list.id)) {
          return "Stopped Lists"
        } else if (finishedPopularListsIds.includes(this.list.id)) {
          return "Finished Lists"
        }
      } else {
        const classroomStoppedListsIds = this.stateClassrooms.currentClassroomWithLists.stoppedLists ? this.stateClassrooms.currentClassroomWithLists.stoppedLists.nodes.map(list => list.id) : [];
        const classroomPopularListsIds = this.stateClassrooms.currentClassroomWithLists.popularLists ? this.stateClassrooms.currentClassroomWithLists.popularLists.nodes.map(list => list.id) : [];
        if (classroomStoppedListsIds.includes(this.list.id)) {
          return "Stopped Lists"
        } else if (classroomPopularListsIds.includes(this.list.id)) {
          return "Popular Lists"
        }
      }
    },
    onCardClicked() {
      /* 
        TODO: Missing result page case
      */
      const path = `${this.routePath}${this.list.slug}`
      const mpProperties = {
        list: this.list,
        board: this.board,
        classroom: this.classroom,
        section: this.listSection()
      };
      this.$track(TRACK_TYPES.LINK_NAVIGATED, mpProperties);
      this.$router.push(path);
    }
  }
});

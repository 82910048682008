




































































































































































import Vue from "vue";
import { mapGetters } from "vuex";
import Navbar from "./components/Navbar.vue";
import ImmersiveNavbar from "@/dex-shared/components/ImmersiveNavbar.vue";
import SidebarDesktop from "./components/sidebar/SidebarDesktop.vue";
import SidebarMobile from "./components/sidebar/SidebarMobile.vue";
import ModalsContainer from "@/dex-shared/components/ModalsContainer.vue";
import AdBlockModal from "@/dex-shared/components/AdBlockModal.vue";
import PostMortem from '@/components/PostMortem.vue';
import track, { TRACK_TYPES } from './dex-shared/helpers/track';
import { settingsFactory, SettingsProvidersType } from '@descomplica/settings-browser';

export default Vue.extend({
  components: {
    Navbar,
    SidebarDesktop,
    SidebarMobile,
    ModalsContainer,
    ImmersiveNavbar,
    PostMortem
  },

  computed: {
    ...mapGetters({
      userIsUsingAdBlock: 'userIsUsingAdBlock',
      userId: 'userId',
    }),
    focusPage: function() {
      return this.$route.params.list !== undefined || this.$route.path === "/entrar";
    },
    noSidebarPage() {
      return ['history'].includes(this.$route.name);
    },
    immersiveNavbar() {
      return ['history'].includes(this.$route.name);
    },
  },

  data: function() {
    return {
      sidebar: false,
      AdBlockModal,
      embedded: false
    }
  },

  methods: {
    toggleSidebar() {
      this.sidebar = !this.sidebar;
    },
    watchMobileResize() {
      if (window.innerWidth >= 1024) {
        this.sidebar = false;
      }
    },
    isPWAUser() {
      return window.matchMedia('(display-mode: standalone)').matches;
    }
  },

  async created() {
    if (this.userIsUsingAdBlock) {
      this.$track(TRACK_TYPES.SCREEN_VIEW, {
        screen: 'AdBlockModal'
      });
      this.$store.dispatch('OPEN_MODAL', { component: this.AdBlockModal });
    }

    if (this.$route.fullPath.split('/').includes("embedded")) {
      this.embedded = true;
    }

    if(this.$route.params.listId){
      this.$store.dispatch("PULL_LIST", this.$route.params.listId);
    }
    let response = await this.$store.dispatch("PULL_CLASSROOM", this.$route.params.classroom);

    if(response === false) {
      this.$router.push('/404');
    }

    const config = {
      clientSideId: process.env.VUE_APP_LAUNCHDADRKLY_CLIENT_SIDE_ID,
      user: {
        id: this.$store.state.auth.user.descomplicaId,
        key: this.$store.state.auth.user.descomplicaId,
        name: this.$store.state.auth.user.name,
        email: this.$store.state.auth.user.email
      }
    };
    const settings = await settingsFactory.create([SettingsProvidersType.LAUNCHDARKLY], config);
    const showCookieWarning = await settings.get('lgpd-alert-script', false);

    if(showCookieWarning){
      const cookieWarning = document.createElement('script');    
      cookieWarning.setAttribute('type',"text/javascript");
      cookieWarning.setAttribute('src',"https://flowpress-production.s3.sa-east-1.amazonaws.com/wp-content/uploads/2021/08/18174720/lgpd-cookies-alertmin.js");
      cookieWarning.defer = true;
      document.head.appendChild(cookieWarning);
    }
  },

  mounted() {
    this.$nextTick(() => {
      window.addEventListener('resize', this.watchMobileResize.bind(this))
    });

    if (this.userId) {
      this.$store.dispatch("PULL_LIST_RECOMMENDATIONS");
    }
  },

  beforeDestroy() {
    window.removeEventListener('resize', this.watchMobileResize);
  },

  watch: {
    userId(newValue) {
      if (newValue) {
        this.$store.dispatch("PULL_LIST_RECOMMENDATIONS");
      }
    }
  }
});












































































































































































































import Vue from 'vue';
import { mapState, mapGetters } from "vuex";
import MagnifyIcon from "vue-material-design-icons/Magnify.vue";
import CloseIcon from "vue-material-design-icons/Close.vue";
import MenuIcon from "vue-material-design-icons/Menu.vue";

export default Vue.extend({
  components: {
    MagnifyIcon,
    CloseIcon,
    MenuIcon
  },

  props: {
    isMobileSearch: {
      type: Boolean,
      default: false,
    }
  },

  computed: {
    ...mapState({
      searchRecommendations: (state: any) => state.lists.searchRecommendations.slice(0, 3),
    }),
    ...mapGetters({
      listSearchResult: "listSearchResult",
      classroom: "currentClassroom"
    }),
  },
  
  data() {
    return {
      searchTerm: decodeURIComponent(this.$route.query.q as string || ''),
      debounce: null,
      hasSearched: !!this.$route.query.q,
    }
  },

  watch: {
    '$route': function () {
      this.searchTerm = this.$route.query.q as string || '';
      if (!this.$route.query.q) {
        this.$store.commit("CLEAN_SEARCH_RECOMMENDATIONS");
      }
    }
  },

  methods: {
    resetSearch() {
      this.searchTerm = '';
      this.$store.commit("CLEAN_SEARCH_RECOMMENDATIONS");
      this.updateContent();
    },
    showRecommendationsBox() {
      if (!this.searchTerm) return false;

      return !this.hasSearched && (!!this.searchRecommendations.length || !!this.searchTerm);
    },
    showRecommendations() {
      return !!this.searchTerm && !!this.searchRecommendations.length && !this.isSearchTermInRecommendations();
    },
    isSearchTermInRecommendations() {
      return this.searchRecommendations.find(sr => sr.searchTerm === this.searchTerm);
    },
    updateContent: function() {
      this.$emit("input", { searchTerm: this.searchTerm, debounce: this.debounce, hasSearched: this.hasSearched });
    },
    updateSearch(searchTerm: string) {
      this.hasSearched = false;
      this.searchTerm = searchTerm;
      this.updateContent();

      clearTimeout(this.debounce);
      this.debounce = setTimeout(() => {
        this.$store.dispatch("PULL_SEARCH_RECOMMENDATIONS", this.searchTerm);
      }, 100);
    },
    searchLists() {
      this.hasSearched = true;
      const searchURL = `/${this.classroom.slug}/busca?q=${encodeURIComponent(this.searchTerm)}`;
      
      this.$router.push(searchURL);
      this.cancelMobileSearch();
    },
    cancelMobileSearch() {
      this.$emit('cancelMobileSearch');
    },
    toggleMobileSidebar() {
      this.$emit('toggleMobileSidebar');
    },
    isSearchPage() {
      return this.$route.name === 'search';
    }
  },

  mounted() {
    if (this.isMobileSearch) {
      this.$refs.searchInput.focus();
    }
  }
});

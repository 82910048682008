// let STORE_TESTS = {
//     "preguica": "A",
//     "aluninho": "B",
//     "imagemDaHomepage": "A"
// }

// import ListCarousel from "@/components/list/ListCarousel.vue";

import animacaoAluninho from "@/dex-shared/components/investigation/aluninho-data.json";
import animacaoPreguicinha from "@/dex-shared/components/investigation/preguicinha-data.json";

export default {
  animacaoDaInvestigation: {
    data: {
      A: { element: animacaoAluninho, ratio: 0.5 },
      B: { element: animacaoPreguicinha, ratio: 0.5 }
    }
  }
};

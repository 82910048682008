












import ContentComponent from "./ContentComponent.vue";
export default {
  components: {
    ContentComponent
  },
  props: ["content", "context", "trustedContent"],
  computed: {
    lengthOfQuestion: function() {
      let accumulator = 0;
      for (let i = 0; i < this.content.length; i++) {
        let current = this.content[i];
        if (current && current.behavior == "text") {
          accumulator += current.content.length;
        } else {
          return 241; // questions that are not pure text are large questions
        }
      }
      return accumulator;
    }
  },
  methods: {
    questionSize() {
      if (this.context != "question") {
        return "";
      }
      if (this.lengthOfQuestion > 240) {
        return "question-large";
      }
      if (this.lengthOfQuestion > 140) {
        return "question-medium";
      }
      return "question-small";
    }
  }
};























































































































































import Vue from "vue";
import ListCarousel from "@/components/list/ListCarousel.vue";
import LandingBanner from "@/components/LandingBanner.vue";
import DexPWABanner from "@/components/pwa/DexPWABanner.vue";
import Streak from "@/components/streak/Streak.vue";
import pxButton from "@/dex-shared/components/px-button.vue";
import { mapState, mapGetters } from 'vuex';
import { watch } from 'fs';
import StreakNoListDone from '@/components/StreakNoListDone.vue';
import StreakNoUser from '@/components/StreakNoUser.vue';
import StreakMobile from '@/components/streak/StreakMobile.vue';
import track, { TRACK_TYPES } from '@/dex-shared/helpers/track';

export default Vue.extend({
  components: {
    ListCarousel,
    LandingBanner,
    Streak,
    StreakMobile, 
    StreakNoListDone,
    StreakNoUser,
    pxButton,
    DexPWABanner
  },
  data() {
    return {
      loadingPopularLists: false
    }
  },
  computed: {
    ...mapState({
      user: (state:any) => state.auth.user,
      classroom: (state: any) => state.classrooms.currentClassroomWithLists,
      currentClassroomId: (state: any) => state.classrooms.currentClassroom.id
    }),
    ...mapGetters({
      popularLists: 'popularLists',
      currentClassroom: 'currentClassroom',
      todayStreak: 'todayStreak'
    }),
    shouldShowStreak() {
      return this.todayStreak && this.todayStreak.completedLists > 0
    },
  },
  methods: {
    fixDeprecatedRoute() {
      if(this.$route.name === 'deprecated_classroom')
        this.$router.replace(`/${this.$route.params.classroom}`)
    },
    async goToRandomList() {
      this.loadingPopularLists = true;
      await this.$store.dispatch('PULL_POPULAR_LISTS');
      this.loadingPopularLists = false;
      const listRecommendations = this.popularLists;
      const randomListIndex = Math.floor(Math.random() * listRecommendations.length);
      const chosenList = listRecommendations[randomListIndex];
      const routePath = `/${this.currentClassroom.slug}/${chosenList.board.slug}/${chosenList.slug}/`;
      this.$track(TRACK_TYPES.LINK_NAVIGATED, {
          classroom: this.currentClassroom,
          label: "Random List"
      });
      this.$router.push(routePath + chosenList.id);
    }
  },
  created: async function() {
    // Deprecated route
    this.fixDeprecatedRoute();
    if (this.user && this.user.id) {
      await this.$store.dispatch('PULL_STREAK');
    }
    this.$store.commit("SET_CURRENT_BOARD_ID", null);
    document.title = 'Descomplica Exercícios';
    let description = document.getElementsByName('description')[0] as HTMLMetaElement;
    description.content = this.classroom.description
  },
  watch: {
    classroom(newValue, oldValue) {
      if (newValue.error) {
        this.$router.push('/404')
      } else {
        let description = document.getElementsByName('description')[0] as HTMLMetaElement;
        description.content = this.classroom.description
      }
    },
  },
  beforeRouteEnter (to, from, next) {
    next(vm => {
      vm.$store.dispatch("PULL_CLASSROOM_WITH_LISTS", to.params.classroom)
    });
  },
});

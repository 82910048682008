import MixpanelTracker from "./MixpanelTracker";
import { Mixpanel } from './interfaces';

/* 
    It's responsible to provide application tracking 
    interface.
*/

class TrackingEvents {
    mixpanel: Mixpanel
    storeState: Object
    constructor (storeState) {
        this.storeState = storeState
        this.mixpanel = new MixpanelTracker(storeState);
    }

    private isValid(payload) {
        const keys = Object.keys(payload);
        return keys.includes("eventName") && typeof payload == "object";
    }

    track(payload) {
        if (this.isValid(payload)) {
            this.mixpanel.track(payload);
        }
    }
}

export default TrackingEvents;
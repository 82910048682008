

















































































import Vue from "vue";
import { mapState } from "vuex";
import ArrowBackIcon from "vue-material-design-icons/ArrowLeft.vue";
import Sidebar from "./Sidebar.vue";
import Feedback from "../Feedback.vue";
import BackToDescomplica from '../BackToDescomplica.vue';
import DexLogoIcon from "../../dex-shared/components/icons/DexLogo.vue";

export default Vue.extend({
  components: {
    ArrowBackIcon,
    Sidebar,
    Feedback,
    BackToDescomplica,
    DexLogoIcon
  },
  watch: {
    '$route' (to, from) {
      this.$emit('toggle-sidebar')
    }
  },
  computed: {
    ...mapState({
      user: (state: any) => state.auth.user
    })
  }
});











































































































































































import Vue from "vue";
import pxButton from "./px-button.vue";

export default Vue.extend({
  components: {
    pxButton
  },

  props: {
    id: {
      type: Number,
      required: true
    }
  },

  data() {
    return {};
  },

  methods: {
    refresh() {
      document.location.reload();
    }
  }
});

import TrackingEvents from '@/dex-shared/models/Tracking/TrackingEvents';
import { TRACK_TYPES, injectPayloadMetadata } from '@/dex-shared/helpers/track';

export default store => {
    const TRACK = "TRACK";
  store.subscribeAction((action, rootState) => {
    const { type, payload } = action;
    if (type == TRACK) {
      switch (payload.actionName) {
        case TRACK_TYPES.LIST_STARTED:
          return new TrackingEvents(rootState).track(
            injectPayloadMetadata(payload, { eventName: TRACK_TYPES.LIST_STARTED })
          );
        case TRACK_TYPES.LIST_CONTINUED:
            return new TrackingEvents(rootState).track(
              injectPayloadMetadata(payload, { eventName: TRACK_TYPES.LIST_CONTINUED })
            );
        case TRACK_TYPES.LIST_CONSUMED:
          return new TrackingEvents(rootState).track(
            injectPayloadMetadata(payload, { eventName: TRACK_TYPES.LIST_CONSUMED })
          );
        case TRACK_TYPES.EXAM_CONSUMED:
          return new TrackingEvents(rootState).track(
            injectPayloadMetadata(payload, { eventName: TRACK_TYPES.EXAM_CONSUMED })
          );
        case TRACK_TYPES.EXAM_EXIT_INTENTION:
          return new TrackingEvents(rootState).track(
            injectPayloadMetadata(payload, { eventName: TRACK_TYPES.EXAM_EXIT_INTENTION })
          );
        case TRACK_TYPES.EXAM_STARTED:
          return new TrackingEvents(rootState).track(
            injectPayloadMetadata(payload, { eventName: TRACK_TYPES.EXAM_STARTED })
          );
        default:
          return;
      }
    }
  });
};
























































































































































































































import Vue from 'vue'
import pxButton from "@/dex-shared/components/px-button.vue";
import track, { TRACK_TYPES } from '@/dex-shared/helpers/track';
export default Vue.extend({
    components: {
        pxButton
    },
    props: {
        ctaCaller: {
            type: Function,
            required: true
        },
        loadingCallerData: {
            type: Boolean,
            required: true
        }
    },
})













import { mapGetters } from "vuex";
import Content from "../../dex-shared/components/content/Content.vue";
import SendReport from "../../dex-shared/components/report/SendReport.vue";

export default {
  components: {
    Content,
    SendReport
  },

  props: ["article"],
};

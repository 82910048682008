























import Vue from "vue";
import Content from "../../../dex-shared/components/content/Content.vue"; 
import { mapGetters, mapActions, mapState } from "vuex";

export default Vue.extend({
  components: {
    Content
  },
  data: function() {
  return {
      answerWasSent: false,
    };
},
  props: ["interactiveData", "question", "onAnswer", "abTestVariant"],
  computed: {
    ...mapState({
      user: (state:any) => state.auth.user
    }),
    ...mapGetters({
      reply: "sessionQuestionReply",
    })
  },
  methods: {
    ...mapActions({
      pullQuestionSolutions: "PULL_QUESTION_SOLUTIONS",
      pushInvestigationResponse: 'PUSH_INVESTIGATION_RESPONSE'
    }),
    redirectToLogin() {
      this.$router.push('/entrar')
    },
    sendAnswer(index) {
      if(!this.answerWasSent) {
        this.onAnswer(index);
        this.answerWasSent = true;
      }
      return;
    }
  }
});

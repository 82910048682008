






































































































































import Vue from "vue";
import ListsSection from "../components/ListsSection.vue";
import BoardDonutChart from "../components/board/BoardDonutChart.vue";
import Loading from "@/dex-shared/components/Loading.vue";
import LevelDropDown from "@/components/LevelDropDown.vue";
import { mapGetters, mapState } from 'vuex';

export default Vue.extend({
  components: {
    ListsSection,
    BoardDonutChart,
    Loading,
    LevelDropDown
  },
  data: function() {
    return {
      loaded: false
    }
  },
  computed: {
    ...mapGetters({
      classroom: "currentClassroom",
      displayNameBy: "displayNameByLevelName",
      levelNames: "levelNames"
    }),
    ...mapState({
      selectedLevels: (state: any) => state.levels.selectedLevels,
      board: (state: any) => state.boards.board
    }),
    statText: function(): string {
      if (this.board.overallAssertionRate > 75) {
        return `Você está arrasando!`;
      }
      if (this.board.overallAssertionRate > 50) {
        return `Você está acima da média`;
      }
      if (this.board.overallAssertionRate > 25) {
        return `Você pode melhorar` ;
      }
      return `Você tem que dar um gás`;
    },
    subsections(): any[]{
      return [{
        title: "Estou fazendo",
        text: `Você ainda não está fazendo nenhuma lista de ${this.board.title}`,
        c2a: "Que tal terminar essas?",
        carousel: "Termine as listas",
        sections: this.board.stoppedSectionsBy(this.selectedLevels),
        listsWithoutSections: this.board.stoppedListsWithoutSectionBy(this.selectedLevels)
      },{
        title: "Não comecei",
        text: `Não existem mais listas disponíveis de ${this.board.title}`,
        c2a: "Comece a estudar agora:",
        carousel: "Comece com",
        sections: this.board.popularSectionsBy(this.selectedLevels),
        listsWithoutSections: this.board.popularListsWithoutSectionBy(this.selectedLevels)
      },{
        title: "Já terminei",
        text: `Você ainda não terminou nenhuma lista de ${this.board.title}`,
        carousel: "Faça de novo",
        sections: this.board.finishedSectionsBy(this.selectedLevels),
        listsWithoutSections: this.board.finishedListsWithoutSectionBy(this.selectedLevels)
      }]
    },
  },
  methods: {
    async changeId(route: any) {
      this.loaded = false;
      let response = await this.$store.dispatch("PULL_BOARD_WITH_BOARD_SECTIONS_BY_BOARD_WITH_LISTS", {boardId: route, batchForEachSection: 12}); 

      // Deprecated routes
      await this.fixDeprecatedRoute();
      
      document.title = `Exercícios - ${this.board.title || this.board.title}`

      this.loaded = true;
    },
    selectedLevelsDisplayStatus(selectedLevels) {
      if (selectedLevels.length == 3) {
        return "todas";
      } else if (selectedLevels.length == 1) {
        return this.displayNameBy(selectedLevels[0]);
      } else {
        return `${this.displayNameBy(selectedLevels[0])} e ${this.displayNameBy(selectedLevels[1])}`;
      }
    },
    async fixDeprecatedRoute() {
      if(this.$route.name === 'deprecated_board_classroom') {
        this.$router.replace(`/${this.$route.params.classroom}/${this.$route.params.board}`);
      }
      else if (this.$route.name === 'deprecated_board'){
        this.$router.replace(`/${this.board.classroom.slug}/${this.$route.params.board}`);
        this.$store.dispatch("PULL_CLASSROOM", this.board.classroom.slug);
      }
    }
  },
  async created() {
    this.changeId(this.$route.params.board);  
  },
  // called when link is to another board
  beforeRouteUpdate(to, from, next) {
    this.changeId(to.params.board);
    next();
  }
});

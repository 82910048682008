

















import Vue from "vue";

export default Vue.extend({
  props: {
    margin: {
      type: String,
      default: "0"
    },
    color: {
      type: String,
      default: "#E0E0E0"
    },
    size: {
      type: Number,
      default: 1
    },
    width: {
      type: String,
      default: "auto"
    }
  }
});
























































import Vue from "vue";

export default Vue.extend({
  props: {
    top: {
      type: Number,
      default: null
    },
    left: {
      type: Number,
      default: null
    },
    darkMode: {
      type: Boolean,
      default: false
    },
    maxWidth: {
      type: Number,
      default: 362
    }
  },
  computed: {
    position() {
      if (this.top || this.left) {
        return {
          position: 'absolute',
          top: `${this.top + 8}px`,
          left: `${this.left - this.maxWidth}px`,
          maxWidth: `${this.maxWidth}px`
        }
      } else {
        return {}
      }
    }
  }
});












































































































































































































import Vue from "vue";
import ListCard from "./list/ListCard.vue";
import ListCarousel from "./list/ListCarousel.vue";
import TabMenu from '@/dex-shared/components/TabMenu.vue';
import { mapState } from 'vuex';
import track, { TRACK_TYPES } from '../dex-shared/helpers/track';

export default Vue.extend({
  components: {
    ListCard,
    ListCarousel,
    TabMenu
  },

  props: ["board", "subsections", "selectedLevels"],

  data: function() {
    return {
      active: 1
    };
  },

  computed: {
    ...mapState({
      board_sections: (state:any) => state.boards.boardSections
    }),
    listsInSection: function(){
      let subsection = this.subsections[this.active], numberOfLists = subsection.listsWithoutSections.length;
      for (let section of subsection.sections) {
        numberOfLists += section.lists.length;
      }
      return numberOfLists > 0;
    },
  },

  methods: {
    makeActive(i: number) {
      this.$track(TRACK_TYPES.SCREEN_VIEW, {
        sections: [this.subsections[i].title],
        screen: "Board",
        screenId: this.board.title
      });
      this.active = i;
    }
  },
});





























































import Vue from 'vue';
import pxButton from '@/dex-shared/components/px-button.vue';
import DexPWAModal from './DexPWAModal.vue';
import track, { TRACK_TYPES } from '../../dex-shared/helpers/track';

export default Vue.extend({
  components: {
  	pxButton,
  },

  data() {
		return {
      DexPWAModal,
		}
  },

  methods: {
    openPWAModal() {
      this.$track(TRACK_TYPES.SCREEN_VIEW, {
        screen: 'DexPWAModal'
      });
      this.$store.dispatch('OPEN_MODAL', { component: this.DexPWAModal });
    }
  }
})
























































































































































































































































































































import Vue from "vue";
import { mapState, mapGetters } from "vuex";
import MenuUpIcon from "vue-material-design-icons/MenuUp.vue";
import MenuDownIcon from "vue-material-design-icons/MenuDown.vue";
import CloseIcon from "vue-material-design-icons/Close.vue";
import Loading from "@/dex-shared/components/Loading.vue";
import pxModal from "@/dex-shared/components/px-modal.vue";
import HorizontalRuler from "@/dex-shared/components/HorizontalRuler.vue";
import NoActivityBanner from "./NoActivityBanner.vue";
import ProgressBarChart from "./ProgressBarChart.vue";
import BoardDonutChart from "../board/BoardDonutChart.vue";

export default Vue.extend({
  components: {
    Loading,
    NoActivityBanner,
    HorizontalRuler,
    MenuDownIcon,
    MenuUpIcon,
    CloseIcon,
    BoardDonutChart,
    ProgressBarChart,
    pxModal,
  },

  computed: {
    ...mapState({
      currentClassroom: (state: any) => state.classrooms.currentClassroom,
      boardsReplies: (state: any) => state.history.boardsReplies,
      totalDaysDoingExercises: (state: any) => state.history.totalDaysDoingExercises,
    }),
    ...mapGetters({
      userId: 'userId'
    })
  },

  data() {
    return {
      loaded: false,
      showLegend: false,
      chartBoardId: null,
      customSelectOpen: false,
    };
  },

  methods: {
    async pullData() {
      this.loaded = false;
      
      await Promise.all([
        this.$store.dispatch('PULL_BOARDS_REPLIES', this.currentClassroom.slug),
        this.$store.dispatch('PULL_TOTAL_DAYS_DOING_EXERCISES'),
      ]);

      this.loaded = true;
    },
    listsTotalMessage(board) {
      if (!board.totalLists) return '0 listas';

      return `${board.totalLists} lista${board.totalLists > 1 ? 's' : ''} no total`; 
    },
    showNoActivityBanner() {
      return !this.boardsReplies.reduce((acc, board) => acc + board.boardReplies.total, 0);
    },
    showProgressBarChart() {
      const board = this.boardsReplies.find(b => b.id == this.chartBoardId);
      if (!board) return false;

      return !!board.allReplies.length;
    },
    toggleLegend() {
      this.showLegend = !this.showLegend;
    },
    totalLists() {
      return this.boardsReplies.reduce((acc, board) => acc + board.totalLists, 0);
    },
    totalListsMessage() {
      return (this.totalLists() === 1) ? 'lista feita' : 'listas feitas';
    },
  },

  watch: {
    currentClassroom() {
      if (this.currentClassroom.slug) this.pullData();
    },
    boardsReplies() {
      this.chartBoardId = this.boardsReplies[0] && this.boardsReplies[0].id;
    },
  },

  beforeMount() {
    if (!this.userId) this.$router.push(`/${this.$route.params.classroom}`);
  },

  async mounted() {
    if (this.currentClassroom.slug) this.pullData();
  },
});
















































































































































import Vue from 'vue';
import { mapGetters } from 'vuex';
import DateProvider from '../../models/DateProvider';
import moment from 'moment';
import _ from 'lodash';

moment.locale('pt-br');

const LAST_WEEKS_SHOWN = 7;

export default Vue.extend({
	props: ['boardId'],

	data() {
		return {
			width: 750,
			height: 280,
			yAxis: [0, 25, 50, 75, 100],
			xAxisMarginLeft: 0,
			xAxisTextMarginLeft: 70,
			verticalMargin: 120,
			barWidth: 8,
			tooltipBox: {
				opacity: 0,
				x: 0,
				y: 5,
				width: 120,
				height: 30
			},
			tooltipText: {
				opacity: 0,
				x: 0,
				y: 0,
				vHtml: ''
			},
			tooltipDetail: {
				opacity: 0,
				x: 0,
				y: 23,
				width: 20,
				height: 20,
				transform: ''
			}
		};
	},

	computed: {
		currentBoardReplies() {
			return this.$store.state.history.boardsReplies.find(
				b => b.id == this.boardId
			).allReplies;
		},
		repliesGroupedByWeek() {
			const repliesGroupedObj = this.currentBoardReplies.reduce(
				(acc, reply) => {
					const replyDate = new DateProvider({
						date: reply.createdAt
					});
					const weekOfTheYear = replyDate.weekOfTheYear;
					const weekData = replyDate.weekRange();

					if (!acc[weekOfTheYear]) {
						acc[weekOfTheYear] = {
							...acc[weekOfTheYear],
							weekOfTheYear,
							...weekData,
							totalCount: 1,
							correctCount: reply.correct ? 1 : 0,
							year: replyDate.year,
							isLastWeek: replyDate.isLastWeek,
							isCurrentWeek: replyDate.isCurrentWeek
						};
					} else {
						acc[weekOfTheYear].totalCount += 1;
						acc[weekOfTheYear].correctCount += reply.correct
							? 1
							: 0;
					}

					return acc;
				},
				{}
			);
			const groupedRepliesKeys = Object.keys(repliesGroupedObj);

			return _.orderBy(
				groupedRepliesKeys.map(k => repliesGroupedObj[k]),
				['year', 'weekOfTheYear'],
				['asc', 'asc']
			).slice(-LAST_WEEKS_SHOWN);
		}
	},

	methods: {
		yAxisY(percentage) {
			return (
				this.height -
				((percentage / 100) * (this.height - this.verticalMargin) +
					(this.verticalMargin - 50))
			);
		},
		xAxisX(index) {
			const marginLeft = this.xAxisTextMarginLeft;
			return (
				marginLeft +
				index * ((this.width - 2 * marginLeft) / (LAST_WEEKS_SHOWN - 1))
			);
		},
		xAxisY(index) {
			return this.height - 60 + index * 14;
		},
		xAxisText(replies) {
			if (replies.isCurrentWeek) return ['Esta', 'semana'];
			if (replies.isLastWeek) return ['Semana', 'passada'];

			return [replies.startOfWeek, `a ${replies.endOfWeek}`];
		},
		correctRatio(replies) {
			return replies.correctCount / replies.totalCount;
		},
		mouseoverBar(replies, repliesIndex) {
			this.tooltipBox = {
				...this.tooltipBox,
				opacity: 1,
				x: this.xAxisX(repliesIndex) - this.tooltipBox.width / 2
			};

			const tooltipDetailX =
				this.xAxisX(repliesIndex) - this.tooltipDetail.width / 2;

			this.tooltipDetail = {
				...this.tooltipDetail,
				opacity: 1,
				x: tooltipDetailX,
				transform: `rotate(45, ${tooltipDetailX +
					this.tooltipDetail.width / 2}, ${this.tooltipDetail.y +
					this.tooltipDetail.height / 2})`
			};

			this.tooltipText = {
				...this.tooltipText,
				opacity: 1,
				x: this.xAxisX(repliesIndex),
				y: this.tooltipBox.y + this.tooltipBox.height / 2,
				vHtml: `${(+this.correctRatio(replies) * 100).toFixed(
					0
				)}% de acerto`
			};
		},
		mouseleaveBar() {
			this.tooltipBox = {
				...this.tooltipBox,
				opacity: 0
			};

			this.tooltipDetail = {
				...this.tooltipDetail,
				opacity: 0
			};

			this.tooltipText = {
				...this.tooltipText,
				opacity: 0
			};
		}
	}
});

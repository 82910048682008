














































import Vue from "vue";
import { mapGetters, mapState } from "vuex";

export default Vue.extend({
  computed: {
    ...mapState({
      modals: (state: any) => state.modals.modals
    })
  },
  methods: {
    closeModal(id: number) {
      this.$store.dispatch("CLOSE_MODAL", id);
    }
  }
});

import store from '../store';

class Board {
    id: any
    title: any
    slug: any
    replies: any
    lists: any
    sections: any
    levels: any
    popularListsWithNoSection: any
    finishedListsWithNoSection: any
    startedListsWithNoSection: any
    constructor({data}) {
        this.id = data.id;
        this.title = data.title;
        this.slug = data.slug;
        this.replies = data.replies;
        this.sections = data.boardSectionsByBoardId;
        this.levels = { "EASY": { minimum: 0.75, maximum: Infinity}, "MEDIUM": { minimum: 0.5, maximum: 0.75}, "HARD": { minimum: 0, maximum: 0.5 } };
        this.popularListsWithNoSection = data.popularLists;
        this.finishedListsWithNoSection = data.finishedLists;
        this.startedListsWithNoSection = data.stoppedLists;
    }

    private filterByLevel(elements, level) {
        return elements.filter( element => element.assertionRate >= level.minimum && element.assertionRate < level.maximum );
    }

    private splitListsForLevels(elements, selectedLevels) {
        let listsSplittedIntoLevels = {}, listsBySelectedLevels = [];
        for (let level in this.levels) {
            listsSplittedIntoLevels[level] = this.filterByLevel(elements, this.levels[level]);
        }
        for (let selectedLevel of selectedLevels) {
            listsBySelectedLevels = [ ...listsBySelectedLevels, ...listsSplittedIntoLevels[selectedLevel] ];
        }
        return listsBySelectedLevels;
    }

    private sortListsByScore(lists) {
        const scores = store.state.lists.listRecommendationsToUserScores;
        if (!scores) return lists;

        return [...lists].sort((a, b) => (scores[b.id] || 0) - (scores[a.id] || 0));
    }

    get overallAssertionRate() {
        if (this.replies) {
            return (100 * parseInt(this.replies.correct)) / parseInt(this.replies.totalReplies);
        } else {
            return 0;
        }
    }

    get totalRepliesCount() {
        if (this.replies) {
            return parseInt(this.replies.totalReplies);
        } else {
            return 0;
        }
    }

    get correctRepliesCount() {
        if (this.replies) {
            return parseInt(this.replies.correct);
        } else {
            return 0;
        }
        
    }

    get wrongRepliesCount() {
        if (this.replies) {
            return parseInt(this.replies.wrong);
        } else {
            return 0;
        }
    }

    popularSectionsBy(levels) {
        if (this.sections && this.sections.nodes.length > 0) {
            return this.sections.nodes.map(section => {
                if (section.popularLists && section.popularLists.nodes.length > 0) {
                    return {
                        id: section.id,
                        title: section.title,
                        lists: this.splitListsForLevels(this.sortListsByScore(section.popularLists.nodes), levels)
                    };
                } else {
                    return {
                        id: section.id,
                        title: section.title,
                        lists: []
                    };
                }
            });
        } else {
            return [];
        }
        
    }

    finishedSectionsBy(levels) {
        if (this.sections && this.sections.nodes.length > 0) {
            return this.sections.nodes.map(section => {
                if (section.finishedLists && section.finishedLists.nodes.length > 0) {
                    return {
                        id: section.id,
                        title: section.title,
                        lists: this.splitListsForLevels(this.sortListsByScore(section.finishedLists.nodes), levels)
                    };
                } else {
                    return {
                        id: section.id,
                        title: section.title,
                        lists: []
                    };
                }
            });
        } else {
            return [];
        }
    }

    stoppedSectionsBy(levels) {
        if (this.sections && this.sections.nodes.length > 0) {
            return this.sections.nodes.map(section => {
                if (section.stoppedLists && section.stoppedLists.nodes.length > 0) {
                    return {
                        id: section.id,
                        title: section.title,
                        lists: this.splitListsForLevels(this.sortListsByScore(section.stoppedLists.nodes), levels)
                    };
                } else {
                    return {
                        id: section.id,
                        title: section.title,
                        lists: []
                    };
                }
            });
        } else {
            return [];
        }
    }

    popularListsWithoutSectionBy(levels) {
        return this.splitListsForLevels(this.popularListsWithNoSection.nodes, levels);
    }

    finishedListsWithoutSectionBy(levels) {
        return this.splitListsForLevels(this.finishedListsWithNoSection.nodes, levels);
    }

    stoppedListsWithoutSectionBy(levels) {
        return this.splitListsForLevels(this.startedListsWithNoSection.nodes, levels);
    }

    pickRandomListFrom(lists) {
        return lists[Math.floor(Math.random() * lists.length)];
    }

    randomListWithoutSectionBy(levels) {
        let lists = this.popularListsWithoutSectionBy(levels);
        let randomList = this.pickRandomListFrom(lists);
        return randomList;
    }
}

export default Board;










































































































import Vue from 'vue'
import Component from 'vue-class-component'
import {
  State,
  Getter,
  Action,
  Mutation,
  namespace
} from 'vuex-class';
import { mapGetters } from "vuex";
import { Prop, Watch } from 'vue-property-decorator';
import { Carousel, Slide } from "vue-carousel";
import ArrowLeft from "vue-material-design-icons/ChevronLeft.vue";
import ArrowRight from "vue-material-design-icons/ChevronRight.vue";
import FileDocumentOutlineIcon from "vue-material-design-icons/FileDocumentOutline.vue";
import PlayIcon from "vue-material-design-icons/Play.vue";
import track, { TRACK_TYPES } from '../../dex-shared/helpers/track';

@Component({
  components: {
    Carousel,
    Slide,
    PlayIcon,
    ArrowLeft,
    ArrowRight,
    FileDocumentOutlineIcon
  },
})
export default class QuestionMap extends Vue{
  @Prop({
    default: []
  }) listItemsArray!: any[];

  @Prop({
    default: 0
  }) index!: number;

  @Prop({
    default: ""
  }) routePath!: string;

  @Watch('$route')
  onRouteChange(to:any, from:any) {
    let carousel = this.$refs.carousel as VueCarousel;
    if (this.$refs.carousel) {
      let itemIndex;
      if (to.params.itemIndex) {
        itemIndex = parseInt(to.params.itemIndex) - 1
      } else {
        if (to.params.questionIndex) {
          let toQuestion = this.listQuestions[parseInt(to.params.questionIndex) - 1]
          itemIndex = this.listItems.indexOf(toQuestion)
        } else if (to.params.articleIndex) {
          let toArticle = this.listArticles[parseInt(to.params.articleIndex) - 1]
          itemIndex = this.listItems.indexOf(toArticle)
        }
      }
      let carouselPage = Math.floor((itemIndex)/carousel.currentPerPage);
      if (carousel.currentPage != carouselPage) {
        carousel.goToPage(carouselPage);
      }
      if (this.listItemsArray.length > carousel.currentPerPage &&
       itemIndex % carousel.currentPerPage == 0) {
        carousel.goToPage((itemIndex)/carousel.currentPerPage);
      }
    }
  }


  @Getter listItems!: any[];
  @Getter listReplies!: any[];
  @Getter listVideos!: any[];
  @Getter listArticles!: any[];
  @Getter listQuestions!: any[];

  isFirstPage = true;
  isLastPage  = false;

  get questionsArray(): any {
    return this.listItemsArray.filter(
      (item:any) => item.type === 'Question'
    )
  }

  get questionState(): any {
    var state: any[] = [];
    for (var index in this.listReplies) {
      let reply = this.listReplies[index];
      if (reply != false) {
        if (reply.correct)
          state[parseInt(index)] = "questions-navigation-item-correct";
        else state[parseInt(index)] = "questions-navigation-item-wrong";
      } else {
        state[parseInt(index)] = "questions-navigation-item-blank";
      }
    }
    return state;
  }

  get limittedlistItemsArray(): Array<any> {
    const deviceWidth: number = document.documentElement.clientWidth;
    const itemWidth: number = 22 + 10; // width + margin
    const maxItems: number = Math.floor(deviceWidth/itemWidth);
    const limittedArray = this.listItemsArray.slice(this.index, this.index + maxItems);
    if (limittedArray.length == maxItems) {
      return limittedArray
    } else {
      const diff = maxItems - limittedArray.length
      return this.listItemsArray.slice(this.index - diff, this.index - diff + maxItems)
    }
  }

  questionIndex(question:any): number {
    return this.questionsArray.indexOf(question) + 1
  }


  changeItem(index: number) {
    (this as any).$track(TRACK_TYPES.NAVIGABILITY_ELEMENT_USED, {
      type: "Question Map",
      listId: this.$store.getters["currentListData"].id,
    });
    let newRoute = this.routePath+this.$route.params.list+'/';
    let item = this.listItemsArray[index];
    if (item.type === 'Question') {
      newRoute += 'questao/' + (this.listQuestions.indexOf(item) + 1)
    } else if (item.type === 'Article') {
      newRoute += 'explicacao/' + (this.listArticles.indexOf(item) + 1)
    } else if (item.type === 'Video') {
      newRoute += 'video/' + (this.listVideos.indexOf(item) + 1)
    } else {
      newRoute += index
    }
    if (newRoute[0] !== '/') newRoute = `/lista/${newRoute}`;
    this.$router.push(newRoute)
  }

  nextSlide() {
    let carousel = this.$refs.carousel as VueCarousel;
    carousel.goToPage(carousel.getNextPage());
    this.checkPage();
  }

  prevSlide() {
    let carousel = this.$refs.carousel as VueCarousel;
    carousel.goToPage(carousel.getPreviousPage());
    this.checkPage();
  }

  checkPage() {
    let carousel = this.$refs.carousel as VueCarousel;
    if (carousel.currentPage + 1 == carousel.pageCount) {
      this.isLastPage = true;
    } else {
      this.isLastPage = false;
    }
    if (carousel.currentPage == 0) {
      this.isFirstPage = true
    } else {
      this.isFirstPage = false;
    }
  }
};

import { query, mutation } from '@/dex-shared/helpers/graphql';
import Streak from '@/models/Streak';
import DateProvider from '@/models/DateProvider';

export default {
    state: {
        streaks: [] as Array<Streak>
    },
    mutations: {
        ["SET_COLLECTION"](state: any, collection: any) {
            state.streaks = collection;
        },
        ["FORMAT_AND_SET_COLLECTION_WITH_TIMEFRAME"](state: any, collection: any) {
            let desired = [], timeFrameInDays = 30, numberOfDays = 0;
            if (collection && collection.length > 0) {
                while (numberOfDays <= timeFrameInDays) {
                    let streakDate = new DateProvider().subtractDateWith({amount: numberOfDays, comparison: 'days'});
                    let day = streakDate.date();
                    desired.push(new Streak({
                        dateProvider: new DateProvider({ date: streakDate }),
                        completedLists: 0
                    }));
                    numberOfDays += 1;
                }
                collection.map(element => {
                    let dateProvider = new DateProvider({ date: element });
                    for (let streak of desired) {
                        if (dateProvider.isSameDateAs(streak.date)) {
                            streak._completedLists = element.qtdCompletedLists;
                        }
                    }
                });
                state.streaks = desired;
            } else {
                state.streaks = [];
            }
        },
    },
    getters: {
        streakConsecutives(state: any) {
            let streaks = [ ...state.streaks ], consecutives = [];
            for (let streak of streaks) {
                if (streak.completedLists >= 1) {
                    consecutives.push(streak);
                } else {
                    break
                }
            }
            return consecutives;
        },
        anyWasNeverDoneBefore(state: any) {
            if (state.streaks) {
                let streaks = [ ...state.streaks ];
                return streaks.map(streak => streak ? streak.completedLists : 0)
                    .reduce((accumulator: number, currentValue: number) => accumulator + currentValue, 0) > 0 ? false : true;
            } else {
                return false;
            }
        },
        streakCollection(state: any) {
            return state.streaks;
        },
        todayStreak(state: any) {
            if (state.streaks && state.streaks[0] == undefined) {
                return undefined;
            } else {
                let todayStreak = state.streaks.filter(streak => {
                    return streak.dateProvider.isSameDateAs(new DateProvider().date);
                });
                return todayStreak[0]; 
            }
        }
    },
    actions: {
        async PULL_STREAK({ commit }: any) {
            let timezoneOffset = new DateProvider().timezoneOffset;
            let response = await query(
                `query userListHistory($timezoneOffset: Int){
                    getUserListHistory(timezoneOffset: $timezoneOffset) {
                      day
                      month
                      year
                      qtdCompletedLists
                    }
                }`,
                {
                    timezoneOffset
                }
            );
            commit("FORMAT_AND_SET_COLLECTION_WITH_TIMEFRAME", response.data.getUserListHistory);            
        },
        ADD_COMPLETED_LIST({commit, state}: any) {
            let streaks = state.streaks;
            let updatedStreaks = streaks.map(streak => {
                if (streak.dateProvider.isSameDateAs(new DateProvider()).date) {
                    streak._completedLists = streak.completedLists + 1;
                }
            })    
            commit("SET_COLLECTION", updatedStreaks);
        }
    }
};
















































































import Vue from "vue";
import { mapState, mapGetters } from "vuex";
import TabMenu from "@/dex-shared/components/TabMenu.vue";
import RecentActivity from "../components/history/RecentActivity.vue";
import Progress from "../components/history/Progress.vue";
import KeyboardBackspaceIcon from "vue-material-design-icons/KeyboardBackspace.vue";
import track, { TRACK_TYPES } from '../dex-shared/helpers/track';

export default Vue.extend({
  components: {
    TabMenu,
    RecentActivity,
    Progress,
    KeyboardBackspaceIcon,
  },

  data() {
    return {
      activeTab: 0,
      initialIndex: 0,
      fromRoute: null,
      tabs: ['Atividades recentes', 'Andamento'],
    };
  },

  computed: {
    ...mapState({
      currentClassroom: (state: any) => state.classrooms.currentClassroom,
    }),
    currentTabHash() {
      return this.hashFromTab(this.tabs[+this.activeTab]);
    },
  },

  beforeRouteEnter(to, from, next) {
    next(vm => {
      (vm as any).fromRoute = from;
    })
  },

  watch: {
    activeTab() {
      this.replaceHash();
    }
  },

  methods: {
    handleBack(fallbackRoute) {
      const userCameFromDirectLink = this.fromRoute && !this.fromRoute.name;
      if (userCameFromDirectLink) {
        this.$router.push(fallbackRoute);
      } else {
        this.$router.back();
      }
    },
    replaceHash() {
      const { path } = this.$route;
      
      this.$router.replace(`${path}${this.currentTabHash}`);
    },
    hashFromTab(tab) {
      return `#${tab.split(' ').map(s => s.toLowerCase()).join('-')}`;
    },
    activateTab(event) {
        this.$track(TRACK_TYPES.SCREEN_VIEW, {
          screen: 'History',
          sections: [this.tabs[event]]
        });
        this.activeTab = event;
    }
  },

  created() {
    if (this.$route.hash) {
      const initialIndex = this.tabs.map(t => this.hashFromTab(t)).findIndex(h => h == this.$route.hash);
      this.initialIndex = initialIndex != -1 ? initialIndex : 0;
      this.activeTab = this.initialIndex;
    } 
    
    this.replaceHash();
  },
});

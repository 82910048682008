























import Vue from "vue";
import { mapActions } from "vuex";
import ReportModal from "./ReportModal.vue";

export default Vue.extend({
  props: {
    reportFor: {
      required: true,
      validator: s => ["article", "question"].includes(s as string)
    },
    reportedId: {
      type: Number,
      required: true
    },
    customDisplayMessage: {
      type: String,
      required: false
    }
  },

  data() {
    return {
      ReportModal
    };
  },

  methods: {
    openModal() {
      this.$store.dispatch("OPEN_MODAL", {
        component: this.ReportModal,
        props: {
          reportFor: this.reportFor,
          reportedId: this.reportedId
        }
      });
    },
    displayMessageAccordingToReportType(type) {
      if (type == "article") {
        return "Estranhou essa explicação?";
      } else {
        return "Estranhou esse gabarito?";
      }
    }
  }
});

import Vue from 'vue';
import Router from 'vue-router';
import Classroom from './views/Classroom.vue';
import Board from './views/Board.vue';
import List from './views/List.vue';
import Search from './views/Search.vue';
import PageNotFound from './views/PageNotFound.vue';
import Login from './dex-shared/views/Login.vue';
import History from './views/History.vue';
import EmbeddedList from './views/EmbeddedList.vue';
import { loginURL } from './dex-shared/settings/endpoints';

Vue.use(Router);

let router = new Router({
	mode: 'history',
	base: process.env.VUE_APP_BASE_URL,
	routes: [
		{
			path: '/',
			name: 'home',
			component: Classroom
		},
		{
			path: '/entrar',
			component: Login,
			beforeEnter() {
				const path = encodeURIComponent(window.location.href);
				const loginPath = `${loginURL}/cadastro-completo/?returnUrl=${path}`;
				return window.location.assign(loginPath);
			}
		},
		{
			path: '/404',
			name: '404',
			component: PageNotFound
		},

		// Deprecated Links
		{
			path: '/turma/:classroom/',
			name: 'deprecated_classroom',
			component: Classroom
		},
		{
			path: '/turma/:classroom/:board',
			name: 'deprecated_board_classroom',
			component: Board
		},

		{
			path: '/turma/:classroom/:board/:list',
			name: 'deprecated_list_path',
			component: List
		},
		{
			path: '/turma/:classroom/:board/:list/resultado',
			name: 'deprecated_list_classroom_result',
			component: List
		},
		{
			path: '/turma/:classroom/:board/:list/questao/:questionIndex',
			name: 'deprecated_list_path_question',
			component: List
		},
		{
			path: '/turma/:classroom/:board/:list/explicacao/:articleIndex',
			name: 'deprecated_list_path_article',
			component: List
		},
		{
			path: '/turma/:classroom/:board/:list/:itemIndex',
			name: 'deprecated_list_path_item',
			component: List
		},

		{
			path: '/mural/:board',
			name: 'deprecated_board',
			component: Board
		},

		{
			path: '/mural/:board/:list',
			name: 'deprecated_list_board',
			component: List
		},
		{
			path: '/mural/:board/:list/resultado',
			name: 'deprecated_list_board_result',
			component: List
		},
		{
			path: '/mural/:board/:list/questao/:questionIndex',
			name: 'deprecated_list_board_question',
			component: List
		},
		{
			path: '/mural/:board/:list/explicacao/:articleIndex',
			name: 'deprecated_list_board_article',
			component: List
		},
		{
			path: '/mural/:board/:list/:itemIndex',
			name: 'deprecated_list_board_item',
			component: List
		},

		{
			path: '/lista/:list',
			name: 'deprecated_list',
			component: List
		},
		{
			path: '/lista/:list/resultado',
			name: 'deprecated_list_result',
			component: List
		},
		{
			path: '/lista/:list/questao/:questionIndex',
			name: 'deprecated_list_question',
			component: List
		},
		{
			path: '/lista/:list/explicacao/:articleIndex',
			name: 'deprecated_list_article',
			component: List
		},
		{
			path: '/lista/:list/:itemIndex',
			name: 'deprecated_list_item',
			component: List
		},

		// New paths
		{
			path: '/:classroom',
			name: 'classroom',
			component: Classroom
		},
		{
			path: '/:classroom/historico',
			name: 'history',
			meta: {
				navbarTitle: 'Histórico e progresso'
			},
			component: History
		},
		{
			path: '/:classroom/busca',
			name: 'search',
			component: Search
		},
		{
			path: '/:classroom/:board',
			name: 'board',
			component: Board
		},
		{
			path: '/:classroom/:board/embedded/:list',
			name: 'embedded_list',
			component: EmbeddedList
		},
		{
			path: '/:classroom/:board/embedded/:list/resultado',
			name: 'list_result',
			component: EmbeddedList
		},
		{
			path: '/:classroom/:board/embedded/:list/:itemIndex',
			name: 'list_item',
			component: EmbeddedList
		},
		{
			path: '/:classroom/:board/embedded/:list/questao/:questionIndex',
			name: 'list_item_question',
			component: EmbeddedList
		},
		{
			path: '/:classroom/:board/embedded/:list/explicacao/:articleIndex',
			name: 'list_item_article',
			component: EmbeddedList
		},
		{
			path: '/:classroom/:board/:list',
			name: 'list',
			component: List
		},
		{
			path: '/:classroom/:board/:list/resultado',
			name: 'list_result',
			component: List
		},
		{
			path: '/:classroom/:board/:list/:itemIndex',
			name: 'list_item',
			component: List
		},
		{
			path: '/:classroom/:board/:list/questao/:questionIndex',
			name: 'list_item_question',
			component: List
		},
		{
			path: '/:classroom/:board/:list/explicacao/:articleIndex',
			name: 'list_item_article',
			component: List
		},
		{
			path: '/:classroom/:board/:list/video/:videoIndex',
			name: 'list_item_video',
			component: List
		},
		{
			path: '*',
			name: '404',
			component: PageNotFound
		}
	]
});

router.beforeEach((to, from, next) => {
	// @ts-ignore
	if (window.ReactNativeWebView) {
		// @ts-ignore
		window.ReactNativeWebView.postMessage(
			JSON.stringify({
				location: window.location.href,
				type: 'beforeEach'
			})
		);
	}
	next();
});

router.afterEach((to, from) => {
	// @ts-ignore
	if (window.ReactNativeWebView) {
		// @ts-ignore
		window.ReactNativeWebView.postMessage(
			JSON.stringify({
				location: window.location.href,
				type: 'afterEach'
			})
		);
	}
});

export default router;






















































































































































































































import Vue from "vue";
import { mapState, mapGetters } from "vuex";
import RocketIcon from "vue-material-design-icons/Rocket.vue";
import RadioboxBlankIcon from "vue-material-design-icons/RadioboxBlank.vue";
import CheckboxMarkedCircleIcon from "vue-material-design-icons/CheckboxMarkedCircle.vue";
import ArrowTopRightIcon from "vue-material-design-icons/ArrowTopRight.vue";
import pxButton from "@/dex-shared/components/px-button.vue";
import HorizontalRuler from "@/dex-shared/components/HorizontalRuler.vue";
import ListProgressBar from "../list/ListProgressBar.vue";
import DateProvider from "../../models/DateProvider";
import Streak from "../../models/Streak";
import Loading from "@/dex-shared/components/Loading.vue";
import NoActivityBanner from "./NoActivityBanner.vue";

const LIST_HISTORY_DAY_LIMIT = 3;
const SHOW_LIST_AMOUNT = 6;

export default Vue.extend({
  components: {
    ListProgressBar,
    RadioboxBlankIcon,
    CheckboxMarkedCircleIcon,
    HorizontalRuler,
    RocketIcon,
    ArrowTopRightIcon,
    pxButton,
    Loading,
    NoActivityBanner,
  },

  computed: {
    ...mapState({
      currentClassroom: (state: any) => state.classrooms.currentClassroom,
      listsHistory: (state: any) => state.history.listsHistory,
    })
  },

  data() {
    return {
      loaded: false,
      loadingMoreDays: false,
      debounceScroll: 0,
      openListsByDay: {},
    }
  },

  methods: {
    async pullData() {
      this.loaded = false;

      await this.$store.dispatch('PULL_LIST_HISTORY', {
        limit: LIST_HISTORY_DAY_LIMIT,
        offset: this.listsHistory.length,
      });

      this.loaded = true;
    },
    historyItems(history) {
      const historyDate = new DateProvider({ date: history });
      const streakData = historyDate.isToday || history.completedLists.length === 0 ? [] : [{
        type: 'streak',
        completedLists: history.completedLists.length,
        streakCount: history.streakCount,
      }];
      const historyLists = [
        ...history.completedLists.map(cl => ({ ...cl, type: 'list' })),
        ...history.uncompletedLists.map(ul => ({ ...ul, type: 'list' }))
      ].filter((l, i) => i < this.openListsByDay[this.historyKey(history)]);

      return streakData.concat(historyLists);
    },
    shouldShowMoreListsButton(history) {
      return history.completedLists.length + history.uncompletedLists.length > this.openListsByDay[this.historyKey(history)];
    },
    showMoreLists(history) {
      this.openListsByDay = {
        ...this.openListsByDay,
        [this.historyKey(history)]: SHOW_LIST_AMOUNT + this.openListsByDay[this.historyKey(history)]
      };
    },
    historyDay(history) {
      const historyDate = new DateProvider({ date: history });

      if (historyDate.isToday) return 'Hoje';
      if (historyDate.isYesterday) return 'Ontem';

      return historyDate.dayOfTheWeek;
    },
    historyDayAndMonth(history) {
      return (new DateProvider({ date: history })).dayAndMonth;
    },
    isListComplete(list) {
      const { questionsTotal, answersByUser: { correct, wrong } } = list;

      return questionsTotal === correct + wrong;
    },
    isChallengeComplete(streakItem) {
      const { completedLists } = streakItem;
      const streak = new Streak({ completedLists });

      return streak.amountOfBreakedMilestones() === streak.streakBreakpoints.length;
    },
    challengeListMessage(challenge) {
      const addPlural = challenge.completedLists > 1 ? 's' : '';

      return `${challenge.completedLists} lista${addPlural} feita${addPlural}`;
    },
    historyIsMoreThanSevenDaysOld(history) {
      return (new DateProvider()).daysDifference((new DateProvider({ date: history })).date) > 7;
    },
    historyTimeFromNow(history) {
      return (new DateProvider({ date: history })).timeFromNow;
    },
    async infiniteScroll() {
      const now = Date.now();
      if (now - this.debounceSroll < 300) return;
      this.debounceScroll = now;
      const bottomOfWindow = (document.documentElement.scrollTop + window.innerHeight) === document.documentElement.offsetHeight;

      if (bottomOfWindow && !this.loadingMoreDays) {
        this.loadingMoreDays = true;
        
        const offset = this.listsHistory.length;

        await this.$store.dispatch('PULL_LIST_HISTORY', {
          limit: LIST_HISTORY_DAY_LIMIT,
          offset: this.listsHistory.length,
        });

        this.loadingMoreDays = false;

        if (this.$store.state.history.listsHistory.length === offset) {
          window.removeEventListener('scroll', this.infiniteScroll);
        }
      }
    },
    historyKey(history) {
      return `${history.day}-${history.month}-${history.year}`;
    }
  },

  watch: {
    listsHistory() {
      this.listsHistory.reduce((openListsByDay, history) => {
        if (openListsByDay[this.historyKey(history)]) return openListsByDay;
        openListsByDay[this.historyKey(history)] = SHOW_LIST_AMOUNT;
        
        return openListsByDay;
      }, this.openListsByDay);
    },
    openListsByDay() {
      this.$forceUpdate();
    }
  },

  mounted() {
    this.pullData();
    window.addEventListener('scroll', this.infiniteScroll);
  },

  beforeDestroy() {
    window.removeEventListener('scroll', this.infiniteScroll);
  }
    
});
































































































































import Vue from 'vue';
import { mapGetters } from 'vuex';
import DexPWAModal from "../pwa/DexPWAModal.vue";
import CellphoneArrowDownIcon from "vue-material-design-icons/CellphoneArrowDown.vue";
import RestoreClockIcon from "vue-material-design-icons/RestoreClock.vue";
import track, { TRACK_TYPES } from '../../dex-shared/helpers/track';

export default Vue.extend({
  components: {
    CellphoneArrowDownIcon,
    RestoreClockIcon,
  },

  data() {
    return {
      DexPWAModal
    }
  },
  computed: {
    ...mapGetters({
      userId: 'userId'
    }),
    currentBoard(): any {
      return this.$store.getters.currentBoard;
    },
    classroomSlug(): any {
      return this.$store.state.classrooms.currentClassroom.slug;
    },
    boards(): any[] {
      return this.$store.getters.boards;
    },
    classroom(): any {
      return this.$store.state.classrooms.all;
    },
  },

  methods: {
    onHistoryClicked() {
      this.$track(TRACK_TYPES.LINK_NAVIGATED, {
        context: 'history'
      });
      this.$router.push(`/${this.classroomSlug}/historico`);
    },
    openModal() {
      this.$track(TRACK_TYPES.SCREEN_VIEW, {
        screen: 'DexPWAModal'
      });
      this.$store.dispatch('OPEN_MODAL', { component: this.DexPWAModal });
    },
    onBoardLinkClicked(board) {
      this.$track(TRACK_TYPES.LINK_NAVIGATED, {
        board
      });
      this.$router.push(`/${this.$store.getters.currentClassroom.slug}/${board.slug}`);
    }
  }
})

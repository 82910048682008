














































import Vue from 'vue'
import pxModal from "@/dex-shared/components/px-modal.vue";
import HorizontalRuler from "@/dex-shared/components/HorizontalRuler.vue";
import CloseIcon from "vue-material-design-icons/Close.vue";
import { mapActions } from "vuex";
import track, { TRACK_TYPES } from '../dex-shared/helpers/track';
export default Vue.extend({
    components: {
        pxModal,
        HorizontalRuler,
        CloseIcon
    },
    data() {
        return {
            showContent: false
        }
    },
    methods: {
        toggleDropDown() {
            this.showContent = !this.showContent;
        },
        onOptionClicked(option) {
            this.$track(TRACK_TYPES.SCREEN_VIEW, {
                screen: "Board",
                screenId: null,
                sections: this.selected.filter(active => active !== option)
            });
            this.$store.dispatch("handleOperatedLevel", option);
        },
        toggleOption(option) {
            this.options[option].chosen = !this.options[option].chosen;
        }
    },
    props: ["selected", "options", "displayOptionsBy", "selectedLevelsDisplayStatus"]
})

import { query } from '@/dex-shared/helpers/graphql';
import Board from '@/models/Board';

// mutations
const SET_BOARD_WITH_BOARD_SECTIONS_BY_BOARD_WITH_LISTS = "SET_BOARD_WITH_BOARD_SECTIONS_BY_BOARD_WITH_LISTS";

// actions 
const PULL_BOARD_WITH_BOARD_SECTIONS_BY_BOARD_WITH_LISTS = "PULL_BOARD_WITH_BOARD_SECTIONS_BY_BOARD_WITH_LISTS"

export default {
  state: {
    board: null
  },
  getters: {
  },
  mutations: {
    [SET_BOARD_WITH_BOARD_SECTIONS_BY_BOARD_WITH_LISTS](state: any, board: any[]) {
      state.board = new Board({ data: board });
    },
  },
  actions: {
    async [PULL_BOARD_WITH_BOARD_SECTIONS_BY_BOARD_WITH_LISTS]({ commit, rootState }: any, { boardId, batchForEachSection }: any) {
      let userId = rootState.auth.user ? rootState.auth.user.id : null;
      let boardParameterId;
      if (isNaN(boardId)) {
        let boardBySlugResponse = await query(`query getAllBoards($slug: String!){
          allBoards(condition: {
            slug: $slug
          }){
            nodes {
              id
            }
          }
        }`, {
          slug: boardId
        });
        let boardBySlug = boardBySlugResponse.data.allBoards.nodes;
        if (boardBySlug && boardBySlug[0]) {
          boardParameterId = boardBySlug[0].id;
        } else {
          //TODO error with the slug
        }
      } else {
        boardParameterId = boardId;
      }
      if (userId) {
        let response = await query(`query getBoardByIdOnDexWeb($userId: Int!, $boardId: Int!, $batchForEachSection: Int!) {
              boardById(id: $boardId) {
                id
                title
                slug
                replies(userid: $userId) {
                  totalReplies
                  correct
                  wrong
                }
                popularLists(first: $batchForEachSection, userid: $userId) {
                  nodes {
                    id
                    name
                    slug
                    assertionRate
                    totalQuestions
                    answersByUser {
                      correct
                      wrong
                    }
                  }
                }
                finishedLists(first: $batchForEachSection, userid: $userId) {
                  nodes {
                    id
                    name
                    slug
                    assertionRate
                    totalQuestions
                    answersByUser {
                      correct
                      wrong
                    }
                  }
                }
                stoppedLists(first: $batchForEachSection, userid: $userId) {
                  nodes {
                    id
                    name
                    slug
                    assertionRate
                    totalQuestions
                    answersByUser {
                      correct
                      wrong
                    }
                  }
                }
                boardSectionsByBoardId {
                  nodes {
                    id
                    title
                    finishedLists(userid: $userId, first: $batchForEachSection) {
                      nodes {
                        id
                        name
                        slug
                        assertionRate
                        totalQuestions
                        answersByUser {
                          correct
                          wrong
                        }
                      }
                    }
                    popularLists(userid: $userId, first: $batchForEachSection) {
                      nodes {
                        id
                        name
                        slug
                        assertionRate
                        totalQuestions
                        answersByUser {
                          correct
                          wrong
                        }
                      }
                    }
                    stoppedLists(userid: $userId, first: $batchForEachSection) {
                      nodes {
                        id
                        name
                        slug
                        assertionRate
                        totalQuestions
                        answersByUser {
                          correct
                          wrong
                        }
                      }
                    }
                  }
                }
              }
            }`, {
          userId: userId,
          boardId: +boardParameterId,
          batchForEachSection: +batchForEachSection
        })
        commit(SET_BOARD_WITH_BOARD_SECTIONS_BY_BOARD_WITH_LISTS, response.data.boardById);
      } else {
        let response = await query(`query getBoardByIdOnDexWeb2($boardId: Int!, $batchForEachSection: Int!) {
          boardById(id: $boardId) {
            id
            title
            slug
            popularLists(first: $batchForEachSection) {
              nodes {
                id
                name
                slug
                assertionRate
                totalQuestions
              }
            }
            finishedLists(first: $batchForEachSection) {
              nodes {
                id
                name
                slug
                assertionRate
                totalQuestions
              }
            }
            stoppedLists(first: $batchForEachSection) {
              nodes {
                id
                name
                slug
                assertionRate
                totalQuestions
              }
            }
            boardSectionsByBoardId {
              nodes {
                id
                title
                finishedLists(first: $batchForEachSection) {
                  nodes {
                    id
                    name
                    slug
                    assertionRate
                    totalQuestions
                  }
                }
                popularLists(first: $batchForEachSection) {
                  nodes {
                    id
                    name
                    slug
                    assertionRate
                    totalQuestions
                  }
                }
                startedLists(first: $batchForEachSection) {
                  nodes {
                    id
                    name
                    slug
                    assertionRate
                    totalQuestions
                  }
                }
              }
            }
          }
        }`, {
          boardId: +boardParameterId,
          batchForEachSection: +batchForEachSection
        })
        commit(SET_BOARD_WITH_BOARD_SECTIONS_BY_BOARD_WITH_LISTS, response.data.boardById);
      }
    }
  }
}
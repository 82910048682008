export let graphqlBasicURL = process.env.VUE_APP_DEX_GRAPHQL_URL;
export let graphqlSpecialURL = process.env.VUE_APP_DEX_GRAPHQL_URL;
export let graphqlGreatHall = process.env.VUE_APP_GREATHALL_GRAPHQL_URL;
export let authenticationURL = process.env.VUE_APP_DEX_AUTH_URL;
export let chimeraBasicURL = process.env.VUE_APP_DESCOMPLICA_CHIMERA_URL;
export let fileUploadURL = process.env.VUE_APP_DEX_UPLOAD_URL;
export let imaginieBasicURL = process.env.VUE_APP_IMAGINIE_URL;
export let tokenAuthenticationURL = process.env.VUE_APP_TOKEN_AUTH_URL;
export let loginURL = process.env.VUE_APP_LOGIN_URL;
export let signUpURL = process.env.VUE_APP_SIGNUP_URL;
export let baseURL = process.env.VUE_APP_BASE_URL;
export let descomplicaURL = process.env.VUE_APP_DESCOMPLICA_URL;
export let dexWebURL = process.env.VUE_APP_DEX_WEB_URL;
export let dexExamsURL = process.env.VUE_APP_DEX_EXAMS_URL;

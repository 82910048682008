



















// Decorators
import Vue from 'vue'
import Component from 'vue-class-component'
import {
  State,
  Getter,
  Action,
  Mutation,
  namespace
} from 'vuex-class';

import { Prop } from 'vue-property-decorator';
import Content from "../../../dex-shared/components/content/Content.vue";
import CheckIcon from "vue-material-design-icons/Check.vue";
import CloseIcon from "vue-material-design-icons/Close.vue";
import { mapGetters } from "vuex";

@Component({
  components: {
    Content,
    CheckIcon,
    CloseIcon
  }
})
export default class InteractiveDataResult extends Vue{

  @Prop() interactiveData!: any;
  @Prop() questionId!: number;

 @Getter sessionQuestionReply: any;

  get reply(): any{
    return this.sessionQuestionReply(this.questionId);
  }

  get alternativeState() {
    return this.interactiveData.alternatives.map((alternative: any,index: number)=>{
      return {
        checked: +this.reply.answer === +index,
        correct: +this.reply.correctAnswer === +index,
        wrong:   +this.reply.correctAnswer !== +index,
        unchecked: +this.reply.answer !== +index
      }
    });
  }
}

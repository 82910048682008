

































































































































































































































































































import Vue from 'vue'
import pxButton from '@/dex-shared/components/px-button.vue';
import pxModal from '@/dex-shared/components/px-modal.vue';
import TabMenu from '@/dex-shared/components/TabMenu.vue';
import KeyboardBackspaceIcon from "vue-material-design-icons/KeyboardBackspace.vue";

const initialTab = () => {
	const isChrome = navigator.userAgent.indexOf("Chrome") != -1;
	const isSafari = navigator.userAgent.indexOf("Safari") != -1;

	if (isChrome) return 0;
	if (isSafari) return 1;

	return 2;
};

export default Vue.extend({
  components: {
    pxButton,
    pxModal,
    TabMenu,
    KeyboardBackspaceIcon,
  },

	props: {
		id: {
			type: Number,
			required: true,
		}
	},

	data() {
		return {
			active: initialTab(),
			initialTab: initialTab(),
		}
	},

  methods: {
		closeModal() {
			this.$store.dispatch('CLOSE_MODAL', this.id);
		}
  }
})





























































import Vue from "vue";
import {mapGetters, mapState} from "vuex";
import classrooms from '@/store/modules/classrooms';
import HomeIcon from "vue-material-design-icons/Home.vue";
import track, { TRACK_TYPES } from '../dex-shared/helpers/track';
export default Vue.extend({
  components: {
    HomeIcon
  },

  props: ["items", "resultItem", "icon", "routePath"],

  computed: {
    ...mapGetters({
      currentListData: "currentListData",
      listReplies: "listReplies",
      classroom: "currentClassroom",
      board: "currentBoard"
    }),
    showResult: function() {
      if (this.$route.name === "list_result") {
        return true;
      } return false;
    },
    boardLink: function(): any {
      return '/' + this.classroom.slug + '/' + this.board.slug;
    },
    inBoard: function(): any {
      return this.$route.params.boardId && !this.$route.params.listId
    }
  },

  methods: {
    isLast(i: number) {
      if (i + 1 == this.items.length) {
        return "item-last";
      }
    },
    onBoardLinkClicked() {
      this.$track(TRACK_TYPES.LINK_NAVIGATED, {
        context: "OUT",
        label: "Board",
        list: this.currentListData
      });
      this.$router.push(this.boardLink);
    },
    toggleShowResult(value: any = undefined) {
      if(this.routePath){
        if (value) {
          this.$router.push(this.routePath+this.$route.params.list+'/resultado');
        } else {
          this.$router.push(this.routePath+this.$route.params.list);
        }
      } else {
        if (value) {
          this.$router.push('/enem/matematica/'+this.$route.params.listId+'/resultado');
        } else {
          this.$router.push('/enem/matematica/'+this.$route.params.listId);
        }
      }
    }
  },
});



































import Vue from 'vue';
import _ from "lodash";
import { mapGetters, mapState, mapActions } from 'vuex';
import Loading from '@/dex-shared/components/Loading.vue';
import pxCard from "@/dex-shared/components/px-card.vue";
import ListCard from "../components/list/ListCard.vue";

export default Vue.extend({
  components: {
    Loading,
    pxCard,
    ListCard
  },

  data() {
		return {
      loaded: false,
      searchTerm: '',
		};
  },

  computed: {
    ...mapGetters({
      searchResults: 'listSearchResult'
    }),
    boards() {
      const { bestResult, boards } = this.searchResults;
      const boardsArr = Object.keys(boards).map(board => ({
        board,
        length: boards[board].length,
        bestResult: board === bestResult.boardTitle ? 1 : 0,
      }));

      return _.orderBy(boardsArr, ['bestResult', 'length'], ['desc', 'desc']).map(b => b.board);
    }
  },

  watch: {
    '$route': 'searchLists'
  },

  methods: {
    async searchLists() {
      this.searchTerm = this.$route.query.q;
      this.loaded = false;
      await this.$store.dispatch("PULL_CLASSROOM", this.$route.params.classroom);
      await this.$store.dispatch("PULL_SEARCH_RESULTS", this.searchTerm);
      this.loaded = true;
    },
    listsCount() {
      const { boards } = this.searchResults;
      return Object.keys(boards).reduce((acc, boardKey) => acc + boards[boardKey].length, 1);
    }
  },

  mounted() {
    this.searchLists();
  }
});

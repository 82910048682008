




































































































































import InteractiveData from "@/components/question/interactive_data/InteractiveData.vue";
import InvestigationItem from "./InvestigationItem.vue";

export default {
  components: {
    InteractiveData,
    InvestigationItem
  },
  props: [
    "investigation",
    "doubtAboutInvestigations",
    "onAnswer",
    "switchDoubtAboutInvestigations",
    "cancelInvestigations"
  ],
  data() {
    return {
      doubtPermissionQuestion: {
        id: 1,
        content: [{ content: "Foo bar?", behavior: "text" }],
        interactiveData: {
          alternatives: [
            [{ id: 0, content: "Pode ser!", behavior: "text" }],
            [
              {
                id: 1,
                content: "Pergunte novamente mais tarde!",
                behavior: "text"
              }
            ]
          ]
        },
        type: "Feedback-Permission"
      }
    };
  },
  methods: {
    pushPermissionToInvestigation(answer: any) {
      if (
        this.doubtPermissionQuestion.interactiveData.alternatives[answer][0]
          .id === 0
      ) {
        this.switchDoubtAboutInvestigations();
      } else {
        this.cancelInvestigations();
      }
    }
  }
};

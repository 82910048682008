import { render, staticRenderFns } from "./WrongCard.vue?vue&type=template&id=4cc20baa&scoped=true&"
import script from "./WrongCard.vue?vue&type=script&lang=ts&"
export * from "./WrongCard.vue?vue&type=script&lang=ts&"
import style0 from "./WrongCard.vue?vue&type=style&index=0&id=4cc20baa&lang=less&scoped=true&"


/* normalize component */
import normalizer from "!../../../node_modules/vue-loader/lib/runtime/componentNormalizer.js"
var component = normalizer(
  script,
  render,
  staticRenderFns,
  false,
  null,
  "4cc20baa",
  null
  
)

export default component.exports
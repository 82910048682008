




































































































import Vue from "vue";
import { mapGetters, mapState } from "vuex";
import pxCard from "../../dex-shared/components/px-card.vue";
import Content from "../../dex-shared/components/content/Content.vue";
import track, { TRACK_TYPES } from '../../dex-shared/helpers/track';

export default Vue.extend({
  components: {
    pxCard,
    Content
  },
  props: ["question","status"],
  computed: {
    ...mapGetters({
      percentage: "percentageCorrectOfQuestion",
    }),
    ...mapState({
      listItems: (state: any) => state.lists.current.listItemsByListId.nodes,
      list: (state: any) => state.lists.current
    }),
    questionPosition: function():number {
      return this.listItems.filter(
        (item:any) => item.questionByQuestionId
      ).indexOf(this.question) + 1
    },
    questionLink: function() {
      return `/${this.$route.params.classroom}/${this.$route.params.board}/${this.$route.params.list}/${this.questionPosition}`
    }
  },
  methods: {
    onResultItemClicked() {
      this.$track(TRACK_TYPES.LINK_NAVIGATED, {
        list: this.list,
        context: 'Resultados Lista',
        label: this.status
      });
      this.$router.push(this.questionLink)
    }
  }
});

import { query, mutation } from "@/dex-shared/helpers/graphql";

// mutations
let SET_INVESTIGATIONS = "SET_CURRENT_INVESTIGATIONS";
let SET_ERROR_MESSAGE = "SET_ERROR_MESSAGE";
let REMOVE_ANSWERED_INVESTIGATION = "REMOVE_ANSWERED_INVESTIGATION";
let RESET_INVESTIGATIONS = "RESET_INVESTIGATIONS";

// getters
let INVESTIGATIONS_AS_QUESTIONS = "INVESTIGATIONS_AS_QUESTIONS";

// actions
let PULL_INVESTIGATIONS = "PULL_INVESTIGATIONS";
let PUSH_INVESTIGATION_RESPONSE = "PUSH_INVESTIGATION_RESPONSE";
let PUSH_INVESTIGATION_DENIAL = "PUSH_INVESTIGATION_DENIAL";

export default {
  state: {
    investigations: [],
    errorMessage: ""
  },
  mutations: {
    [SET_INVESTIGATIONS](state: any, investigations: any) {
      state.investigations = investigations;
    },
    [REMOVE_ANSWERED_INVESTIGATION](state: any, investigationToBeRemoved: any) {
      state.investigations = state.investigations.filter(
        (investigation: any) => investigation.id !== investigationToBeRemoved.id
      );
    },
    [SET_ERROR_MESSAGE](state: any, errorMessage: any) {
      state.errorMessage = errorMessage;
    },
    [RESET_INVESTIGATIONS](state: any) {
      state.investigations = [];
    }
  },
  getters: {
    [INVESTIGATIONS_AS_QUESTIONS](state: any) {
      return state.investigations.map((investigation: any) => ({
        id: investigation.id,
        content: [
          {
            content: investigation.title,
            behavior: "text"
          }
        ],
        interactiveData: {
          alternatives: investigation.hypotheses.map((hypothesis: any) => [
            {
              id: hypothesis.id,
              content: hypothesis.title,
              behavior: "text"
            }
          ])
        },
        type: "Feedback-Question"
      }));
    }
  },
  actions: {
    async [PULL_INVESTIGATIONS]({ commit, state }: any, { userId }: any) {
      let response = await query(
        `query getSuitableInvestigationsByUserId($id: Int!) {
                    suitableInvestigationsByUserId(id: $id) {
                      id
                      title
                      hypotheses {
                          id
                          title
                      }
                    }
                }`,
        {
          id: userId
        }
      );
      commit(SET_INVESTIGATIONS, response.data.suitableInvestigationsByUserId);
    },
    async [PUSH_INVESTIGATION_RESPONSE](
      { commit, state }: any,
      { hypothesisId, investigationId, userId }: any
    ) {
      let response = await mutation(
        `
                mutation createResponse($hypothesisId: Int!, $investigationId: Int!, $userId: Int!) {
                    createInvestigationResponse(
                        input: {
                            investigationResponse: {
                                hypothesisId: $hypothesisId
                                investigationId: $investigationId
                                userId: $userId
                            }
                        }
                    ) {
                        investigationResponse {
                            id
                            hypothesisId
                            investigationId
                            userId
                        }
                    } 
                }
            `,
        {
          hypothesisId,
          investigationId,
          userId
        }
      );
      if (response) {
        commit(REMOVE_ANSWERED_INVESTIGATION, investigationId);
      } else {
        commit(SET_ERROR_MESSAGE, response.message);
      }
    },
    async [PUSH_INVESTIGATION_DENIAL](
      { commit, state }: any,
      { userId, investigationId }: any
    ) {
      let response = await mutation(
        `
                mutation createDenial($userId: Int!, $investigationId: Int!) {
                    createInvestigationDenial(
                        input: { 
                            investigationDenial: { 
                                userId: $userId,  
                                investigationId: $investigationId 
                            } 
                        }
                    ) {
                        investigationDenial {
                          id
                          createdAt
                        }
                    }
                }
            `,
        {
          userId,
          investigationId
        }
      );
      if (response) {
        commit(RESET_INVESTIGATIONS);
      }
    }
  }
};

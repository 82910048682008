import DateProvider from '@/models/DateProvider';

declare interface Streak {
    streakFireRule: number
    streakBreakpoints: Array<number>
    dateProvider: any
    completedLists: number
}

class Streak {

    constructor({dateProvider, completedLists}: {dateProvider?: any, completedLists: number}) {
        this.streakFireRule = 120;
        this.streakBreakpoints = [1, 2, 6, 15, 40, 100];
        this.completedLists = completedLists;
        this.dateProvider = dateProvider;
    }

    get date() {
        return this.dateProvider.date;
    }

    amountOfBreakedMilestones() {
        return this.streakBreakpoints.filter(breakpoint => breakpoint <= this.completedLists).length;
    }

    get achievedMilestones() {
        return this.amountOfBreakedMilestones();
    }

    get completed() {
        if (this.completedLists == this.streakBreakpoints[this.streakBreakpoints.length - 1]) {
            return true;
        } else {
            return false;
        }
    }

    get lastAchievedMilestone() {
        let lastAchievedMilestone = 0;
        for (let breakpoint of this.streakBreakpoints.filter(breakpoint => breakpoint < this.completedLists)) {
            if (breakpoint > lastAchievedMilestone) {
                lastAchievedMilestone = breakpoint;
            }
        }
        return lastAchievedMilestone;
    }

    calculateNextMilestone() {
        return this.streakBreakpoints.filter(breakpoint => breakpoint > this.completedLists)[0];
    }

    get nextMilestone() {
        return this.calculateNextMilestone();
    }

    get remainingListsToNextMilestone() { 
        let nextMilestone = this.calculateNextMilestone();
        return (nextMilestone - this.completedLists);
    }

    get hasNoListDone() {
        return (this.completedLists < 1);
    }

    set _completedLists(amount) {
        this.completedLists = amount;
    }
    
}

export default Streak;
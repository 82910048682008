




















































































































































































































import pxButton from "@/dex-shared/components/px-button.vue";
import DescomplicaLogo from "@/dex-shared/components/icons/DescomplicaLogo.vue";
import MenuIcon from "vue-material-design-icons/Menu.vue";
import MagnifyIcon from "vue-material-design-icons/Magnify.vue";
import {mapState} from "vuex";
import SearchBar from './SearchBar.vue';
import pxModal from "@/dex-shared/components/px-modal.vue";

export default {
  components: {
    pxButton,
    MenuIcon,
    SearchBar,
    MagnifyIcon,
    pxModal,
    DescomplicaLogo,
  },

  data() {
    return {
      mobileSearchOpen: false,
    };
  },

  computed: {
    ...mapState({
      classroomSlug: (state:any) => state.classrooms.currentClassroom.slug
    }),
    homeLink: function():string {
			if (this.classroomSlug) {
				return `/${this.classroomSlug}`;
			} else {
				return '/';
			}
		}
  },

  methods: {
    cancelMobileSearch() {
      this.mobileSearchOpen = false;
    },
    isSearchPage() {
      return this.$route.name === 'search';
    },
    toggleSidebar() {
      this.$emit('toggle-sidebar');
    }
  }
};






















































import Vue from "vue";
import { mapState } from "vuex";
import Sidebar from "./Sidebar.vue";
import Feedback from "../Feedback.vue";

export default Vue.extend({
  components: {
    Sidebar,
    Feedback,
  },
  
  computed: {
    ...mapState({
      user: (state: any) => state.auth.user
    })
  }
});

import * as Sentry from "@sentry/vue";
const isProd = process.env.NODE_ENV === "production";

export default (store) => {
  const SET_USER = 'SET_USER';

  if (isProd) {
    store.subscribe((mutation, state) => {
      if (mutation.type == SET_USER) {
        const { id, email, descomplicaId, isSubscriber } = mutation.payload;

        Sentry.configureScope((scope) => {
          scope.setUser({
            id,
            email,
            descomplicaId,
            isSubscriber,
          });
        });
      }
    });
  }
};




















































































































































































































































import moment from 'moment';
import Vue from 'vue';
import Streak from "@/models/Streak";
import DatePicker from "@/components/streak/DatePicker.vue";
import StreakProgress from "@/components/streak/StreakProgress.vue";
import pxButton from '@/dex-shared/components/px-button.vue';
import StreakProgressMobile from "@/components/streak/StreakProgressMobile.vue";
import { Carousel, Slide } from 'vue-carousel';
import ArrowLeft from "vue-material-design-icons/ChevronLeft.vue";
import ArrowRight from "vue-material-design-icons/ChevronRight.vue";
import { TweenMax } from 'gsap';
import { cdn } from "@/dex-shared/lib/cdn";
import TWEEN from '@tweenjs/tween.js';
import track, { TRACK_TYPES } from '../../dex-shared/helpers/track';

export default Vue.extend({
    components: {
        DatePicker,
        StreakProgress,
        StreakProgressMobile,
        Carousel,
        Slide,
        ArrowLeft,
        ArrowRight,
        pxButton
    },
    props: {
        ctaCaller: {
            type: Function,
            required: true
        },
        loadingCallerData: {
            type: Boolean,
            required: true
        }
    },
    data() {
        return {
            moment,
            activeDate: moment(),
            activePage: 0,
            activeStreak: null,
            width: (window.innerWidth * 0.575),
            progressPercentage: 90
        }
    },
    computed: {
        streaks() {
            return [ ...this.$store.getters['streakCollection'] ].reverse();
        }
    },
    watch: {
        activeStreak(nextStreak) {
            this.tween(90, this.progressBarPercentage(nextStreak));
        }
    },
    async created() {
        await this.loadStreaks();
        this.setInitialConfigurations();
    },
    methods: {
        handleScreenDimensions() {
            this.width = window.innerWidth;
            this.height = window.innerHeight;
        },
        setInitialConfigurations() {
            // TODO: Isolate moment, all components know too much about how date are being handled.
            moment.locale('pt-br');
            this.activePage = this.streaks.length - 3;
            this.activeStreak = this.streaks[this.streaks.length - 1];
        },
        loadStreaks() {
            return this.$store.dispatch('PULL_STREAK');
        },
        changeDate(date) {
            this.activeDate = moment(date);
        },
        ////////////////////////////////////////
        isActive(date) {
            return date.isSame(this.activeDate, 'day')
                && date.isSame(this.activeDate, 'month')
                && date.isSame(this.activeDate, 'year');
        },
        dateByName(date) {
            if (moment(date).isSame(moment(), 'day') && moment(date).isSame(moment(), 'month') && moment(date).isSame(moment(), 'year')) {
                return "HOJE";
            } else if ( date.isSame(moment().subtract(1, 'days'), 'day') && date.isSame(moment().subtract(1, 'days'), 'month') && date.isSame(moment().subtract(1, 'days'), 'year') ) {
                return "ONTEM";
            } else {
                return moment(date).format('ddd').toUpperCase();
            }
        },
        moveSliderToLeft() {
            if (this.activePage > 0) {
                this.activePage -= 1;
            }
        },
        moveSliderToRight() {
            if (this.activePage < this.streaks.length - 3) {
                this.activePage += 1;
            }
        },
        isMovingToRight(date) {
            return moment(this.activeDate).isBefore(moment(date));
        },
        isMovingToLeft(date) {
            return moment(this.activeDate).isAfter(moment(date));
        },
        changeStreakTo(streak) {
            this.activeStreak = streak;
        },
        onDateClick(date, streak) {
            this.$track(TRACK_TYPES.NAVIGABILITY_ELEMENT_USED, {
                type: "Streak",
                label: this.dateByName(streak.date)
            });
            if (this.isMovingToLeft(date)) {
                this.moveSliderToLeft();
            } else {
                this.moveSliderToRight();
            }
            this.changeStreakTo(streak);
            this.changeDate(date);
        },
        isOnFire(streak) {
            let streakIndex = this.streaks.indexOf(streak);
            let lastStreak = this.streaks[streakIndex + 1];
            let nextStreak = this.streaks[streakIndex - 1];
            if (!streak.hasNoListDone) {
                if (streak.dateProvider.isToday) {
                    return true;
                } else if (lastStreak && !lastStreak.hasNoListDone) {
                    return true;
                } else if (nextStreak && !nextStreak.hasNoListDone) {
                    return true;
                }
            } else {
                return false;
            }
        },
        shouldRemainingListsAppear({ streak }) {
            return !streak.completed && this.isToday(streak.date) && !streak.hasNoListDone;
        },
        isToday(date) {
            return date.isSame(moment(), 'day')
                && date.isSame(moment(), 'month')
                && date.isSame(moment(), 'year');
        },
        tween(start, end) {
            // TODO: Tween knows too much about percentage transition, it is not reusable.
            let frameHandler

            const animate = function(currentTime) {
                TWEEN.update(currentTime)
                frameHandler = requestAnimationFrame(animate);
            }

            const myTween = new TWEEN.Tween({ progressPercentage: start }).to({ progressPercentage: end }, 850)
                .onUpdate((object) =>  {
                    this.progressPercentage = object.progressPercentage;
                })
                .onComplete(() => {
                    cancelAnimationFrame(frameHandler);
                })
                .delay(100)
                .easing(TWEEN.Easing.Quadratic.Out)
                .start()
                frameHandler = requestAnimationFrame(animate);
        },
        progressBarPercentage(streak) {
            // TODO: knows the message and the fact that exists a streak, it has first degree dependency.
            let completedLists = streak.completedLists,
            achievedMilestones = streak.achievedMilestones;

            if (!completedLists || completedLists <= 0) {
                return 95;
            } else if (completedLists == 1) {
                return 90;
            }

            if (streak.streakBreakpoints.includes(completedLists)) {
                return (100 - ( ((achievedMilestones - 1) * 20) - 10 ));
            } else {
                let proportionalDifference = ( ((streak.completedLists - streak.lastMilestone) * 20 ) / ((streak.nextMilestone) - streak.lastMilestone) )
                let result = (100 - (proportionalDifference + ( (achievedMilestones - 1) * 20 )));
                return result;
            }
        },
    }
})












import Vue from "vue";
import VueMarkdown from "vue-markdown";
import DOMPurify from "dompurify";

export default Vue.extend({
  components: {
    VueMarkdown
  },
  props: ["component", "trustedContent"],
  methods: {
    sanitize(text) {
      return this.trustedContent ? text : DOMPurify.sanitize(text, { ALLOW_DATA_ATTR: false });
    }
  }
});
